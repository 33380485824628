import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import apiConfig from "./../../apis/apiConfig";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "./../../actions";
import { translationHook } from "./../translationHook";
import moment from "moment";
import style from "./Dashboard.module.scss";
import DashboardList from "./DashboardList";
import DateBox from "devextreme-react/date-box";
import { Tooltip } from "devextreme-react/tooltip";
import logo from "../../images/ShjMunUniPortal-LogoBGVer.svg";
import logoStrip from "../../imagesTemp/STRIP-LogoBGVer.png";

class DashboardView extends Component {
	constructor(props) {
		super(props);
		this.handleTooltip = this.handleTooltip.bind(this);
		this.searchResults = 0;
	}

	state = {
		municipalList: [],
		sectorsList: [],
		sectionsList: [],
		municipalId: 0,
		sectorId: 0,
		sectionId: 0,
		fromDate: moment(new Date()).lang("en").format("YYYY-MM-DD"),
		toDate: moment(new Date()).lang("en").format("YYYY-MM-DD"),
		cardElements: [],
		activeCard: {},
		dataList: [],
		// searchResults: 0,
		tooltipIsVisible: {},
		dataType: "",
		gridName: "",
		lastRefresh: "",
		showRefreshBtn: false,
		// showTitle: false,
	};

	onSubmit = () => {};

	getTime = () => {
		let date = new Date();
		let hours = date.getHours();
		let minutes = date.getMinutes();
		let ampm;
		if (hours >= 12) {
			ampm = this.props.i18n.language == "ar" ? "م" : "PM";
		} else {
			ampm = this.props.i18n.language == "ar" ? "ص" : "AM";
		}
		hours = hours % 12;
		hours = hours ? hours : 12;
		hours = hours < 10 ? "0" + hours : hours;
		minutes = minutes < 10 ? "0" + minutes : minutes;
		let time = hours + ":" + minutes + " " + ampm;
		this.setState({ lastRefresh: time });
	};

	getMunicipal = async () => {
		let municipalArr = [
			{
				value: 0,
				label: this.props.i18n.language === "ar" ? "الكل" : "All",
			},
		];
		const response = await apiConfig.post(
			"API/ADMINISTRATION/USERS/DASHBOARDS/GET_DASHBOARD_DEPARTMENTS",
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Name_AR
						: item.Department_Name_EN;

				municipalArr.push({ value: item.Department_Id, label });
			});
			this.setState({ municipalList: municipalArr });
		}
	};

	getSectors = async (municipalId) => {
		let sectorsArr = [
			{
				value: 0,
				label: this.props.i18n.language === "ar" ? "الكل" : "All",
			},
		];
		const response = await apiConfig.post(
			"API/ADMINISTRATION/USERS/DASHBOARDS/GET_DASHBOARD_SECTORS",
			{
				Selected_Department_Id: municipalId ? municipalId : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Sector_Name_AR
						: item.Department_Sector_Name_EN;

				sectorsArr.push({ value: item.Department_Sector_Id, label });
			});
			this.setState({ sectorsList: sectorsArr });
		}
	};

	getSections = async (municipalId, sectorId) => {
		let sectionArr = [
			{
				value: 0,
				label: this.props.i18n.language === "ar" ? "الكل" : "All",
			},
		];
		const response = await apiConfig.post(
			"API/ADMINISTRATION/USERS/DASHBOARDS/GET_DASHBOARD_SECTIONS",
			{
				Selected_Department_Id: municipalId ? municipalId : 0,
				Selected_Sector_Id: sectorId ? sectorId : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Section_Name_AR
						: item.Department_Section_Name_EN;

				sectionArr.push({ value: item.Department_Section_Id, label });
			});
			this.setState({ sectionsList: sectionArr });
		}
	};

	getStatistics = async (val) => {
		const response = await apiConfig.post(
			"API/ADMINISTRATION/USERS/DASHBOARDS/GET_DASHBOARD_STATISTICS",
			{
				Selected_Department_Id: val?.municipalId ? val?.municipalId : 0,
				Selected_Sector_Id: val?.sectorId ? val?.sectorId : 0,
				Selected_Section_Id: val?.sectionId ? val?.sectionId : 0,
				From_Date: val?.fromDate ? val?.fromDate : this.state.fromDate,
				To_Date: val?.toDate ? val?.toDate : this.state.toDate,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			// Arrange cards due to (Dashboard_Card_No)
			let newArr = response.data.Data.sort((r1, r2) =>
				r1.Dashboard_Card_No > r2.Dashboard_Card_No
					? 1
					: r1.Dashboard_Card_No < r2.Dashboard_Card_No
					? -1
					: 0
			);
			this.setState({ cardElements: newArr });
			// Get last refresh time
			this.getTime();
		}

		this.setState({
			municipalId: val?.municipalId ? val?.municipalId : 0,
			sectorId: val?.sectorId ? val?.sectorId : 0,
			sectionId: val?.sectionId ? val?.sectionId : 0,
		});

		response?.data?.Data.filter((x) => {
			if (
				x.Dashboard_Card_Id == this.state.activeCard.Dashboard_Card_Id
			) {
				if (
					x.Card_Statistics == this.state.activeCard.Card_Statistics
				) {
					this.setState({ showRefreshBtn: false });
				} else {
					this.setState({ showRefreshBtn: true });
				}
			}
		});
	};

	handleClickedCard = (cardData) => {
		if (cardData.Card_Statistics > 0) {
			this.setState({
				activeCard: cardData,
				gridName:
					this.props.i18n.language === "ar"
						? cardData.Dashboard_Card_Name_AR
						: cardData.Dashboard_Card_Name_EN,
			});
			this.getDataList({ cardId: cardData.Dashboard_Card_Id });
		}
	};

	getDataList = async (val) => {
		this.props.setLoading(true, "dark");
		const response = await apiConfig.post(
			"API/ADMINISTRATION/USERS/DASHBOARDS/GET_DASHBOARD_DATA_LIST",
			{
				Lang: this.props.i18n.language,
				Selected_Department_Id: this.state.municipalId,
				Selected_Sector_Id: this.state.sectorId,
				Selected_Section_Id: this.state.sectionId,
				From_Date: this.state.fromDate,
				To_Date: this.state.toDate,
				Dashboard_Card_Id: val.cardId,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ dataList: response.data.Data });
		}

		this.setState({ dataType: this.state.activeCard.System_Grid_Name });
		this.props.setLoading(false);
	};

	handleTooltip(item) {
		this.setState((prevState) => ({
			tooltipIsVisible: { [item]: !this.state.tooltipIsVisible[item] },
		}));
	}

	// handleFilteredItems = (data) => {
	//   if(this.searchResults !== data) {
	//     this.searchResults = data
	//   }
	//   // this.setState((prev) => {
	//   //   if (prev.searchResults !== data) {
	//   //     return { ...prev, searchResults: data };
	//   //   }
	//   // });
	// };

	componentDidMount() {
		this.getMunicipal();
		this.getSections();
		this.getSectors();
		this.getStatistics({});

		const intervalStatisticsId = setInterval(
			() => this.getStatistics(),
			this.props.currentUser.Data.Refresh_Time
		);
		this.setState({ intervalStatisticsId });
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalStatisticsId);
	}

	render() {
		return (
			<>
				<div className={style.dashboardOverlay}>
					{/* Form */}
					<Row className="justify-content-center">
						<Col xl={10}>
							<Row>
								<Col
									md={4}
									xs={12}
									className="d-flex justify-content-center align-items-center"
								>
									<h3 className={style.title}>
										{this.props.t("dashboard")}
									</h3>
								</Col>

								<Col md={8} xs={12}>
									<Form
										onSubmit={this.onSubmit}
										render={({
											handleSubmit,
											form,
											values,
										}) => (
											<form
												onSubmit={() => handleSubmit}
												id="dashboardForm"
												className={[
													`row flex-column px-5`,
													style.dashboardForm,
												].join(" ")}
											>
												{/* <Row className="justify-content-center">
                      <Col xs={12} md={3}>
                        <Select
                          label={this.props.t("municipal")}
                          name="municipal"
                          data={this.state.municipalList}
                          onChange={(e) => {
                            form.change("municipal", e.target.value);
                            this.getSectors(e.target.value);
                            this.getStatistics({
                              municipalId: e.target.value,
                            });
                          }}
                        ></Select>
                      </Col>

                      <Col xs={6} md={3}>
                        <Select
                          label={this.props.t("department")}
                          name="department"
                          data={this.state.sectorsList}
                          onChange={(e) => {
                            form.change("department", e.target.value);
                            this.getSections(values.municipal, e.target.value);
                            this.getStatistics({
                              municipalId: values.municipal,
                              sectorId: e.target.value,
                            });
                          }}
                        ></Select>
                      </Col>

                      <Col xs={6} md={3}>
                        <Select
                          label={this.props.t("section")}
                          name="section"
                          data={this.state.sectionsList}
                          onChange={(e) => {
                            form.change("section", e.target.value);
                            this.getStatistics({
                              municipalId: values.municipal,
                              sectorId: values.department,
                              sectionId: e.target.value,
                            });
                          }}
                        ></Select>
                      </Col>
                    </Row> */}

												<Row className="justify-content-center mb-3">
													<Col xs={12} sm={6}>
														<label
															className={
																style.dateLabel
															}
														>
															{this.props.t(
																"fromDate"
															)}
														</label>
														<DateBox
															className={
																style.date
															}
															acceptCustomValue={
																false
															}
															defaultValue={
																this.state
																	.fromDate
															}
															dateSerializationFormat="yyyy-MM-dd"
															displayFormat="yyyy-MM-dd"
															onValueChanged={(
																e
															) => {
																form.change(
																	"from_Date",
																	e.value
																);
																this.setState({
																	fromDate:
																		e.value,
																});
																this.getStatistics(
																	{
																		municipalId:
																			values.municipal,
																		sectorId:
																			values.department,
																		sectionId:
																			values.section,
																		fromDate:
																			e.value,
																	}
																);
															}}
															type="date"
														/>
													</Col>

													<Col xs={12} sm={6}>
														<label
															className={
																style.dateLabel
															}
														>
															{this.props.t(
																"toDate"
															)}
														</label>
														<DateBox
															className={
																style.date
															}
															acceptCustomValue={
																false
															}
															defaultValue={
																this.state
																	.toDate
															}
															dateSerializationFormat="yyyy-MM-dd"
															displayFormat="yyyy-MM-dd"
															onValueChanged={(
																e
															) => {
																form.change(
																	"to_Date",
																	e.value
																);
																this.setState({
																	toDate: e.value,
																});
																this.getStatistics(
																	{
																		municipalId:
																			values.municipal,
																		sectorId:
																			values.department,
																		sectionId:
																			values.section,
																		fromDate:
																			values.from_Date,
																		toDate: e.value,
																	}
																);
															}}
															type="date"
														/>
													</Col>
												</Row>
											</form>
										)}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* Form */}

					{/* Cards */}
					<Row className="justify-content-center">
						<Col xl={10}>
							<Row>
								<Col md={4} className="p-2">
									{
										<div
											className={`${[
												"d-flex justify-content-between",
												style.activeCard,
											].join(" ")} 
                    ${style.orangeBackground}`}
										>
											{this.state.activeCard
												.Dashboard_Card_Id ? (
												<>
													<div className="d-flex flex-column justify-content-between align-items-baseline w-75">
														{this.state.activeCard
															.Dashboard_Card_Icon_URL && (
															<div
																className={
																	style.imageCont
																}
															>
																<img
																	alt=""
																	src={
																		this
																			.state
																			.activeCard
																			.Dashboard_Card_Icon_URL
																	}
																/>
															</div>
														)}

														<div
															className={
																style.cardTitle
															}
														>
															{this.props.i18n
																.language ===
															"ar"
																? this.state
																		.activeCard
																		.Dashboard_Card_Name_AR
																: this.state
																		.activeCard
																		.Dashboard_Card_Name_EN}
														</div>
													</div>
													<div
														className={
															style.cardStat
														}
													>
														<div>
															{
																this.state
																	.activeCard
																	.Card_Statistics
															}
														</div>
														{this.state
															.showRefreshBtn ? (
															<div
																className={
																	style.activeCardRefresh
																}
																onClick={() =>
																	this.getDataList(
																		{
																			cardId: this
																				.state
																				.activeCard
																				.Dashboard_Card_Id,
																		}
																	)
																}
															>
																<svg
																	version="1.0"
																	xmlns="http://www.w3.org/2000/svg"
																	width="512.000000pt"
																	height="512.000000pt"
																	viewBox="0 0 512.000000 512.000000"
																	preserveAspectRatio="xMidYMid meet"
																>
																	<g
																		transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
																		fill="#000000"
																		stroke="none"
																	>
																		<path
																			d="M2320 5110 c-793 -77 -1517 -526 -1935 -1200 -79 -128 -200 -380
															-220 -459 -21 -79 -14 -135 26 -218 68 -141 239 -209 393 -157 64 22 149 101
															178 167 141 319 266 506 470 703 691 668 1787 714 2538 107 92 -75 249 -236
															314 -323 l48 -65 -168 -5 c-191 -7 -216 -14 -291 -83 -154 -142 -133 -383 45
															-500 82 -55 124 -59 667 -55 435 3 484 5 528 22 66 25 149 103 178 168 l24 53
															0 535 0 535 -24 53 c-31 69 -114 145 -183 168 -193 64 -392 -56 -424 -254 l-7
															-42 -104 105 c-398 399 -895 648 -1458 731 -124 18 -467 26 -595 14z"
																		/>
																		<path
																			d="M224 2049 c-49 -14 -125 -69 -161 -117 -63 -83 -64 -93 -61 -663 l3
															-514 26 -55 c56 -118 160 -184 289 -184 60 0 86 5 130 25 104 49 175 150 187
															264 l6 55 104 -105 c370 -371 848 -622 1362 -714 571 -103 1176 -4 1683 276
															361 199 683 497 911 843 85 130 231 423 253 509 39 153 -46 316 -193 371 -66
															24 -161 26 -227 4 -64 -22 -149 -101 -178 -167 -140 -319 -270 -512 -480 -712
															-275 -262 -601 -426 -988 -497 -165 -31 -494 -31 -660 0 -387 71 -725 242
															-998 506 -86 82 -212 226 -212 240 0 3 46 6 103 6 221 0 322 47 395 185 23 43
															27 62 27 140 0 78 -4 97 -27 140 -34 65 -90 118 -158 149 l-55 26 -525 -1
															c-289 0 -539 -5 -556 -10z"
																		/>
																	</g>
																</svg>
															</div>
														) : null}
													</div>
												</>
											) : (
												<div
													className={
														style.logoContainer
													}
												>
													<img
														alt="logo"
														src={
															process.env
																.REACT_APP_IMG_SRC ==
															2
																? logoStrip
																: logo
														}
													/>
												</div>
											)}
										</div>
									}

									<div>
										<Row>
											<Col
												xs={12}
												className="d-flex flex-column justify-content-center align-items-center mt-3 mb-2"
											>
												<div
													className={
														style.refreshIcon
													}
													onClick={() =>
														this.getStatistics()
													}
												>
													<svg
														version="1.0"
														xmlns="http://www.w3.org/2000/svg"
														width="512.000000pt"
														height="512.000000pt"
														viewBox="0 0 512.000000 512.000000"
														preserveAspectRatio="xMidYMid meet"
													>
														<g
															transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
															fill="#000000"
															stroke="none"
														>
															<path
																d="M2320 5110 c-793 -77 -1517 -526 -1935 -1200 -79 -128 -200 -380
														-220 -459 -21 -79 -14 -135 26 -218 68 -141 239 -209 393 -157 64 22 149 101
														178 167 141 319 266 506 470 703 691 668 1787 714 2538 107 92 -75 249 -236
														314 -323 l48 -65 -168 -5 c-191 -7 -216 -14 -291 -83 -154 -142 -133 -383 45
														-500 82 -55 124 -59 667 -55 435 3 484 5 528 22 66 25 149 103 178 168 l24 53
														0 535 0 535 -24 53 c-31 69 -114 145 -183 168 -193 64 -392 -56 -424 -254 l-7
														-42 -104 105 c-398 399 -895 648 -1458 731 -124 18 -467 26 -595 14z"
															/>
															<path
																d="M224 2049 c-49 -14 -125 -69 -161 -117 -63 -83 -64 -93 -61 -663 l3
														-514 26 -55 c56 -118 160 -184 289 -184 60 0 86 5 130 25 104 49 175 150 187
														264 l6 55 104 -105 c370 -371 848 -622 1362 -714 571 -103 1176 -4 1683 276
														361 199 683 497 911 843 85 130 231 423 253 509 39 153 -46 316 -193 371 -66
														24 -161 26 -227 4 -64 -22 -149 -101 -178 -167 -140 -319 -270 -512 -480 -712
														-275 -262 -601 -426 -988 -497 -165 -31 -494 -31 -660 0 -387 71 -725 242
														-998 506 -86 82 -212 226 -212 240 0 3 46 6 103 6 221 0 322 47 395 185 23 43
														27 62 27 140 0 78 -4 97 -27 140 -34 65 -90 118 -158 149 l-55 26 -525 -1
														c-289 0 -539 -5 -556 -10z"
															/>
														</g>
													</svg>
													{this.props.t("refreshNow")}
												</div>
												<p className={style.lastUpdate}>
													{this.props.t("lastUpdate")}{" "}
													{this.state.lastRefresh}
												</p>
											</Col>
										</Row>
									</div>
								</Col>

								<Col md={8}>
									<Row>
										{this.state.cardElements.map(
											(cardData) => {
												return (
													<>
														<Col
															md={4}
															className={[
																"p-2",
																style.tooltip1,
															].join(" ")}
															key={
																cardData.Dashboard_Card_No
															}
															id={(cardData?.Dashboard_Card_Name_EN).replace(
																/\s+/g,
																""
															)}
															onClick={() =>
																this.handleClickedCard(
																	cardData
																)
															}
															onMouseEnter={() =>
																this.handleTooltip(
																	(cardData?.Dashboard_Card_Name_EN).replace(
																		/\s+/g,
																		""
																	)
																)
															}
															onMouseLeave={() =>
																this.handleTooltip(
																	(cardData?.Dashboard_Card_Name_EN).replace(
																		/\s+/g,
																		""
																	)
																)
															}
														>
															<div
																className={[
																	"d-flex justify-content-between",
																	style.cards,
																	cardData.Card_Statistics ==
																	0
																		? style.disabledCard
																		: style.enabledCard,
																].join(" ")}
															>
																<div className="d-flex flex-column justify-content-between align-items-baseline">
																	<img
																		alt=""
																		className="d-flex align-self-start"
																		src={
																			cardData.Dashboard_Card_Icon_URL
																		}
																		height="60px"
																	/>
																	<div
																		className={
																			style.cardTitle
																		}
																	>
																		{this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? cardData.Dashboard_Card_Name_AR
																			: cardData.Dashboard_Card_Name_EN}
																	</div>
																</div>
																<div
																	className={
																		style.cardStat
																	}
																>
																	{
																		cardData.Card_Statistics
																	}
																</div>
															</div>
														</Col>

														{cardData.Dashboard_Card_Description_AR ||
														cardData.Dashboard_Card_Description_EN ? (
															<Tooltip
																target={
																	"#" +
																	(cardData?.Dashboard_Card_Name_EN).replace(
																		/\s+/g,
																		""
																	)
																}
																visible={
																	this.state
																		?.tooltipIsVisible[
																		(cardData?.Dashboard_Card_Name_EN).replace(
																			/\s+/g,
																			""
																		)
																	]
																}
																hideOnOutsideClick={
																	true
																}
																className={
																	style.tooltip
																}
																copyRootClassesToWrapper={
																	true
																}
															>
																<div>
																	{this.props
																		.i18n
																		.language ===
																	"ar"
																		? cardData.Dashboard_Card_Description_AR
																		: cardData.Dashboard_Card_Description_EN}
																</div>
															</Tooltip>
														) : (
															false
														)}
													</>
												);
											}
										)}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* Cards */}
				</div>

				<Row className="mt-5">
					{/* Grid Title */}
					{/* {this.state.showTitle ? (
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div>
                <p className={style.searchResultsLabel}>
                  {this.props.t("filter total result")}
                </p>
                <p className={style.searchResults}>
                  ({this.props.dataList.length})
                </p>
              </div>
            </div>
          ) : null} */}
					{/* Grid Title */}

					<div className={`${style.dataGridTitle} mt-5`}>
						<h3>
							{this.props.i18n.language == "ar"
								? this.state.activeCard.Dashboard_Card_Name_AR
								: this.state.activeCard.Dashboard_Card_Name_EN}
						</h3>
					</div>
					{/* Grid */}
					<DashboardList
						dataList={this.state.dataList}
						cardId={this.state.activeCard.Dashboard_Card_Id}
						dataType={this.state.dataType}
						gridName={this.state.gridName}
						systemGridName={this.state.activeCard.System_Grid_Name}
						// filteredItems={(data) => this.handleFilteredItems(data)}
						// showTitle={(data) => this.setState({ showTitle: data })}
						// clearDashboard={() => this.setState({activeCard: {}, dataList: []})}
					/>
					{/* Grid */}
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(DashboardView));
