import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "../return/ReturnStep.module.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import FormControl from "@material-ui/core/FormControl";

class EscalateStep extends Component {
	state = {
		usersList: [
			{
				value: 0,
				label: this.props.t("pleaseSelect"),
			},
		],
		receiverType: "2",
		userId: 0,
	};

	getUsersList = async () => {
		let stepsArr = [],
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Request_Task_Escalate_Step_Users_List",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.User_Name_AR
						: item.User_Name_EN;

				stepsArr.push({ value: item.User_Id, label });
			});

			this.setState({
				usersList: [...this.state.usersList, ...stepsArr],
			});
		}
	};

	onReturnRequestStepSubmit = async (values, form) => {};

	componentDidMount() {
		this.getUsersList();
	}

	handleEscalateToType = (e) => {
		this.setState({ receiverType: e.target.value });
		this.props.setValuesCB((prev) => {
			if (e.target.value == 2) {
				return {
					Escalate_Step_Receiver_Type_Id: e.target.value,
					Escalate_Step_Receiver_User_Id: null,
				};
			}
			return { ...prev, Escalate_Step_Receiver_Type_Id: e.target.value };
		});
	};

	handleEscalateToUserSelect = (e) => {
		this.setState({ userId: e.target.value });
		this.props.setValuesCB((prev) => {
			return { ...prev, Escalate_Step_Receiver_User_Id: e.target.value };
		});
	};

	render() {
		const validate = (values) => {
			const errors = {};

			if (values.receiverType === "3" && !values.userId) {
				errors.userId = `${this.props.t("error.field_required")}`;
			}

			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.onReturnRequestStepSubmit}
					initialValues={{
						receiverType: this.state.receiverType,
						userId: this.state.userId,
					}}
					validate={validate}
					validateOnBlur={true}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="escalateStepForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={8}>
								<FormControl className="mt-3">
									<label className={style.radioLabel}>
										{this.props.t("escalateTo")}
									</label>
									<RadioGroup
										row
										name="receiverType"
										value={this.state.receiverType}
										onChange={this.handleEscalateToType}
									>
										<FormControlLabel
											value="2"
											control={<Radio />}
											label={this.props.t(
												"escalateToUserGroup"
											)}
										/>
										<FormControlLabel
											value="3"
											control={<Radio />}
											label={this.props.t(
												"escalateToUserGroupUser"
											)}
										/>
									</RadioGroup>
								</FormControl>
							</Col>
							{values?.receiverType === "3" && (
								<Col xs={12}>
									<Select
										label={this.props.t("userGroupUsers")}
										className="mb-2"
										name="userId"
										required={true}
										data={this.state.usersList}
										value={this.state.userId}
										onChange={
											this.handleEscalateToUserSelect
										}
										showError={() => {
											let fieldStatus =
												form.getFieldState("userId");

											if (fieldStatus) {
												return fieldStatus.invalid;
											}
										}}
									></Select>
								</Col>
							)}
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(EscalateStep));
