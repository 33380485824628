import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";
import moment from "moment";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { DataGrid, Column, Lookup, Editing } from "devextreme-react/data-grid";

import { Toolbar, Item } from "devextreme-react/html-editor";
import Wysiwyg from "../UI/Wysiwyg";

import CustomStore from "devextreme/data/custom_store";

const toolbaroptions = {
	items: [
		"undo",
		"redo",
		"separator",
		"bold",
		"italic",
		"underline",
		"strike",
		"separator",
		"link",
		{
			formatName: "header",
			formatValues: [false, 3, 4, 5],
		},
		{
			formatName: "size",
			formatValues: ["11px", "14px", "16px", "22px"],
		},
		"orderedList",
		"bulletList",
		"alignLeft",
		"alignCenter",
		"alignRight",
	],
};

class ServiceDetailForm extends Component {
	state = {
		detailTypes: [],
		usedServices: [],
		dropDownApisList: [],
		dropDownIntegrationApisList: [],
		fileTypes: [],
		serviceLookups: [],
		showForm: false,
		selectedIntegrationApi: "",
		serviceDetailsApisInputsList: [],
		serviceDetailListForInputs: [],
		detailsFormsList: [],
		linkedWithDropDown: [],
		serviceDetailPredefinedFields: [],
		langsList: [],
		inputsFormatsList: [],
	};

	getLanguages = async () => {
		let typesArr = [{ value: 0, label: this.props.t("NaN") }];
		const response = await apiConfig.post("/API/SYSTEM/DDL/GET_LANGUAGUES");

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ langsList: typesArr });
		}
	};

	getDetailTypes = async () => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_DETAILS_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ detailTypes: typesArr });
		}
	};
	getusedServices = async () => {
		let usedServicesArr = [{ value: 0, label: this.props.t("NaN") }],
			url =
				"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_VALIDATION_APIS_LIST";

		const response = await apiConfig.post(
			url,
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_API_Name_AR
						: item.Service_Detail_API_Name_EN;

				usedServicesArr.push({
					value: item.Service_Detail_API_Id,
					label,
				});
			});

			this.setState({ usedServices: usedServicesArr });
		}
	};

	getdropDownApisList = async () => {
		let dropDownApisListArr = [],
			url =
				"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_DROPDOWN_DATA_APIS_LIST";

		const response = await apiConfig.post(
			url,
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_API_Name_AR
						: item.Service_Detail_API_Name_EN;

				dropDownApisListArr.push({
					value: item.Service_Detail_API_Id,
					label,
				});
			});

			this.setState({ dropDownApisList: dropDownApisListArr });
		}
	};

	getdropDownIntegrationApisList = async () => {
		let dropDownApisListArr = [],
			url =
				"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_INTEGRATION_APIS_LIST";

		const response = await apiConfig.post(
			url,
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_API_Name_AR
						: item.Service_Detail_API_Name_EN;

				dropDownApisListArr.push({
					value: item.Service_Detail_API_Id,
					label,
				});
			});

			this.setState({ dropDownIntegrationApisList: dropDownApisListArr });
		}
	};

	getFileTypes = async () => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_FILES_CONTENTS_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ fileTypes: typesArr });
		}
	};

	getServiceLookups = async () => {
		let lookupsArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				lookupsArr.push({ value: item.Record_Id, label });
			});

			this.setState({ serviceLookups: lookupsArr });
		}
	};

	getServiceDetailsApisInputsList = async () => {
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_APIS_INPUTS_LIST",
			{
				Service_Detail_Id: this.props.editMood
					? this.props.serviceDetailId
					: 0,
				Service_Detail_API_Id: this.state.selectedIntegrationApi,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (
			response.data.Status &&
			response.data.Data &&
			response.data.Data.length > 0
		) {
			this.setState({ serviceDetailsApisInputsList: response.data.Data });
		}
	};

	getServiceDetailsListForInputs = async () => {
		let lookupsArr = [];

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_LIST_FOR_INPUTS",
			{
				Service_Id: this.props.serviceid,
			},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_Title_AR
						: item.Service_Detail_Title_EN;

				lookupsArr.push({ value: item.Service_Detail_Id, label });
			});
			this.setState({ serviceDetailListForInputs: lookupsArr });
		}
	};

	getPredefinedFieldsList = async () => {
		let dropdownPredefinedFieldsArr = [],
			url = "/API/ADMINISTRATION/SERVICES/GET_PREDEFINED_FIELDS";

		dropdownPredefinedFieldsArr.push({
			value: 0,
			label: this.props.t("NaN"),
		});

		const response = await apiConfig.post(
			url,
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_Predefined_Field_Name_AR
						: item.Service_Detail_Predefined_Field_Name_EN;

				dropdownPredefinedFieldsArr.push({
					value: item.Service_Detail_Predefined_Field_Id,
					label,
				});
			});

			this.setState({
				serviceDetailPredefinedFields: dropdownPredefinedFieldsArr,
			});
		}
	};

	getServiceDetailFormsList = async () => {
		let typesArr = [];
		let at = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/DDL_SERVICE_DETAILS_FORMS_LIST",
			{ Service_Id: this.props.serviceid },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_Form_Title_AR
						: item.Service_Detail_Form_Title_EN;

				typesArr.push({ value: item.Service_Detail_Form_Id, label });
			});

			this.setState({ detailsFormsList: typesArr });
		}
	};
	getDropDownLinkedWithList = async () => {
		let typesArr = [{ value: 0, label: this.props.t("NaN") }];
		let at = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/DDL_SERVICE_DETAILS_RELATION_DROPDOWN_LIST",
			{ Service_Id: this.props.serviceid },
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_Title_AR
						: item.Service_Detail_Title_EN;

				typesArr.push({ value: item.Service_Detail_Id, label });
			});

			this.setState({ linkedWithDropDown: typesArr });
		}
	};

	getInputsFormatsList = async () => {
		let typesArr = [{ value: 0, label: this.props.t("NaN") }];
		let at = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/DDL_INPUTS_FORMATS_LIST",
			{},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Service_Detail_Format_Name_AR
						: item.Service_Detail_Format_Name_EN;

				typesArr.push({ value: item.Service_Detail_Format_Id, label });
			});

			this.setState({ inputsFormatsList: typesArr });
		}
	};

	componentDidMount() {
		this.getusedServices();
		this.getdropDownApisList();
		this.getdropDownIntegrationApisList();
		this.getDetailTypes();
		this.getFileTypes();
		this.getServiceLookups();
		this.getServiceDetailsListForInputs();
		this.getPredefinedFieldsList();
		this.getServiceDetailFormsList();
		this.getDropDownLinkedWithList();
		this.getLanguages();
		this.getInputsFormatsList();

		if (this.props.initValues?.usedServices) {
			this.setState({
				selectedIntegrationApi: this.props.initValues?.usedServices,
			});
		}
	}

	componentDidUpdate(prevProps, prevSate) {
		if (
			this.state.selectedIntegrationApi !== "" &&
			this.state.selectedIntegrationApi !==
				prevSate.selectedIntegrationApi
		) {
			this.getServiceDetailsApisInputsList();
		}
	}

	validate = (values) => {
		const errors = {};

		if (!values.detailNameAR) {
			errors.detailNameAR = `${this.props.t("error.field_required")}`;
		}

		if (!values.detailNameEN) {
			errors.detailNameEN = `${this.props.t("error.field_required")}`;
		}

		if (!values.detailType) {
			errors.detailType = `${this.props.t("error.field_required")}`;
		}

		// if (
		// 	(values.detailType === 1 ||
		// 		values.detailType === 2 ||
		// 		values.detailType === 4 ||
		// 		values.detailType === 5 ||
		// 		values.detailType === 6 ||
		// 		values.detailType === 7 ||
		// 		values.detailType === 8 ||
		// 		values.detailType === 9) &&
		// 	!values.usedServices
		// ) {
		// 	errors.usedServices = `${this.props.t("error.field_required")}`;
		// }

		if (values.detailType === 11 && !values.usedServices) {
			errors.usedServices = `${this.props.t("error.field_required")}`;
		}

		if (values.detailType === 12 && !values.usedServices) {
			errors.usedServices = `${this.props.t("error.field_required")}`;
		}

		if (
			values.detailType === 3 &&
			!values.fileType &&
			!values.Service_File_Signature
		) {
			errors.fileType = `${this.props.t("error.field_required")}`;
		}

		if (values.detailType === 2 && !values.serviceLookups) {
			errors.serviceLookups = `${this.props.t("error.field_required")}`;
		}

		if (values.detailType !== 12 && !values.serviceDetailFormId) {
			errors.serviceDetailFormId = `${this.props.t(
				"error.field_required"
			)}`;
		}

		// if (values.detailType === 10 && !values.serviceDetailLabelEN) {
		// 	errors.serviceDetailLabelEN = `${this.props.t(
		// 		'error.field_required'
		// 	)}`;
		// }

		// if (values.detailType === 10 && !values.serviceDetailLabelAR) {
		// 	errors.serviceDetailLabelAR = `${this.props.t(
		// 		'error.field_required'
		// 	)}`;
		// }

		/*if (values.detailType === 1 && !values.predefinedField) {
			errors.predefinedField = `${this.props.t("error.field_required")}`;
		}*/

		if (
			!values.Service_File_Max_Count &&
			values.detailType === 3 &&
			!values.Service_File_Signature
		) {
			errors.Service_File_Max_Count = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (
			!values.Service_File_Max_Size &&
			values.detailType === 3 &&
			!values.Service_File_Signature
		) {
			errors.Service_File_Max_Size = `${this.props.t(
				"error.field_required"
			)}`;
		}

		return errors;
	};

	onServiceDetailSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/SERVICES/ADD_SERVICE_DETAIL"
				: "/API/ADMINISTRATION/SERVICES/UPD_SERVICE_DETAIL";

		if (values.detailType === 7 && !values.checkboxDefault) {
			values.checkboxDefault = 0;
		} else if (values.detailType === 7 && values.checkboxDefault) {
			values.checkboxDefault = 1;
		} else {
			values.checkboxDefault = null;
		}

		if (values.detailType === 6 && !values.allowOldDate) {
			values.allowOldDate = 0;
		} else if (values.detailType === 6 && values.allowOldDate) {
			values.allowOldDate = 1;
		} else {
			values.allowOldDate = null;
		}

		if (
			(values.detailType === 1 || values.detailType === 4) &&
			!values.allowSymbols
		) {
			values.allowSymbols = 0;
		}

		if (values.detailType === 10) {
			values.required = 0;
		}

		let bodyData = {
			Service_Detail_Type_Id: values.detailType,
			Service_Detail_API_Id: values.usedServices,
			Service_Detail_Title_AR: values.detailNameAR,
			Service_Detail_Title_EN: values.detailNameEN,
			Service_Detail_Required: values.required ? 1 : 0,
			Service_Lookup_Id: values.serviceLookups,
			Service_Detail_Predefined_Field_Id: values.predefinedField,
			Service_File_Content_Type_Id: values.fileType
				? values.fileType
				: null,
			Service_File_Extension: values.fileExtension
				? values.fileExtension
				: null,
			Service_Allow_Old_Date: values.allowOldDate,
			Service_ChkBox_Status: values.checkboxDefault,
			Service_Detail_Template_Code: values.templateCode,
			API_Inputs: this.state.serviceDetailsApisInputsList.map(
				({ Service_Detail_Id, Service_Detail_API_Input_Id }) => {
					return {
						Service_Detail_API_Input_Id,
						Service_Detail_Id,
					};
				}
			),
			Service_Detail_Form_Id:
				values.detailType === 12 ? null : values.serviceDetailFormId,
			Service_Detail_Lang_Id:
				values.detailType === 1 || values.detailType === 4
					? values.serviceDetailLangId
					: null,
			Service_Detail_Format_Id:
				values.detailType === 1 ? values.serviceDetailFormatId : null,
			Service_Detail_Allow_Symbols: values.allowSymbols ? 1 : 0,
			Service_Detail_Label_Text_EN: values.serviceDetailLabelEN,
			Service_Detail_Label_Text_AR: values.serviceDetailLabelAR,
			Service_Detail_Relation_DropDown_Input_Id:
				values.Service_Detail_Relation_DropDown_Input_Id,
			Service_File_Signature:
				values.detailType === 3
					? values.Service_File_Signature
						? 1
						: 0
					: 0,
			Service_File_Signature_Attachment:
				values.detailType === 3
					? values.Service_File_Signature_Attachment
						? 1
						: 0
					: 0,
			Service_File_Max_Count:
				values.detailType === 3 && !values.Service_File_Signature
					? values.Service_File_Max_Count
					: null,
			Service_File_Max_Size:
				values.detailType === 3 && !values.Service_File_Signature
					? values.Service_File_Max_Size
					: null,
		};

		if (!this.props.editMood) {
			bodyData.Service_Id = this.props.serviceid;
		} else {
			bodyData.Service_Detail_Id = this.props.serviceDetailId;
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			if (this.props.editMood) {
				this.props.triggerDialogForm(false);
			}
			this.props.reloadDatagrid();
			form.restart();
			// form.change('usedServices', '')
			this.setState({
				selectedIntegrationApi: "",
			});
			this.getServiceDetailsListForInputs();
		}
	};

	render() {
		let { initValues } = this.props,
			columnAlign = "left";

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Service_Detail_API_Input_Id",
			load: () => {
				return this.state.serviceDetailsApisInputsList;
			},
			update: (key, values) => {
				this.setState({
					serviceDetailsApisInputsList:
						this.state.serviceDetailsApisInputsList.map(
							(detail) => {
								if (
									detail.Service_Detail_API_Input_Id === key
								) {
									detail.Service_Detail_Id =
										values.Service_Detail_Id;
								}

								return detail;
							}
						),
				});
			},
		});

		return (
			<>
				<Form
					// subscription={{ submitting: true }}
					onSubmit={this.onServiceDetailSubmit}
					initialValues={
						initValues
							? initValues
							: {
									detailNameAR: "",
									detailNameEN: "",
									detailType: "",
									usedServices: 0,
									predefinedField: 0,
									serviceDetailLangId: 0,
									serviceDetailFormatId: 0,
									Service_File_Max_Count: 1,
									Service_File_Max_Size: "0",
									required: true,
									Service_File_Signature: false,
									Service_File_Signature_Attachment: false,
							  }
					}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						if (
							(values.detailType !== 1) &
								(values.detailType !== 2) &
								(values.detailType !== 3) &&
								(values.detailType !== 4) &
								(values.detailType !== 5) &
								(values.detailType !== 6) &
								(values.detailType !== 8) &
								(values.detailType !== 9) &
								(values.detailType !== 11) &&
							values.predefinedField
						) {
							delete values.predefinedField;
						}

						if (values.detailType !== 2 && values.serviceLookups) {
							delete values.serviceLookups;
						}

						if (values.detailType !== 3 && values.fileExtension) {
							delete values.fileExtension;
						}

						if (values.detailType !== 3 && values.fileType) {
							delete values.fileType;
						}

						if (values.detailType !== 6 && values.allowOldDate) {
							delete values.allowOldDate;
						}

						if (values.detailType !== 7 && values.checkboxDefault) {
							delete values.checkboxDefault;
						}

						if (
							values.detailType == 3 &&
							!values.Service_File_Signature &&
							values.templateCode
						) {
							delete values.templateCode;
						}

						return (
							<>
								{!this.props.editMood &&
									!this.props.viewMood && (
										<div className="d-flex justify-content-end">
											<ButtonPrimary
												variant={
													!this.state.showForm
														? "contained"
														: "outlined"
												}
												onClick={() => {
													form.restart();
													this.setState({
														showForm:
															!this.state
																.showForm,
														serviceDetailsApisInputsList:
															[],
														selectedIntegrationApi:
															"",
													});
												}}
												type="button"
											>
												{!this.state.showForm &&
													this.props.t(
														"addNewDetail"
													)}
												{this.state.showForm &&
													this.props.t("cancel")}
											</ButtonPrimary>
										</div>
									)}
								<form
									id="serviceDetailForm"
									className={`row ${
										!this.state.showForm &&
										!this.props.editMood &&
										!this.props.viewMood
											? "d-none"
											: ""
									}`}
									onSubmit={handleSubmit}
									noValidate
								>
									<Col xs={12} md={6}>
										<TextField
											label={this.props.t("detailno")}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											name="detailNo"
											disabled
										/>
									</Col>

									<Col xs={12} md={6}>
										<Field
											name="detailType"
											subscription={{
												submitFailed: true,
												invalid: true,
											}}
											disabled={this.props.viewMood}
											required={true}
										>
											{(fieldProps) => (
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"servicedetailtype"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													required={true}
													name="detailType"
													data={
														this.state.detailTypes
													}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"detailType"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
													onChange={(e) => {
														fieldProps.input.onChange(
															e
														);
														this.setState({
															serviceDetailsApisInputsList:
																[],
															selectedIntegrationApi:
																"",
														});
														form.change(
															"usedServices",
															""
														);

														if (
															e.target.value ===
																11 ||
															e.target.value === 2
														) {
															this.getDropDownLinkedWithList();
														}
													}}
												></Select>
											)}
										</Field>
									</Col>

									<Col xs={12} md={6}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"servicedetailnameAR"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="detailNameAR"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>
									<Col xs={12} md={6}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"servicedetailnameEN"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="detailNameEN"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>

									<Col
										xs={12}
										md={6}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
									>
										<div className="d-flex">
											{values.detailType !== 10 && (
												<Switches
													disabled={
														this.props.viewMood
													}
													color="primary"
													name="required"
													data={{
														label: `${this.props.t(
															"required"
														)}`,
														value: `${this.props.t(
															"required"
														)}`,
													}}
												/>
											)}
											{values.detailType === 3 && (
												<>
													<Switches
														disabled={
															this.props.viewMood
														}
														color="primary"
														name="Service_File_Signature"
														data={{
															label: `${this.props.t(
																"fileIsESignature"
															)}`,
															value: `${this.props.t(
																"fileIsESignature"
															)}`,
														}}
													/>
													<Switches
														disabled={
															this.props.viewMood
														}
														color="primary"
														name="Service_File_Signature_Attachment"
														data={{
															label: `${this.props.t(
																"AllowSendExtSigAsPic"
															)}`,
															value: `${this.props.t(
																"AllowSendExtSigAsPic"
															)}`,
														}}
													/>
												</>
											)}
										</div>
									</Col>

									{values.detailType === 3 &&
										values.Service_File_Signature && (
											<Col xs={12} md={6}>
												<TextField
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"templateCode"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													name="templateCode"
													autoComplete="off"
												/>
											</Col>
										)}

									{(values.detailType === 1 ||
										values.detailType === 2 ||
										values.detailType === 4 ||
										values.detailType === 5 ||
										values.detailType === 6 ||
										values.detailType === 7 ||
										values.detailType === 8 ||
										values.detailType === 9) && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"usedServices"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												name="usedServices"
												data={this.state.usedServices}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"usedServices"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
												onChange={(e) => {
													form.change(
														"usedServices",
														e.target.value
													);

													this.setState({
														serviceDetailsApisInputsList:
															[],
														selectedIntegrationApi:
															e.target.value,
													});
													// this
													// 	.getServiceDetailsApisInputsList()
												}}
											></Select>
										</Col>
									)}

									{values.detailType === 11 && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"usedServices"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={true}
												name="usedServices"
												data={
													this.state.dropDownApisList
												}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"usedServices"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
												onChange={(e) => {
													form.change(
														"usedServices",
														e.target.value
													);
													this.setState({
														serviceDetailsApisInputsList:
															[],
														selectedIntegrationApi:
															e.target.value,
													});
													// this
													// 	.getServiceDetailsApisInputsList()
												}}
											></Select>
										</Col>
									)}

									{values.detailType === 12 && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"usedServices"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={true}
												name="usedServices"
												data={
													this.state
														.dropDownIntegrationApisList
												}
												value={
													this.state
														.selectedIntegrationApi
												}
												onChange={(e) => {
													form.change(
														"usedServices",
														e.target.value
													);
													this.setState({
														serviceDetailsApisInputsList:
															[],
														selectedIntegrationApi:
															e.target.value,
													});
													// this
													// 	.getServiceDetailsApisInputsList()
												}}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"usedServices"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									)}

									{values.detailType === 2 && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"dropdownListName"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={true}
												name="serviceLookups"
												data={this.state.serviceLookups}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"serviceLookups"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									)}

									{(values.detailType === 1 ||
										values.detailType === 2 ||
										values.detailType === 4 ||
										values.detailType === 5 ||
										values.detailType === 6 ||
										values.detailType === 8 ||
										values.detailType === 3 ||
										values.detailType === 9 ||
										values.detailType === 11) && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"predefinedField"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={false}
												name="predefinedField"
												data={
													this.state
														.serviceDetailPredefinedFields
												}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"predefinedField"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									)}

									{values.detailType === 3 &&
										!values.Service_File_Signature && [
											<Col xs={12} md={6}></Col>,
											<Col xs={12} md={3}>
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"fileType"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													required={true}
													name="fileType"
													data={this.state.fileTypes}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"fileType"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>,

											<Col xs={12} md={3}>
												<TextField
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"fileExtension"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													name="fileExtension"
												/>
											</Col>,
										]}

									{values.detailType === 6 && (
										<Col
											xs={12}
											md={6}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="secondary"
												name="allowOldDate"
												data={{
													label: `${this.props.t(
														"allowOldDate"
													)}`,
													value: `${this.props.t(
														"allowOldDate"
													)}`,
												}}
											/>
										</Col>
									)}

									{values.detailType === 7 && (
										<Col
											xs={12}
											md={6}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											}`}
										>
											<Switches
												disabled={this.props.viewMood}
												color="secondary"
												name="checkboxDefault"
												data={{
													label: `${this.props.t(
														"active"
													)}`,
													value: `${this.props.t(
														"active"
													)}`,
												}}
											/>
										</Col>
									)}

									{(values.detailType === 1 ||
										values.detailType === 4) && (
										<>
											<Col xs={12} md={6}>
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"fieldLang"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													required={false}
													name="serviceDetailLangId"
													data={this.state.langsList}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"serviceDetailLangId"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>

											<Col
												xs={12}
												md={6}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												}`}
											>
												<Switches
													disabled={
														this.props.viewMood
													}
													color="secondary"
													name="allowSymbols"
													data={{
														label: `${this.props.t(
															"allowSymbols"
														)}`,
														value: `${this.props.t(
															"allowSymbols"
														)}`,
													}}
												/>
											</Col>
										</>
									)}

									{values.detailType !== 3 &&
										values.detailType !== 10 && (
											<Col xs={12} md={6}>
												<TextField
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"templateCode"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													name="templateCode"
													autoComplete="off"
												/>
											</Col>
										)}

									{values.detailType === 1 && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"fieldFormat"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={false}
												name="serviceDetailFormatId"
												data={
													this.state.inputsFormatsList
												}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"serviceDetailFormatId"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									)}

									{values.detailType !== 12 && (
										<Col xs={12} md={6}>
											<Select
												disabled={this.props.viewMood}
												label={this.props.t(
													"applicationSteps"
												)}
												className={`${
													this.props.viewMood
														? "read-only"
														: ""
												} mb-2`}
												required={
													values.detailType !== 12
												}
												name="serviceDetailFormId"
												data={
													this.state.detailsFormsList
												}
												showError={() => {
													let fieldStatus =
														form.getFieldState(
															"serviceDetailFormId"
														);

													if (fieldStatus) {
														return (
															fieldStatus.submitFailed &&
															fieldStatus.invalid
														);
													}
												}}
											></Select>
										</Col>
									)}
									{values.detailType === 3 &&
										!values.Service_File_Signature && (
											<>
												<Col xs={12} md={3}>
													<TextField
														disabled={
															this.props.viewMood
														}
														label={this.props.t(
															"serviceFileMaxCount"
														)}
														className={`${
															this.props.viewMood
																? "read-only"
																: ""
														} mb-2`}
														required={
															!values.Service_File_Signature
																? true
																: false
														}
														name="Service_File_Max_Count"
														showError={({
															meta: {
																submitFailed,
																invalid,
															},
														}) => {
															return (
																submitFailed &&
																invalid
															);
														}}
													/>
												</Col>
												<Col xs={12} md={3}>
													<TextField
														disabled={
															this.props.viewMood
														}
														label={this.props.t(
															"serviceFileMaxSize"
														)}
														className={`${
															this.props.viewMood
																? "read-only"
																: ""
														} mb-2`}
														required={
															!values.Service_File_Signature
																? true
																: false
														}
														name="Service_File_Max_Size"
														showError={({
															meta: {
																submitFailed,
																invalid,
															},
														}) => {
															return (
																submitFailed &&
																invalid
															);
														}}
													/>
												</Col>
											</>
										)}

									{values.detailType === 2 && (
										<>
											<Col xs={12} md={6}></Col>

											<Col xs={12} md={6}>
												<Select
													disabled={
														this.props.viewMood
													}
													label={this.props.t(
														"linkedWithDropDown"
													)}
													className={`${
														this.props.viewMood
															? "read-only"
															: ""
													} mb-2`}
													required={false}
													name="Service_Detail_Relation_DropDown_Input_Id"
													data={
														this.state
															.linkedWithDropDown
													}
													showError={() => {
														let fieldStatus =
															form.getFieldState(
																"linkedWithDropDown"
															);

														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}
												></Select>
											</Col>
										</>
									)}

									{values.detailType === 10 && (
										<Col xs={12}>
											<Row>
												<Col xs={12} md={6}>
													<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
														"serviceDetailLabelAR"
													)} *`}</label>
													<Field
														name="serviceDetailLabelAR"
														subscription={{
															submitFailed: true,
															invalid: true,
														}}
														disabled={
															this.props.viewMood
														}
														required={false}
													>
														{(fieldProps) => (
															<Wysiwyg
																rtlEnabled={
																	this.props
																		.i18n
																		.language ===
																	"ar"
																		? true
																		: false
																}
																height="250px"
																defaultValue={
																	this.props
																		.initValues
																		?.serviceDetailLabelAR ??
																	""
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																readOnly={
																	this.props
																		.viewMood
																}
																// showError={() => {
																// 	let fieldStatus =
																// 		form.getFieldState(
																// 			'serviceDetailLabelAR'
																// 		);

																// 	if (
																// 		fieldStatus
																// 	) {
																// 		return (
																// 			fieldStatus.submitFailed &&
																// 			fieldStatus.invalid
																// 		);
																// 	}
																// }}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														)}
													</Field>
												</Col>

												<Col xs={12} md={6}>
													<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
														"serviceDetailLabelEN"
													)} *`}</label>
													<Field
														name="serviceDetailLabelEN"
														subscription={{
															submitFailed: true,
															invalid: true,
														}}
														disabled={
															this.props.viewMood
														}
														required={false}
													>
														{(fieldProps) => (
															<Wysiwyg
																rtlEnabled={
																	this.props
																		.i18n
																		.language ===
																	"ar"
																		? true
																		: false
																}
																height="250px"
																defaultValue={
																	this.props
																		.initValues
																		?.serviceDetailLabelEN ??
																	""
																}
																onChange={
																	fieldProps
																		.input
																		.onChange
																}
																readOnly={
																	this.props
																		.viewMood
																}
																// showError={() => {
																// 	let fieldStatus =
																// 		form.getFieldState(
																// 			'serviceDetailLabelEN'
																// 		);

																// 	if (
																// 		fieldStatus
																// 	) {
																// 		return (
																// 			fieldStatus.submitFailed &&
																// 			fieldStatus.invalid
																// 		);
																// 	}
																// }}
																toolbar={
																	toolbaroptions
																}
															></Wysiwyg>
														)}
													</Field>
												</Col>
											</Row>
										</Col>
									)}

									{this.state.selectedIntegrationApi !== "" &&
										this.state.serviceDetailsApisInputsList
											.length > 0 && (
											<>
												<Col xs={12}>
													<DataGrid
														className={[
															this.props.i18n
																.language ===
															"ar"
																? "dx-grid-ar"
																: "dx-grid-en",
															"globalBox",
														].join(" ")}
														id="dataGrid"
														width={"100%"}
														dataSource={
															jsonDataSource
														}
														showBorders={false}
														showColumnLines={false}
														showRowLines={true}
														allowColumnResizing={
															true
														}
														columnResizingMode={
															"widget"
														}
														allowColumnReordering={
															true
														}
														rowAlternationEnabled={
															false
														}
														focusedRowEnabled={true}
														columnWidth={"auto"}
														wordWrapEnabled={true}
														rtlEnabled={
															this.props.i18n
																.language ===
															"ar"
																? true
																: false
														}
													>
														<Editing
															mode="cell"
															allowUpdating={true}
															allowAdding={false}
															allowDeleting={
																false
															}
														/>
														<Column
															allowEditing={false}
															dataField="Service_Detail_API_Input_No"
															sortOrder="asc"
															caption={this.props.t(
																"varId"
															)}
															alignment={"center"}
															width={"120px"}
														></Column>

														<Column
															allowEditing={false}
															dataField={
																this.props.i18n
																	.language ===
																"ar"
																	? "Service_Detail_API_Input_Name_AR"
																	: "Service_Detail_API_Input_Name_EN"
															}
															caption={this.props.t(
																"varName"
															)}
															alignment={
																columnAlign
															}
														></Column>

														<Column
															allowEditing={true}
															cssClass="outlinedColumn"
															dataField="Service_Detail_Id"
															caption={this.props.t(
																"valFrom"
															)}
															alignment={"center"}
														>
															<Lookup
																dataSource={
																	this.state
																		.serviceDetailListForInputs
																}
																displayExpr="label"
																valueExpr="value"
															/>
														</Column>
													</DataGrid>
												</Col>
											</>
										)}

									<Col className="mt-4" xs={12}>
										<Row className="align-items-baseline">
											<Col className="mt-2 submit-btn-wrap">
												{!this.props.viewMood && (
													<ButtonPrimary
														color="primary"
														variant="contained"
														disabled={
															submitting ||
															this.props.viewMood
														}
														type="submit"
													>
														{this.props.t(
															"saveDetail"
														)}
													</ButtonPrimary>
												)}
											</Col>
										</Row>
									</Col>
								</form>
							</>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ServiceDetailForm));
