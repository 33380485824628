import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	MasterDetail,
	Selection,
	// SearchPanel,
	// FilterRow,
	// HeaderFilter,
	// ColumnChooser,
	// Export,
	// Grouping,
	// GroupPanel,
} from "devextreme-react/data-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import { ExcelJS , Workbook } from "exceljs";
// import saveAs from "file-saver";
// import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import { translationHook } from "../../../translationHook";
// import IconButton from '@material-ui/core/IconButton';
// import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ButtonDanger, ButtonSecondary } from "../../../UI/Buttons";
import {
	setLoading,
	triggerNotification,
	triggerDialogForm,
} from "../../../../actions";
import { deleteRequestInvoice } from "../../../../apis/requests/info/deleteRequestInvoice";
// import { deleteRequestTaskAttachment } from '../../../../apis/requests/info/deleteRequestTaskAttachment'
import VisibilityIcon from "@material-ui/icons/Visibility";

class InvoiceDetailsList extends Component {
	constructor(props) {
		super(props);
		// this.onExporting = this.onExporting.bind(this);
	}

	state = {
		invoiceItems: [],
	};

	render() {
		let columnAlign = "center",
			invoiceStatusKey = "Invoice_Status_Name_EN",
			// invoiceTypeKey = 'Invoice_Type_Name_EN',
			jsonDataSource = new CustomStore({
				key: "Invoice_Detail_Id",
				load: () => {
					return this.props.detailsList;
				},
			});

		if (this.props.i18n.language === "ar") {
			invoiceStatusKey = "Invoice_Status_Name_AR";
			// invoiceTypeKey = 'Invoice_Type_Name_AR'
		}

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id={`dataGrid_${this.props.key}`}
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					// onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<Column
						dataField="Invoice_Detail_No"
						caption={this.props.t("#")}
						alignment={columnAlign}
						width={140}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Invoice_Detail_Type_Name_AR"
								: "Invoice_Detail_Type_Name_EN"
						}
						caption={this.props.t("invoiceDetailItem")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "Invoice_Type_Category_Name_AR"
								: "Invoice_Type_Category_Name_EN"
						}
						caption={this.props.t("type")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Invoice_Detail_Value"
						caption={this.props.t("value")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Invoice_Detail_Qty"
						caption={this.props.t("qty")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Invoice_Detail_Total_Value"}
						caption={this.props.t("total")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Invoice_Detail_Discount"}
						caption={this.props.t("discount")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Invoice_Detail_Tax_Value"}
						caption={this.props.t("tax")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Invoice_Detail_Grand_Total_Value"}
						caption={this.props.t("grandTotal")}
						alignment={columnAlign}
					></Column>
				</DataGrid>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	setLoading,
	triggerNotification,
	triggerDialogForm,
})(translationHook(InvoiceDetailsList));
