import apiConfig from "../../apiConfig";

export const getLegalInvestorsCountList = async (at, License_Legal_Form_Id) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/LICENSES/LEGAL/FILES/Get_Legal_Investors_Count_List",
			{
				License_Legal_Form_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response;
};
