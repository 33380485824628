import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import AccountInfo from "./AccountInfo";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../actions";
import style from "./account.module.scss";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import moment from "moment";
import CustomerViolations from "./CustomerViolations";
import CustomerInternalViolations from "./CustomerInternalViolations";

class AccountForm extends Component {
  state = {
    key: 0,
    next: 1,
    prev: -1,
    files: [],
    initValues: {},
    loading: true,
    hideButton:
      this.props.showAccountProcessButton === undefined
        ? true
        : this.props.showAccountProcessButton,
  };

  hexToBase64 = (str) => {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, "")
          .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
          .replace(/ +$/, "")
          .split(" ")
      )
    );
  };
  handleNext = () => {
    this.setState({
      key: this.state.next,
      next: this.state.next + 1,
      prev: this.state.prev + 1,
    });
  };

  handlePrev = () => {
    this.setState({
      key: this.state.prev,
      next: this.state.next - 1,
      prev: this.state.prev - 1,
    });
  };
  renderChevs = () => {
    let rtl = this.props.i18n.language === "ar" ? true : false,
      iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
      iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

    return (
      <>
        <IconButton
          aria-label="prev"
          color="primary"
          onClick={this.handlePrev}
          disabled={this.state.key > 0 ? false : true}
        >
          {iconPrev}
        </IconButton>
        <IconButton
          aria-label="next"
          color="primary"
          onClick={this.handleNext}
          disabled={this.state.key < 3 && !this.state.second ? false : true}
        >
          {iconNext}
        </IconButton>
      </>
    );
  };
  componentDidMount() {
    this.getClientInfoFullData();
    const { initValues } = this.props,
      {
        Client_EID_File_Code,
        License_File_Code,
        Government_Approval_File_Code,
        Business_Card_File_Code,
        Client_EID,
      } = initValues;

    const filesArr = [];

    filesArr.push(
      Client_EID_File_Code,
      License_File_Code,
      Government_Approval_File_Code,
      Business_Card_File_Code
    );

    filesArr.forEach(async (item) => {
      if (item != null && item != "") {
        const auth = this.props.currentUser.Data.access_token,
          url =
            "API/ADMINISTRATION/CLIENTPROFILE/GET_CLIENT_PROFILE_ATTACHMENT",
          data = {
            File_Code: item,
          };
        const response = await apiConfig
          .post(url, data, {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          })
          .then((res) => res)
          .catch((error) => {
            return error.response;
          });
        this.setState({
          files: [...this.state.files, { Files_Data: [response.data.Data] }],
        });
      }
    });
  }

  getClientInfoFullData = async () => {
    const auth = this.props.currentUser.Data.access_token,
      response = await apiConfig
        .post(
          "/API/ADMINISTRATION/CLIENTPROFILE/GET_CLIENT_PROFILE_FULL_INFO",
          {
            Client_Profile_id: this.props.accountId,
          },
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            initValues: res.data.Data,
            loading: false,
          });
          return res;
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          return error.response;
        });
    return response;
  };

  downloadRequestAttachment = async ({ at, File_Code }) => {
    const response = await apiConfig
      .post(
        "/API//ADMINISTRATION/REQUESTS/MANAGEMENT/DOWNLOAD_REQUEST_ATTACHMENT",
        {
          File_Code,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
          responseType: "arraybuffer",
        }
      )
      .catch((error) => {
        return error.response;
      });

    return response;
  };
  onSubmit = async (values, action) => {
    const auth = this.props.currentUser.Data.access_token,
      editUrl = "/API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_MODIFY",
      deletetUrl = "/API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_DELETE",
      acceptUrl = "/API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_VERIFY",
      url =
        action === "delete"
          ? deletetUrl
          : action === "Requestedit"
          ? editUrl
          : action === "accept"
          ? acceptUrl
          : false,
      data = {
        client_Profile_Id: this.props.accountId,
        Profile_Notes: values.Profile_Notes,
      };
    this.props.setLoading(true, "dark");
    const response = await apiConfig
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    this.props.setLoading(false);
    this.props.triggerNotification(true, response.data);
    this.props.triggerDialogForm(false);
  };

  render() {
    let { initValues } = this.props;

    const validate = (values) => {
      const errors = {};

      if (!values.Profile_Notes) {
        errors.Profile_Notes = `${this.props.t("error.field_required")}`;
      }
      return errors;
    };
    let mystyle;

    if (this.props.i18n.language === "ar") {
      mystyle = "ServiceTabsAr";
    } else {
      mystyle = "ServiceTabsEn";
    }
    return (
      <>
        {!this.state.loading ? (
          <>
            <Form
              onSubmit={this.onSubmit}
              initialValues={this.state.initValues}
              validate={validate}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className={`row ${
                    !this.props.editMood && !this.props.viewMood ? "d-none" : ""
                  } mt-4 mx-3 departmentFormClass`}
                  noValidate
                >
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={0}
                    activeKey={this.state.key}
                    onSelect={(k) =>
                      this.setState({
                        key: parseInt(k),
                        next: parseInt(k) + 1,
                        prev: parseInt(k) - 1,
                      })
                    }
                  >
                    <Row>

                      <Col
                        sm={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>{this.renderChevs()}</div>
                        <Nav variant="pills" className={style.stepsNav}>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 0 ? style.active : ""
                              }`}
                              eventKey="0"
                              disabled={this.state.first}
                            >
                              {this.props.t("customerInformation")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 1 ? style.active : ""
                              }`}
                              eventKey="1"
                              disabled={this.state.second}
                            >
                              {this.props.t("communicationInfo")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 2 ? style.active : ""
                              }`}
                              eventKey="2"
                              disabled={this.state.third}
                            >
                              {this.props.t("AccountAttachments")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 3 ? style.active : ""
                              }`}
                              eventKey="3"
                              disabled={this.state.forth}
                            >
                              {this.props.t("customerViolations")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 4 ? style.active : ""
                              }`}
                              eventKey="4"
                              disabled={this.state.fifth}
                            >
                              {this.props.t("customerInternalViolations")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 5 ? style.active : ""
                              }`}
                              eventKey="5"
                              disabled={this.state.fifth}
                            >
                              {this.props.t("bankAccountInfo")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className={mystyle}>
                            <Nav.Link
                              className={`${style.navLink} ${
                                this.state.key === 6 ? style.active : ""
                              }`}
                              eventKey="6"
                              disabled={this.state.fifth}
                            >
                              {this.props.t("collectionAccountInfo")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>

                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey={0}>
                            <Row>
                              {initValues.clientProfileId != "" ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled
                                    label={this.props.t("clientProfileId")}
                                    className="read-only mb-3"
                                    name="Client_profile.Client_Profile_Id"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {initValues.Dealing_Type_Name_AR != null ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled
                                    label={this.props.t("dealingTypeAR")}
                                    className="read-only mb-3"
                                    name="Client_profile.Dealing_Type_Name_AR"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {initValues.Dealing_Type_Name_EN != null ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("dealingTypeEN")}
                                    className="read-only mb-3"
                                    name="Client_profile.Dealing_Type_Name_EN"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {initValues.Client_Name_AR != null ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled
                                    label={this.props.t("AccountNameAR")}
                                    className="read-only mb-3"
                                    name="Client_Name_AR"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {initValues.Client_Name_EN != null ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled
                                    label={this.props.t("AccountNameEN")}
                                    className="read-only mb-3"
                                    name="Client_Name_EN"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {initValues.Client_EID != null ? (
                                <Col xs={12} sm={6} md={3}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("id")}
                                    className="read-only mb-3"
                                    name="Client_EID"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {/* <Col xs={12} md={6}>
															<TextField
																disabled={this.props.viewMood}
																label={this.props.t('accountStatus')}
																className='read-only mb-3'
																name="Client_profile.Profile_Active_Status_Id"
																value={`
																${this.state.initValues?.Client_profile?.Profile_Active_Status_Id === 0 ? 
																this.props.t('notActive')
																:
																this.props.t('active')
																}`}
															/>
														</Col> */}
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("accountStatus")}
                                  className="read-only mb-3"
                                  name={`${
                                    this.props.i18n.language === "ar"
                                      ? "Client_profile.Profile_Active_Status_Name_AR"
                                      : "Client_profile.Active_Status_Name_EN"
                                  }`}
                                />
                              </Col>

                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("requestStatus")}
                                  className="read-only mb-3"
                                  name={`${
                                    this.props.i18n.language === "ar"
                                      ? "Client_profile.Verification_Status_Name_AR"
                                      : "Client_profile.Verification_Status_Name_EN"
                                  }`}
                                />
                              </Col>

                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("licenseNo")}
                                  className="read-only mb-3"
                                  name='Client_profile.License_No'
                                />
                              </Col>

                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("licenseIssueDate2")}
                                  className="read-only mb-3"
                                  name='Client_profile.License_Issue_Date'
                                  value={moment(
                                    this.state.initValues
                                      ?.Client_profile
                                      ?.License_Issue_Date
                                  ).format("YYYY-MM-DD")}
                                />
                              </Col>

                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("licenseExpiredDate2")}
                                  className="read-only mb-3"
                                  name='Client_profile.License_Expired_Date'
                                  value={moment(
                                    this.state.initValues
                                      ?.Client_profile
                                      ?.License_Expired_Date
                                  ).format("YYYY-MM-DD")}
                                />
                              </Col>
                              {this.state.initValues?.Client_profile.Profile_Notes && (
                                <Col md={12}>
                                  <Row>
                                    
                                    <Col xs={12} md={6}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        multiline
                                        rows={4}
                                        label={this.props.t("customerAccountNotes")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_profile.Profile_Notes"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              ) 
                              // : (
                              //   <Col md={12}>
                              //     <Row>
                              //       <Col md={12}>
                              //         <h5 className="my-4 text-start">
                              //           {this.props.t(
                              //             "constractourConsultantDetails"
                              //           )}
                              //         </h5>
                              //         <p
                              //           className="m-auto text-center"
                              //           style={{
                              //             fontSize: "18px",
                              //           }}
                              //         >
                              //           {this.props.t("noData")}
                              //         </p>
                              //       </Col>
                              //     </Row>
                              //   </Col>
                              // )
                              }
                              {this.state.initValues?.Client_License_Info &&
                              typeof this.state.initValues
                                ?.Client_License_Info === "object" ? (
                                <Col md={12}>
                                  <Row>
                                    <Col md={12}>
                                      <h5 className="my-4 text-start">
                                        {this.props.t(
                                          "constractourConsultantDetails"
                                        )}
                                      </h5>
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseNo")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_NO"
                                      />
                                    </Col>

                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseNameAR")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Name_AR"
                                      />
                                    </Col>

                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseNameEN")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Name_EN"
                                      />
                                    </Col>

                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseType")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name={`${
                                          this.props.i18n.language === "ar"
                                            ? "Client_License_Info.License_Type_Name_AR"
                                            : "Client_License_Info.License_Type_Name_EN"
                                        }`}
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseCategory")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name={`${
                                          this.props.i18n.language === "ar"
                                            ? "Client_License_Info.License_Class_Name_AR"
                                            : "Client_License_Info.License_Class_Name_EN"
                                        }`}
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t(
                                          "licenseExpiredDate"
                                        )}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Expire_Date"
                                        value={moment(
                                          this.state.initValues
                                            ?.Client_License_Info
                                            ?.License_Expire_Date
                                        ).format("YYYY-MM-DD")}
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("departmentName")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name={`${
                                          this.props.i18n.language === "ar"
                                            ? "Client_License_Info.Department_Name_AR"
                                            : "Client_License_Info.Department_Name_EN"
                                        }`}
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseOwner")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Owner"
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("licenseMail")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Mail"
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("landLinePhone")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Phone"
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("phone")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Mobile"
                                      />
                                    </Col>
                                    <Col xs={12} md={4}>
                                      <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t("fax")}
                                        className={`${
                                          this.props.viewMood
                                            ? "read-only"
                                            : "mb-2"
                                        }`}
                                        name="Client_License_Info.License_Fax"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <Col md={12}>
                                  <Row>
                                    <Col md={12}>
                                      <h5 className="my-4 text-start">
                                        {this.props.t(
                                          "constractourConsultantDetails"
                                        )}
                                      </h5>
                                      <p
                                        className="m-auto text-center"
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {this.props.t("noData")}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey={1}>
                            <Row>
                              {this.state.initValues?.Client_profile
                                ?.Login_Name != null ? (
                                <Col xs={12} md={12}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("loginName")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_profile.Login_Name"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {this.state.initValues?.Client_profile
                                ?.Client_Phone != null ? (
                                <Col xs={12} md={4}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("clientPhone")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_profile.Client_Phone"
                                  />
                                </Col>
                              ) : (
                                false
                              )}
                              {this.state.initValues?.Client_profile
                                ?.Official_Mobile != null ? (
                                <Col xs={12} md={4}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("OfficialMobile")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_profile.Official_Mobile"
                                  />
                                </Col>
                              ) : (
                                false
                              )}

                              {this.state.initValues?.Client_profile
                                ?.Official_Land_Phone != null ? (
                                <Col xs={12} md={4}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("officialLandPhone")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_profile.Official_Land_Phone"
                                  />
                                </Col>
                              ) : (
                                false
                              )}
                              {initValues.Client_Mail != null ? (
                                <Col xs={12} md={6}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("clientMail")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_Mail"
                                  />
                                </Col>
                              ) : (
                                false
                              )}
                              {this.state.initValues?.Client_profile
                                ?.Official_Mail != null ? (
                                <Col xs={12} md={6}>
                                  <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("OfficialMail")}
                                    className={`${
                                      this.props.viewMood ? "read-only" : "mb-2"
                                    }`}
                                    name="Client_profile.Official_Mail"
                                  />
                                </Col>
                              ) : (
                                false
                              )}
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey={2}>
                            <h5 className="my-4 text-start">
                              {this.props.t("requestAttachments")}
                            </h5>
                            <FilesUpload
                              downloadCB={this.downloadRequestAttachment}
                              view={true}
                              attachments={this.state.files}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey={3}>
                            <h5 className="my-4 text-start">
                              {this.props.t("customerViolations")}
                            </h5>
                            {this.state.initValues.Client_Violations && (
                              <CustomerViolations
                                violations={
                                  this.state.initValues.Client_Violations
                                    .Violations_List
                                }
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={4}>
                            <h5 className="my-4 text-start">
                              {this.props.t("customerInternalViolations")}
                            </h5>
                            {this.state.initValues
                              .Client_Internal_Violations && (
                              <CustomerInternalViolations
                                internalViolations={
                                  this.state.initValues
                                    .Client_Internal_Violations
                                }
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={5}>
                            <Row>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("bankNameAR")}
                                  className="read-only mb-3"
                                  name="Client_profile.Bank_Name_AR"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("bankNameEN")}
                                  className="read-only mb-3"
                                  name="Client_profile.Bank_Name_EN"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("iban")}
                                  className="read-only mb-3"
                                  name="Client_profile.IBAN"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("accountNameEN")}
                                  className="read-only mb-3"
                                  name="Client_profile.Account_Name_EN"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("accountMobileNo")}
                                  className="read-only mb-3"
                                  name="Client_profile.Account_Mobile_No"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("accountAddress")}
                                  className="read-only mb-3"
                                  name="Client_profile.Account_Address"
                                />
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey={6}>
                            <Row>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("collectionAccountNumber")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Account_No"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("id")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Identity_No"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("nameAr")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Name_AR"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("nameEn")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Name_EN"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("phone")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Mobile"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("mail")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Email"
                                />
                              </Col>
                              <Col xs={12} sm={6} md={3}>
                                <TextField
                                  disabled={this.props.viewMood}
                                  label={this.props.t("birthdate")}
                                  className="read-only mb-3"
                                  name="Client_profile.Collection_Birthdate"
                                  value={moment(
                                    this.state.initValues
                                      ?.Client_profile
                                      ?.Collection_Birthdate
                                  ).format("YYYY-MM-DD")}
                                />
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>

                    </Row>
                  </Tab.Container>
                </form>
              )}
            />
            {this.state.hideButton && <AccountInfo request={initValues} />}
          </>
        ) : (
          <h3 
          className="h-100 d-flex justify-content-center align-items-center text-success"
          >
            {this.props.t("loadingClientData")}
          </h3>
        )}
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(AccountForm));
