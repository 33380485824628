import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { DataGrid, Column, Selection } from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { translationHook } from "../translationHook";
import { getServiceDetailsFormsList } from "../../apis/services/getServiceDetailsFormsList";
import { getServiceDetailFormInfo } from "../../apis/services/getServiceDetailFormInfo";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
} from "../../actions";

import ServiceDetailsFormInputsList from "./ServiceDetailsFormInputsList";
import ServiceDetailsFormInputsGrid from "./ServiceDetailsFormInputsGrid";

class ServiceDetailsFormInputs extends Component {
	constructor(props) {
		super(props);
	}
	state = {
		reload: false,
		selectedServiceDetailFormId: null,
		redrawGrid: false,
	};

	setOrderUp = async (serviceDetailFormId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_ORDER_UP",
			{
				Service_Detail_Form_Id: serviceDetailFormId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		// this.props.reload();
	};

	setOrderDown = async (serviceDetailFormId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_ORDER_DOWN",
			{
				Service_Detail_Form_Id: serviceDetailFormId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		// this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex align-items-center order-btns compact">
				<IconButton
					onClick={() => this.setOrderUp(data.Service_Detail_Form_Id)}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						this.setOrderDown(data.Service_Detail_Form_Id)
					}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			ServiceDetailTitleKey = "Service_Detail_Form_Title_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			ServiceDetailTitleKey = "Service_Detail_Form_Title_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Service_Detail_Form_Id",
			load: async () => {
				return getServiceDetailsFormsList(at, this.props.serviceid);
			},
		});

		return (
			<Row>
				<Col xs={12} md={5} className="mb-3">
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
						onSelectionChanged={(e) => {
							this.setState({
								selectedServiceDetailFormId:
									e.selectedRowKeys[0],
							});
						}}
					>
						<Selection mode="single" />

						<Column
							cssClass="outlinedColumn"
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
							alignment="center"
							width={"100px"}
							cellRender={this.renderOrderButtons}
						></Column>

						<Column
							dataField="Service_Detail_Form_No"
							sortOrder="asc"
							caption={this.props.t("stepNo")}
							alignment={"center"}
						></Column>

						<Column
							dataField={ServiceDetailTitleKey}
							caption={this.props.t("stepName")}
							alignment={columnAlign}
						></Column>
					</DataGrid>
				</Col>

				<Col xs={12} md={7}>
					<Row>
						<Col xs={12}>
							<ServiceDetailsFormInputsGrid
								serviceDetailFormId={
									this.state.selectedServiceDetailFormId
								}
								redrawGrid={this.state.redrawGrid}
							/>
						</Col>
						<Col xs={12}>
							<ServiceDetailsFormInputsList
								serviceDetailFormId={
									this.state.selectedServiceDetailFormId
								}
								redrawGridCB={() => {
									this.setState({
										redrawGrid: !this.state.redrawGrid,
									});
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
})(translationHook(ServiceDetailsFormInputs));
