import apiConfig from "../../apiConfig";

export const deleteLicenseRegisteredName = async (
	at,
	licenseRegisteredNameId
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Del_License_Registered_Name",
		{
			License_Registered_Name_Id: licenseRegisteredNameId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
