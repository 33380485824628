import React, { Component } from "react";
import { connect } from "react-redux";
import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
	triggerDialogForm,
} from "./../../../actions";
import { translationHook } from "./../../translationHook";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "./../../UI/Buttons";
import { Link } from "@reach/router";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";
import { getLicendeTradeNameList } from "./../../../apis/licenses/licenseTradeNames/getLicendeTradeNameList";
import { deleteLicenseTradeName } from "./../../../apis/licenses/licenseTradeNames/deleteLicenseTradeName";
import { setLicenseTradeNameStatus } from "./../../../apis/licenses/licenseTradeNames/setLicenseTradeNameStatus";
import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import LicensesTrademarkName from "./LicensesTrademarkName";

class LicensesTrademarkNamesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	customizeCellCheckbox = ({ data }) => {
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id}
						name={`forbiddenNameStatus_${data.License_Trademark_Name_Id}`}
						onChange={async () => {
							let at = this.props.currentUser.Data.access_token,
								licenseTrademarkNameId =
									data.License_Trademark_Name_Id,
								status = data.Active_Status_Id ? 0 : 1;

							this.props.setLoading(true, "dark");

							const response = await setLicenseTradeNameStatus(
								at,
								licenseTrademarkNameId,
								status
							);

							this.props.triggerNotification(true, response);

							this.props.setLoading(false);
						}}
					/>
				}
			/>
		);
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			licenseTrademarkNameId = data.License_Trademark_Name_Id,
			licenseTrademarkName =
				this.props.i18n.language === "ar"
					? data.License_Trademark_Name_AR
					: data.License_Trademark_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${licenseTrademarkName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteLicenseTradeName,
				params: [at, licenseTrademarkNameId],
				text: this.props.t("deleteName"),
			},
		});
	};

	renderForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;

		return (
			<LicensesTrademarkName
				nameId={params[0]}
				initValues={params[1]}
				editMood={editMood}
				viewMood={viewMood}
			/>
		);
	};

	handleEdit = (data, viewMood) => {
		let initValues = {
				Active_Status_Id: data.Active_Status_Id,
				License_Trademark_Name_AR: data.License_Trademark_Name_AR,
				License_Trademark_Name_EN: data.License_Trademark_Name_EN,
			},
			nameId = data.License_Trademark_Name_Id,
			licenseName =
				this.props.i18n.language === "ar"
					? data.License_Trademark_Name_AR
					: data.License_Trademark_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("view")
					: this.props.t("edit")
			} ${licenseName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderForm,
			params: [nameId, initValues, viewMood],
		});
	};

	render() {
		let at = this.props.currentUser.Data.access_token;

		const jsonDataSource = new CustomStore({
			key: "License_Trademark_Name_Id",
			load: () => {
				return getLicendeTradeNameList(at);
			},
		});

		return (
			<>
				<LicensesTrademarkName />

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField="License_Trademark_Name_AR"
						caption={this.props.t("nameAr")}
						alignment={"center"}
					></Column>

					<Column
						dataField="License_Trademark_Name_EN"
						caption={this.props.t("nameEn")}
						alignment={"center"}
					></Column>

					<Column
						dataField="Active_Status_Id"
						caption={this.props.t("serviceStatus")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeCellCheckbox}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										onClick={() => {
											this.handleEdit(
												record.data,
												"edit"
											);
										}}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										onClick={() => {
											this.handleEdit(
												record.data,
												"view"
											);
										}}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("licensesTrademarkNamesList");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
	triggerDialogForm,
})(translationHook(LicensesTrademarkNamesList));
