import apiConfig from "../../apiConfig";

export const deleteLicenseTradeName = async (at, licenseTrademarkNameId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Del_License_Trademark_Name",
		{
			License_Trademark_Name_Id: licenseTrademarkNameId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
