import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";

import RegistrationFinancialItems from "./RegistrationFinancialItems";
import RegistrationFinancialLeaseItems from "./RegistrationFinancialLeaseItems";
import RegistrationFinancialInvestorItems from "./RegistrationFinancialInvestorItems";
import RegistrationFinancialActivtiesItems from "./RegistrationFinancialActivtiesItems";
import { getLicensesOffersRegistrationInfo } from "../../apis/licenses/licensesOffers/getLicensesOffersRegistrationInfo";

class RegistrationInfoEditForm extends Component {
	state = {
		showForm: false,
		planData: null,
	};

	validate = (values) => {
		const errors = {};

		if (!values.License_Offer_Registration_Name_AR) {
			errors.License_Offer_Registration_Name_AR = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (!values.License_Offer_Registration_Name_EN) {
			errors.License_Offer_Registration_Name_EN = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (!values.License_Offer_Registration_Years) {
			errors.License_Offer_Registration_Years = `${this.props.t(
				"error.field_required"
			)}`;
		}

		return errors;
	};

	handleItemAddCB = async () => {
		let at = this.props.currentUser.Data.access_token;

		const response = await getLicensesOffersRegistrationInfo(
			at,
			this.props.planData.Offer_Registration_Info
				.License_Offer_Registration_Id
		);

		if (response.data.Status) {
			this.setState({ planData: response.data.Data });
		}
	};

	onPlanSubmit = async (values) => {
		let at = this.props.currentUser.Data.access_token;

		if (values.Active_Status_Id) {
			values["Active_Status_Id"] = 1;
		} else {
			values["Active_Status_Id"] = 0;
		}

		let bodyData = {
			License_Offer_Id:
				this.props.planData.Offer_Registration_Info.License_Offer_Id,
			License_Offer_Registration_Id:
				this.props.planData.Offer_Registration_Info
					.License_Offer_Registration_Id,
			...values,
		};
		this.props.setLoading(true, "dark");
		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/OFFERS/Upd_License_Offer_Registration",
				bodyData,
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});
		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);
	};

	componentDidMount() {
		this.setState({ planData: this.props.planData });
	}

	render() {
		if (!this.state.planData) {
			return null;
		}
		let {
			License_Offer_Registration_Name_EN,
			License_Offer_Registration_Name_AR,
			License_Offer_Registration_Years,
			Active_Status_Id,
		} = this.state.planData?.Offer_Registration_Info;

		let statsStyles = {
			container: { textAlign: "start", marginBottom: "16px" },
			label: { color: "#00b1eb", fontSize: "0.8rem" },
		};

		return (
			<>
				<Form
					// subscription={{ submitting: true }}
					onSubmit={this.onPlanSubmit}
					initialValues={{
						License_Offer_Registration_Name_EN,
						License_Offer_Registration_Name_AR,
						License_Offer_Registration_Years,
						Active_Status_Id: Active_Status_Id ? 1 : 0,
					}}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						return (
							<>
								<form
									id="registrationOfferEditForm"
									className={`row ${
										!this.props.editMood &&
										!this.props.viewMood
											? "d-none"
											: ""
									}`}
									onSubmit={handleSubmit}
									noValidate
								>
									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registrationPlanNameAR"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Name_AR"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>
									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registrationPlanNameEN"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Name_EN"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>

									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registerationYears"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Years"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>

									<Col
										xs={12}
										md={6}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
									>
										<Switches
											disabled={this.props.viewMood}
											color="secondary"
											name="Active_Status_Id"
											data={{
												label: `${this.props.t(
													"serviceStatus"
												)}`,
												value: `${this.props.t(
													"serviceStatus"
												)}`,
											}}
										/>
									</Col>

									<Col className="mt-4" xs={12}>
										<Row className="align-items-baseline">
											<Col className="mt-2 submit-btn-wrap">
												{!this.props.viewMood && (
													<ButtonPrimary
														color="primary"
														variant="contained"
														disabled={
															submitting ||
															this.props.viewMood
														}
														type="submit"
													>
														{this.props.t("save")}
													</ButtonPrimary>
												)}
											</Col>
										</Row>
									</Col>
								</form>
							</>
						);
					}}
				/>

				{this.state.planData && (
					<Row className="mt-5">
						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t("registerationNetValue")}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Registration_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t("registerationLeaseNetValue")}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Registration_Lease_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t(
									"registerationAddInvestorNetValue"
								)}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Registration_Additional_Investor_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t(
									"registerationAddActivityNetValue"
								)}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Registration_Additional_Activity_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t("registerationSpecialNetValue")}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Special_Registration_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t(
									"registerationSpecialLeaseNetValue"
								)}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Special_Registration_Lease_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t(
									"registerationSpecialAddInvestorNetValue"
								)}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Special_Registration_Additional_Investor_Net_Value
								}
							</p>
						</Col>

						<Col md={3} style={statsStyles.container}>
							<label style={statsStyles.label}>
								{this.props.t(
									"registerationSpecialAddActivityNetValue"
								)}
							</label>
							<p>
								{
									this.state.planData.Offer_Registration_Info
										.License_Offer_Special_Registration_Additional_Activity_Net_Value
								}
							</p>
						</Col>
					</Row>
				)}

				{this.state.planData?.Registration_Items_List && (
					<RegistrationFinancialItems
						info={this.state.planData?.Offer_Registration_Info}
						items={this.state.planData.Registration_Items_List}
						reloadCB={this.handleItemAddCB}
					/>
				)}

				{this.state.planData?.Rental_Items_List && (
					<RegistrationFinancialLeaseItems
						info={this.state.planData?.Offer_Registration_Info}
						items={this.state.planData.Rental_Items_List}
						reloadCB={this.handleItemAddCB}
					/>
				)}

				{this.state.planData?.Additinal_Investor_Items_List && (
					<RegistrationFinancialInvestorItems
						info={this.state.planData?.Offer_Registration_Info}
						items={
							this.state.planData.Additinal_Investor_Items_List
						}
						reloadCB={this.handleItemAddCB}
					/>
				)}

				{this.state.planData?.Additinal_Activity_Items_List && (
					<RegistrationFinancialActivtiesItems
						info={this.state.planData?.Offer_Registration_Info}
						items={
							this.state.planData.Additinal_Activity_Items_List
						}
						reloadCB={this.handleItemAddCB}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(RegistrationInfoEditForm));
