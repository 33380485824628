import apiConfig from "../../apiConfig";

export const saveLicenseLegalFile = async (
	at,
	License_Legal_Form_Id,
	Investors_Count,
	License_Attachment_Type_Id,
	file
) => {
	const bodyFormData = new FormData(),
		url = "/API/ADMINISTRATION/LICENSES/LEGAL/FILES/Upload_Legal_File",
		bodyData = {
			License_Legal_Form_Id,
			Investors_Count,
			License_Attachment_Type_Id,
		};

	bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

	bodyFormData.append("Legal_File", file);

	const response = await apiConfig.post(url, bodyFormData, {
		headers: {
			Authorization: `Bearer ${at}`,
		},
	});

	return response;
};
