import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "../return/ReturnStep.module.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import FormControl from "@material-ui/core/FormControl";

class ApproveLicenseName extends Component {
	state = {
		licenseNamesList: [],
		originalLicenseNamesList: [],
		selectedSug: null,
		initValues: {},
		userId: 0,
	};

	getLicenseNamesList = async () => {
		let stepsArr = [],
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Request_Task_License_Names_List",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			let initValues = this.formatInitValue(response.data.Data);

			this.setState({
				licenseNamesList: response.data.Data,
				initValues: initValues,
			});
		}
	};

	onReturnRequestStepSubmit = async (values, form) => {};

	componentDidMount() {
		this.getLicenseNamesList();
	}

	handleSelectSug = (e, reset) => {
		reset();
		this.setState({
			selectedSug: e.target.value,
		});
	};

	// handleEscalateToUserSelect = (e) => {
	// 	this.setState({ userId: e.target.value });
	// 	this.props.setValuesCB((prev) => {
	// 		return { ...prev, Escalate_Step_Receiver_User_Id: e.target.value };
	// 	});
	// };

	formatInitValue = (data) => {
		let initValues = {};

		data.forEach((item, i) => {
			initValues[`sug_EN_${i}`] = item.Lic_Name_EN;
			initValues[`sug_AR_${i}`] = item.Lic_Name_AR;
		});

		return initValues;
	};

	handleTranslate = async (i, txt, lng) => {
		let itemsList = this.state.licenseNamesList,
			at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/SYSTEM/GENERAL/Text_Translation",
			{
				Input_Text: txt,
				Input_Lang: lng,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			if (lng === 1) {
				itemsList[i]["Lic_Name_EN"] = response.data.Data;
				itemsList[i]["Lic_Name_AR"] = txt;
			}

			if (lng === 2) {
				itemsList[i]["Lic_Name_AR"] = response.data.Data;
				itemsList[i]["Lic_Name_EN"] = txt;
			}

			let initValues = this.formatInitValue(itemsList);

			this.setState({
				licenseNamesList: itemsList,
				initValues: initValues,
				selectedSug: JSON.stringify(itemsList[i]),
			});
		}
	};

	componentDidUpdate(prevProp, prevState) {
		if (prevState.selectedSug !== this.state.selectedSug) {
			this.props.setValuesCB(JSON.parse(this.state.selectedSug));
		}
	}

	render() {
		const validate = (values) => {
			const errors = {};

			// if (values.receiverType === "3" && !values.userId) {
			// 	errors.userId = `${this.props.t("error.field_required")}`;
			// }

			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.onReturnRequestStepSubmit}
					initialValues={this.state.initValues}
					validate={validate}
					validateOnBlur={true}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="escalateStepForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							{(() => {
								let activeField = form.getState().active;

								if (
									activeField &&
									form.getState().modified[activeField]
								) {
									let fieldVal =
											form.getState().values[activeField],
										fieldLng = activeField.split("_")[1],
										sugIndex = activeField.split("_")[2],
										selectedSug = JSON.parse(
											this.state.selectedSug
										);

									if (fieldLng === "AR") {
										selectedSug["Lic_Name_AR"] = fieldVal;
										selectedSug["Lic_Name_EN"] =
											form.getState().values[
												`sug_EN_${sugIndex}`
											];
									}

									if (fieldLng === "EN") {
										selectedSug["Lic_Name_EN"] = fieldVal;
										selectedSug["Lic_Name_AR"] =
											form.getState().values[
												`sug_AR_${sugIndex}`
											];
									}

									this.props.setValuesCB((prev) => {
										return { ...prev, ...selectedSug };
									});
								}
							})()}

							<Col xs={12} md={8}>
								<FormControl className="mt-3">
									<RadioGroup
										row
										name="receiverType"
										value={this.state.selectedSug}
										onChange={(e) => {
											this.handleSelectSug(e, form.reset);
										}}
										className="d-flex flex-column"
									>
										{this.state.licenseNamesList.map(
											(item, i) => {
												return (
													<Col
														md={9}
														className="d-flex align-items-center"
													>
														<FormControlLabel
															value={JSON.stringify(
																item
															)}
															control={<Radio />}
															className="mb-3"
														/>

														<div className="flex-grow-1">
															<TextField
																disabled={
																	this.state
																		.selectedSug !==
																	JSON.stringify(
																		item
																	)
																}
																name={`sug_EN_${i}`}
																label={this.props.t(
																	`sugEN_${i}`
																)}
															/>

															{this.state
																.selectedSug ==
																JSON.stringify(
																	item
																) && (
																<a
																	title="translate"
																	href="#"
																	style={{
																		display:
																			"block",
																		marginTop:
																			"-25px",
																		marginBottom:
																			"10px",
																		fontSize:
																			"12px",
																		color: "#333",
																		padding:
																			"0px 14px",
																	}}
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		let inputField = `sug_EN_${i}`;
																		this.handleTranslate(
																			i,
																			values[
																				inputField
																			],
																			2
																		);
																	}}
																>
																	{this.props.t(
																		"translateToAr"
																	)}
																</a>
															)}
														</div>

														<div className="flex-grow-1 mx-3">
															<TextField
																disabled={
																	this.state
																		.selectedSug !==
																	JSON.stringify(
																		item
																	)
																}
																name={`sug_AR_${i}`}
																label={this.props.t(
																	`sugAR_${i}`
																)}
															/>
															{this.state
																.selectedSug ==
																JSON.stringify(
																	item
																) && (
																<a
																	title="translate"
																	href="#"
																	style={{
																		display:
																			"block",
																		marginTop:
																			"-25px",
																		marginBottom:
																			"10px",
																		fontSize:
																			"12px",
																		color: "#333",
																		padding:
																			"0px 14px",
																	}}
																	onClick={(
																		e
																	) => {
																		e.preventDefault();
																		let inputField = `sug_AR_${i}`;
																		this.handleTranslate(
																			i,
																			values[
																				inputField
																			],
																			1
																		);
																	}}
																>
																	{this.props.t(
																		"translateToEn"
																	)}
																</a>
															)}
														</div>
													</Col>
												);
											}
										)}
									</RadioGroup>
								</FormControl>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ApproveLicenseName));
