import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
	ButtonDanger,
	ButtonSecondary,
	ButtonPrimary,
	ButtonWarning,
} from "../../UI/Buttons";
import apiConfig from "../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Lookup,
	Editing,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { translationHook } from "../../translationHook";
import { getDepartmentServiceVersionStepList } from "../../../apis/services/departments/getDepartmentServiceVersionStepList";
import { deleteDepartmentVersionStep } from "../../../apis/services/departments/deleteDepartmentVersionStep";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
} from "../../../actions";
import ServiceVersionUnselectedStep from "./ServiceVersionUnselectedStep";
import ServiceVersionStepForm from "./ServiceVersionStepForm";

class ServiceVersionSteps extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		stepJobs: [],
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			serviceStepid = data.Department_Service_Step_Id,
			serviceStepName =
				this.props.i18n.language === "ar"
					? data.Department_Service_Step_Name_AR
					: data.Department_Service_Step_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${serviceStepName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteDepartmentVersionStep,
				params: [at, serviceStepid],
				text: this.props.t("deleteServiceDetail"),
			},
		});
	};

	renderUnselectedServiceStep = () => {
		return (
			<ServiceVersionUnselectedStep versionid={this.props.versionid} />
		);
	};

	renderServiceVersionStepForm = (...params) => {
		return (
			<ServiceVersionStepForm
				departmentStepId={params[0]}
				departmentServiceVersionId={params[1]}
				initValues={params[2]}
				editMood={this.props.editMood}
			/>
		);
	};

	handleAddStep = () => {
		this.props.triggerDialogForm(true, {
			title: "",
			renderForm: this.renderUnselectedServiceStep,
			params: [],
		});
	};

	handleEditStep = (data) => {
		let initValues = {
				// stepTime: data.Department_Service_Step_Time,
				// stepTimeType: data.Department_Service_Time_Type_Id,
				// serviceNotification: data.Department_Service_Step_Notification_Id,
				stepJob: data.Department_Job_Id,
				// notification: data.Department_Service_Step_Send_Notification,
				stepId: data.Department_Service_Step_Type_Id,
			},
			departmentStepId = data.Department_Service_Step_Id,
			departmentServiceVersionId = data.Department_Service_Version_Id,
			departmentStepName =
				this.props.i18n.language === "ar"
					? data.Department_Service_Step_Name_AR
					: data.Department_Service_Step_Name_EN,
			dialogTitle = `${
				this.props.viewMood
					? this.props.t("viewServiceDetailTitle")
					: this.props.t("editServiceDetailTitle")
			} ${departmentStepName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderServiceVersionStepForm,
			params: [departmentStepId, departmentServiceVersionId, initValues],
		});
	};

	setOrderUp = async (departmentServiceStepId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_DEPARTMENT_VERSION_STEP_ORDER_UP",
			{
				Department_Service_Step_Id: departmentServiceStepId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (departmentServiceStepId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_DEPARTMENT_VERSION_STEP_ORDER_DOWN",
			{
				Department_Service_Step_Id: departmentServiceStepId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() =>
						this.setOrderUp(data.Department_Service_Step_Id)
					}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						this.setOrderDown(data.Department_Service_Step_Id)
					}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	getDepartmentSectionJobs = async () => {
		let at = this.props.currentUser.Data.access_token,
			departmentsArr = [];
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SECTION_JOBS",
			{
				Department_Service_Version_Id: this.props.versionid,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Job_Name_AR
						: item.Department_Job_Name_EN;

				departmentsArr.push({ value: item.Department_Job_Id, label });
			});

			this.setState({ stepJobs: departmentsArr });
		}
	};

	onDepartmentVersionStepSubmit = async (
		Department_Service_Step_Id,
		fieldName, 
		value
	) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPD_DEPARTMENT_VERSION_STEP",
			bodyData = {
				Department_Service_Step_Id,
				Department_Job_Id: -1,
				Escalate_Level_1_Department_Job_Id: -1, 
				Escalate_Level_2_Department_Job_Id: -1,
				[fieldName]: value
			};

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);
	};

	componentDidMount() {
		this.getDepartmentSectionJobs();
	}

	render() {
		let columnAlign = "left",
			ServiceStepTitleKey = "Department_Service_Step_Name_EN",
			ServiceStepTypeKey = "Service_Step_Type_Name_EN",
			NotificationNameKey = "Notification_Name_EN",
			ServiceStepJobCatKey = "Department_Job_Type_Name_EN",
			departmentJobNameKey = "Department_Job_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			ServiceStepTitleKey = "Department_Service_Step_Name_AR";
			ServiceStepTypeKey = "Service_Step_Type_Name_AR";
			NotificationNameKey = "Notification_Name_AR";
			ServiceStepJobCatKey = "Department_Job_Type_Name_AR";
			departmentJobNameKey = "Department_Job_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Service_Step_Id",
			load: async () => {
				return getDepartmentServiceVersionStepList(
					at,
					this.props.versionid
				);
			},
			update: (key, values) => {
				const keyz = ['Department_Job_Id' , 'Escalate_Level_1_Department_Job_Id' , 'Escalate_Level_2_Department_Job_Id']
				const keyzfilter = keyz.filter((item , index) => item === Object.keys(values)[0])

				this.onDepartmentVersionStepSubmit(
					key,
					keyzfilter[0],
					values[keyzfilter[0]]
				);
			},
		});

		return (
			<Row>
				{!this.props.viewMood && (
					<Col xs={12}>
						<div className="d-flex justify-content-between align-items-center mb-4">
							<ButtonPrimary
								size="large"
								onClick={this.handleAddStep}
							>
								{this.props.t("addNewStep")}
							</ButtonPrimary>
						</div>
					</Col>
				)}

				<Col xs={12}>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
						onEditingStart={(row) => {
							if (
								row.data.Department_Service_Step_Type_Id !== 2
							) {
								row.cancel = true;
							} else {
								row.cancel = !row.data.Can_Edit_Step;
							}

							if(row.column.dataField === "Escalate_Level_1_Department_Job_Id" && row.data.Department_Service_Step_Escalate_Level_1 === 0) {
								row.cancel = true
							}
							if(row.column.dataField === "Escalate_Level_2_Department_Job_Id" && row.data.Department_Service_Step_Escalate_Level_2 === 0) {
								row.cancel = true
							}
						}}
					>
						<Editing
							mode="cell"
							allowUpdating={true}
							allowAdding={false}
							allowDeleting={false}
						/>

						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/>

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							cssClass="outlinedColumn"
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
							alignment="center"
							width={"70px"}
							cellRender={this.renderOrderButtons}
							allowEditing={false}
						></Column>

						<Column
							dataField="Department_Service_Step_No"
							sortOrder="asc"
							caption={this.props.t("stepNo")}
							alignment={"center"}
							allowEditing={false}
						></Column>

						<Column
							dataField={ServiceStepTitleKey}
							caption={this.props.t("stepName")}
							alignment={columnAlign}
							allowEditing={false}
						></Column>

						<Column
							dataField={ServiceStepTypeKey}
							caption={this.props.t("stepType")}
							alignment={columnAlign}
							allowEditing={false}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField={ServiceStepJobCatKey}
							caption={this.props.t("jobType")}
							alignment={"center"}
							allowEditing={false}
						></Column>

						<Column
							dataField={NotificationNameKey}
							caption={this.props.t("notificationType")}
							alignment={columnAlign}
							allowEditing={false}
						></Column>

						{/* <Column
							visible={({ row }) => {
								return row.data.Can_Edit_Step === 1
									? false
									: true;
							}}
							dataField={departmentJobNameKey}
							caption={this.props.t("departmentJobName")}
							alignment={columnAlign}
							allowEditing={false}
						></Column> */}

						<Column
							allowEditing={!this.props.viewMood}
							cssClass="outlinedColumn"
							dataField="Department_Job_Id"
							caption={this.props.t("departmentJobName")}
							alignment={"center"}
						>
							<Lookup
								dataSource={this.state.stepJobs}
								displayExpr="label"
								valueExpr="value"
							/>
						</Column>
						<Column
							allowEditing={!this.props.viewMood}
							cssClass="outlinedColumn"
							dataField="Escalate_Level_1_Department_Job_Id"
							caption={this.props.t("EscalateGroupName1")}
							alignment={"center"}
							visible={({ row }) => {
								return row.data.Department_Service_Step_Escalate_Level_1 === 1
									? true
									: false;
							}}
						>
							<Lookup
								dataSource={this.state.stepJobs}
								displayExpr="label"
								valueExpr="value"
							/>
						</Column>
						<Column
							allowEditing={({row}) => (!this.props.viewMood || row.data.Department_Service_Step_Escalate_Level_1 === 1)}
							cssClass="outlinedColumn"
							dataField="Escalate_Level_2_Department_Job_Id"
							caption={this.props.t("EscalateGroupName2")}
							alignment={"center"}
						>
							<Lookup
								dataSource={this.state.stepJobs}
								displayExpr="label"
								valueExpr="value"

							/>
						</Column>

						<Column
							dataField="Department_Service_Step_Send_Notification"
							caption={this.props.t("notification")}
							alignment={"center"}
							width={"120px"}
							allowFiltering={false}
							allowEditing={false}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							{!this.props.viewMood && [
								// <Button
								// 	visible={({ row }) => {
								// 		return row.data.Can_Edit_Step === 1
								// 			? true
								// 			: false;
								// 	}}
								// 	render={(record) => {
								// 		if (
								// 			record.data
								// 				.Department_Service_Step_Type_Id ===
								// 			2
								// 		) {
								// 			return (
								// 				<ButtonWarning
								// 					variant="circleIcon"
								// 					startIcon={<EditIcon />}
								// 					onClick={() =>
								// 						this.handleEditStep(
								// 							record.data
								// 						)
								// 					}
								// 				>
								// 					{/* {this.props.t('edit')} */}
								// 				</ButtonWarning>
								// 			);
								// 		} else {
								// 			return null;
								// 		}
								// 	}}
								// />,

								<Button
									visible={({ row }) => {
										return row.data.Can_Delete_Step === 1
											? true
											: false;
									}}
									render={(record) => {
										return (
											<ButtonDanger
												variant="circleIcon"
												startIcon={<DeleteIcon />}
												onClick={() =>
													this.handleDelete(
														record.data
													)
												}
											>
												{/* {this.props.t('delete')} */}
											</ButtonDanger>
										);
									}}
								/>,
							]}
						</Column>
					</DataGrid>
				</Col>
			</Row>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t("serviceSteps");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(ServiceVersionSteps));
