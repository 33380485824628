import apiConfig from "../../apiConfig";

export const testLicenseLegalFile = async (
	at,
	License_Legal_Form_File_Id,
	License_Legal_Form_Id,
	Investors_Count,
	License_Attachment_Type_Id,
	File_Code
) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/LICENSES/LEGAL/FILES/Test_Legal_File",
			{
				License_Legal_Form_File_Id,
				License_Legal_Form_Id,
				Investors_Count,
				License_Attachment_Type_Id,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response;
};
