import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";

import RegistrationInfoEditForm from "./RegistrationInfoEditForm";

class RegistrationInfoForm extends Component {
	state = {
		showForm: false,
	};

	renderEditForm = (...params) => {
		return (
			<div className="container mt-5">
				<RegistrationInfoEditForm
					editMood={this.props.editMood}
					viewMood={this.props.viewMood}
					planData={params[0]}
				/>
			</div>
		);
	};

	validate = (values) => {
		const errors = {};

		if (!values.License_Offer_Registration_Name_AR) {
			errors.License_Offer_Registration_Name_AR = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (!values.License_Offer_Registration_Name_EN) {
			errors.License_Offer_Registration_Name_EN = `${this.props.t(
				"error.field_required"
			)}`;
		}

		if (!values.License_Offer_Registration_Years) {
			errors.License_Offer_Registration_Years = `${this.props.t(
				"error.field_required"
			)}`;
		}

		return errors;
	};

	onPlanSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token;

		if (values.Active_Status_Id) {
			values["Active_Status_Id"] = 1;
		} else {
			values["Active_Status_Id"] = 0;
		}

		let bodyData = {
			License_Offer_Id: this.props.offerId,
			...values,
		};

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/OFFERS/Add_License_Offer_Registration",
				bodyData,
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			let offerPlanName =
					this.props.i18n.language === "ar"
						? response.data.Data.Offer_Registration_Info
								.License_Offer_Registration_Name_AR
						: response.data.Data.Offer_Registration_Info
								.License_Offer_Registration_Name_EN,
				dialogTitle = `${
					this.props.viewMood
						? this.props.t("viewServiceDetailTitle")
						: this.props.t("editServiceDetailTitle")
				} ${offerPlanName}`;

			this.props.triggerDialogForm(true, {
				title: dialogTitle,
				renderForm: this.renderEditForm,
				params: [response.data.Data],
			});

			this.props.reloadDatagrid();
			form.restart();
		}
	};

	render() {
		return (
			<>
				<Form
					// subscription={{ submitting: true }}
					onSubmit={this.onPlanSubmit}
					initialValues={{
						Active_Status_Id: 0,
					}}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						return (
							<>
								{!this.props.editMood &&
									!this.props.viewMood && (
										<div className="d-flex justify-content-end">
											<ButtonPrimary
												variant={
													!this.state.showForm
														? "contained"
														: "outlined"
												}
												onClick={() => {
													form.restart();
													this.setState({
														showForm:
															!this.state
																.showForm,
														serviceDetailsApisInputsList:
															[],
														selectedIntegrationApi:
															"",
													});
												}}
												type="button"
											>
												{!this.state.showForm &&
													this.props.t("addNewPlan")}
												{this.state.showForm &&
													this.props.t("cancel")}
											</ButtonPrimary>
										</div>
									)}
								<form
									id="registrationOfferForm"
									className={`row ${
										!this.state.showForm &&
										!this.props.editMood &&
										!this.props.viewMood
											? "d-none"
											: ""
									}`}
									onSubmit={handleSubmit}
									noValidate
								>
									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registrationPlanNameAR"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Name_AR"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>
									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registrationPlanNameEN"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Name_EN"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>

									<Col xs={12} md={4}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"registerationYears"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="License_Offer_Registration_Years"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
										/>
									</Col>

									<Col
										xs={12}
										md={6}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
									>
										<Switches
											disabled={this.props.viewMood}
											color="secondary"
											name="Active_Status_Id"
											data={{
												label: `${this.props.t(
													"serviceStatus"
												)}`,
												value: `${this.props.t(
													"serviceStatus"
												)}`,
											}}
										/>
									</Col>

									<Col className="mt-4" xs={12}>
										<Row className="align-items-baseline">
											<Col className="mt-2 submit-btn-wrap">
												{!this.props.viewMood && (
													<ButtonPrimary
														color="primary"
														variant="contained"
														disabled={
															submitting ||
															this.props.viewMood
														}
														type="submit"
													>
														{this.props.t("save")}
													</ButtonPrimary>
												)}
											</Col>
										</Row>
									</Col>
								</form>
							</>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(RegistrationInfoForm));
