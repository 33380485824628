import React, { Component } from "react";
import { connect } from "react-redux";

import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Selection,
	Editing,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { translationHook } from "../translationHook";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
} from "../../actions";

import { getServiceDetailFormInfo } from "../../apis/services/getServiceDetailFormInfo";

class ServiceDetailsFormInputsList extends Component {
	state = {
		reload: false,
		grid: [],
	};

	setOrderUp = async (serviceDetailFormId, serviceDetailId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_INPUT_ORDER_UP",
				{
					Service_Detail_Form_Id: serviceDetailFormId,
					Service_Detail_Id: serviceDetailId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then(async (res) => {
				if (res?.data?.Status) {
					this.setState({ reload: !this.state.reload });
					this.props.redrawGridCB();
				}
			});

		this.props.setLoading(false);
	};

	setOrderDown = async (serviceDetailFormId, serviceDetailId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_ORDER_DOWN",
				{
					Service_Detail_Form_Id: serviceDetailFormId,
					Service_Detail_Id: serviceDetailId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then(async (res) => {
				if (res?.data?.Status) {
					this.setState({ reload: !this.state.reload });
					this.props.redrawGridCB();
				}
			});

		this.props.setLoading(false);
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex align-items-center order-btns compact">
				<IconButton
					onClick={() =>
						this.setOrderUp(
							this.props.serviceDetailFormId,
							data.Service_Detail_Id
						)
					}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						this.setOrderDown(
							this.props.serviceDetailFormId,
							data.Service_Detail_Id
						)
					}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			ServiceDetailTitleKey = "Service_Detail_Title_EN",
			ServiceDetailTypeKey = "Service_Detail_Type_Name_EN";

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			ServiceDetailTitleKey = "Service_Detail_Title_AR";
			ServiceDetailTypeKey = "Service_Detail_Type_Name_AR";
		}

		let jsonDataSource = new CustomStore({
			key: "Service_Detail_Id",
			load: async () => {
				if (!this.props.serviceDetailFormId) {
					return [];
				}
				let at = this.props.currentUser.Data.access_token;
				const response = await getServiceDetailFormInfo(
					at,
					this.props.serviceDetailFormId
				);
				return response?.Form_Items;
			},
			update: async (key, values) => {
				let at = this.props.currentUser.Data.access_token;

				const response = await apiConfig
					.post(
						"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_INPUT_WIDTH",
						{
							Service_Detail_Form_Id:
								this.props.serviceDetailFormId,
							Service_Detail_Id: key,
							Service_Detail_Width: values.Service_Detail_Width,
						},
						{
							headers: {
								Authorization: `Bearer ${at}`,
							},
						}
					)
					.then(async (res) => {
						if (res?.data?.Status) {
							const response = await getServiceDetailFormInfo(
								at,
								res?.data?.Data?.Service_Detail_Form_Id
							);
							this.props.redrawGridCB();

							return response?.Form_Items;
						}
					})
					.catch((error) => {
						return error.response;
					});
			},
		});

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<Selection mode="single" />

					<Editing
						mode="cell"
						allowUpdating={true}
						allowAdding={false}
						allowDeleting={false}
					/>

					<Column
						cssClass="outlinedColumn"
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "right" : "left"
						}
						alignment="center"
						width={"100px"}
						cellRender={this.renderOrderButtons}
						allowEditing={false}
					></Column>

					<Column
						dataField={ServiceDetailTitleKey}
						caption={this.props.t("fieldName")}
						alignment={columnAlign}
						allowEditing={false}
					></Column>

					<Column
						dataField={ServiceDetailTypeKey}
						caption={this.props.t("fieldType")}
						alignment={columnAlign}
						allowEditing={false}
					></Column>
					<Column
						dataField="Service_Detail_Width"
						caption={this.props.t("fieldWidth")}
						alignment={"center"}
						dataType="number"
					></Column>
				</DataGrid>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
})(translationHook(ServiceDetailsFormInputsList));
