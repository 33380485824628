import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ButtonSecondary } from "./../../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { translationHook } from "./../../translationHook";
import { Form, Field } from "react-final-form";
import { TextField, Switches } from "mui-rff";
import apiConfig from "./../../../apis/apiConfig";
import { getLicenseForbiddenNameInfo } from "./../../../apis/licenses/licenseForbiddenNames/getLicenseForbiddenNameInfo";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "./../../UI/Buttons";
import {
	triggerDialog,
	setLoading,
	triggerNotification,
	triggerDialogForm,
	reload,
} from "./../../../actions";

class LicenseForbiddenName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showForm: false,
		};
	}

	handleSubmit = async (values, form) => {
		const at = this.props.currentUser.Data.access_token,
			url = this.props.editMood
				? "/API/ADMINISTRATION/LICENSES/NAMES/Upd_License_Forbidden_Name"
				: "/API/ADMINISTRATION/LICENSES/NAMES/Add_License_Forbidden_Name";

		let body = {
			License_Forbidden_Name_AR: values.License_Forbidden_Name_AR,
			License_Forbidden_Name_EN: values.License_Forbidden_Name_EN,
			Active_Status_Id: values.Active_Status_Id ? 1 : 0,
		};

		if (this.props.editMood) {
			body = { License_Forbidden_Name_Id: this.props.nameId, ...body };
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, body, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
		}

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};

			if (!values.License_Forbidden_Name_AR) {
				errors.License_Forbidden_Name_AR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.License_Forbidden_Name_EN) {
				errors.License_Forbidden_Name_EN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("LicensesForbiddenNamesList")}</h3>
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addName")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}

				<Form
					subscription={{ submitting: true }}
					onSubmit={this.handleSubmit}
					initialValues={
						initValues
							? initValues
							: {
									Active_Status_Id: true,
									License_Forbidden_Name_AR: "",
									License_Forbidden_Name_EN: "",
							  }
					}
					validate={validate}
					render={({ handleSubmit, form, submitting }) => (
						<form
							className={`row pt-5 px-3 ${
								!this.state.showForm &&
								!this.props.editMood &&
								!this.props.viewMood
									? "d-none"
									: ""
							}`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("nameAr")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									required={true}
									name="License_Forbidden_Name_AR"
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("nameEn")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									required={true}
									name="License_Forbidden_Name_EN"
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col
								xs={12}
								md={2}
								className={`${
									this.props.viewMood ? "read-only" : ""
								}`}
							>
								<Switches
									disabled={this.props.viewMood}
									color="primary"
									name="Active_Status_Id"
									data={{
										label: `${this.props.t(
											"serviceStatus"
										)}`,
										value: `${this.props.t(
											"serviceStatus"
										)}`,
									}}
								/>
							</Col>

							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(LicenseForbiddenName));
