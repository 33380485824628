import React, { Component } from "react";
import { connect } from "react-redux";

import { translationHook } from "../translationHook";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
} from "../../actions";

import { getServiceDetailFormInfo } from "../../apis/services/getServiceDetailFormInfo";

class ServiceDetailsFormInputsGrid extends Component {
	state = {
		reload: false,
		grid: [],
	};

	drawGrid = async (serviceDetailFormId) => {
		if (!serviceDetailFormId) {
			return null;
		}
		let at = this.props.currentUser.Data.access_token;

		const response = await getServiceDetailFormInfo(
			at,
			serviceDetailFormId
		);
		this.setState({ grid: response?.Form_Items });
	};

	componentWillReceiveProps(nextProps) {
		if (
			nextProps?.serviceDetailFormId !== this.props.serviceDetailFormId ||
			nextProps?.redrawGrid !== this.props.redrawGrid
		) {
			this.drawGrid(nextProps?.serviceDetailFormId);
		}
	}

	render() {
		if (this.state.grid.length < 1) return null;

		return (
			<>
				<div
					className="row mb-3"
					style={{
						backgroundColor: "#f7f9f5",
						padding: "1rem",
						borderRadius: "20px",
					}}
				>
					{this.state.grid.map((item) => {
						return (
							<div
								className={`col-${
									item.Service_Detail_Width > 12
										? 12
										: item.Service_Detail_Width
								} my-2`}
							>
								<div
									className=" d-flex justify-content-center align-items-center"
									style={{
										height:
											item?.Service_Detail_Type_Id === 4
												? "60px"
												: "30px",
										backgroundColor: "#ddd",
									}}
								>
									<p className="text-small m-0 text-truncate p-1">{`${item?.Service_Detail_Title_AR} / ${item.Service_Detail_Title_EN}`}</p>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
})(translationHook(ServiceDetailsFormInputsGrid));
