import apiConfig from "../apiConfig";

export const getServiceDetailFormInfo = async (at, serviceDetailFormid) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAIL_FORM_INFO",
		{
			Service_Detail_Form_Id: serviceDetailFormid,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
