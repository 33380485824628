import apiConfig from "../../apiConfig";

export const deleteOffer = async (at, offerId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Del_License_Offer",
		{
			License_Offer_Id: offerId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
