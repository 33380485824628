import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../apis/apiConfig";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";

import { ButtonPrimary, ButtonSecondary } from "./../UI/Buttons";
import { translationHook } from "../translationHook";
import {
	triggerDialog,
	setLoading,
	triggerNotification,
	reload,
} from "../../actions";

import Wysiwyg from "../UI/Wysiwyg";
import { withStyles } from "@material-ui/core/styles";

import { getLicensesOfferInfo } from "../../apis/licenses/licensesOffers/getLicensesOfferInfo";

const toolbaroptions = {
	items: [
		"undo",
		"redo",
		"separator",
		"bold",
		"italic",
		"underline",
		"strike",
		"separator",
		"link",
		{
			formatName: "header",
			formatValues: [false, 3, 4, 5],
		},
		{
			formatName: "size",
			formatValues: ["11px", "14px", "16px", "22px"],
		},
		"orderedList",
		"bulletList",
		"alignLeft",
		"alignCenter",
		"alignRight",
	],
};

const styles = (theme) => ({
	avatarRoot: {
		width: "100px !important",
		height: "100px !important",
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		border: "2px solid #ebecea",
		padding: "3px",
		borderRadius: "6px",
	},
	avatarImg: {
		borderRadius: "6px",
	},
	buttonRoot: {
		minWidth: "90px !important",
		padding: "0.5rem 1rem !important",
	},
});

class OfferInfo extends Component {
	state = {
		timeTypes: [],
		offerCategories: [],
		templatesList: [],
		createdServiceId: null,
		imgUrl: "",
		iconUrl: "",
		file: "",
		iconFile: "",
		reload: false,
		offerInitValues: {},
	};

	handleUploadClick = (event) => {
		let file = event.target.files[0];
		const reader = new FileReader();
		let url = reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			this.setState({
				imgUrl: reader.result,
				file,
			});
		};
	};

	handleIconUploadClick = (event) => {
		let file = event.target.files[0];
		const reader = new FileReader();
		let url = reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			this.setState({
				iconUrl: reader.result,
				iconFile: file,
			});
		};
	};

	getServiceCategories = async () => {
		let catsArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_CATEGORIES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				catsArr.push({ value: item.Record_Id, label });
			});

			this.setState({ offerCategories: catsArr });
		}
	};

	componentDidMount() {
		if (this.props.active) {
			this.getServiceCategories();
		}

		this.handleGetOfferInfo();
	}

	handleGetOfferInfo = async () => {
		let at = this.props.currentUser.Data.access_token;
		let offerInfoResponse = await getLicensesOfferInfo(
			at,
			this.props.serviceid
		).catch((error) => {
			return error.response;
		});
		if (offerInfoResponse.data.Status) {
			let offerInfo = offerInfoResponse.data.Data,
				initValues = {
					offerNameAR: offerInfo.License_Offer_Name_AR,
					offerNameEN: offerInfo.License_Offer_Name_EN,
					offerDetailsAR: offerInfo.License_Offer_Description_AR,
					offerDetailsEN: offerInfo.License_Offer_Description_EN,
					offerpriceDescAR:
						offerInfo.License_Offer_Price_Description_AR,
					offerpriceDescEN:
						offerInfo.License_Offer_Price_Description_EN,
					offerLocationDescAR:
						offerInfo.License_Offer_Location_Description_AR,
					offerLocationDescEN:
						offerInfo.License_Offer_Location_Description_EN,
					offerBenefitsDescAR:
						offerInfo.License_Offer_Benefits_Description_AR,
					offerBenefitsDescEN:
						offerInfo.License_Offer_Benefits_Description_EN,
					staysNo: offerInfo.License_Offer_Visas_Count,
					offerVal: offerInfo.License_Offer_Price,
					investorAvgNo: offerInfo.License_Offer_Investors_Count,
					activitiesAvgNo: offerInfo.License_Offer_Activities_Count,
					investorMaxNo: offerInfo.License_Offer_Investors_Max_Count,
					activitiesMaxNo:
						offerInfo.License_Offer_Activities_Max_Count,
					offerStatus: offerInfo.Active_Status_Id ? true : false,
					offerCategoryID: offerInfo.License_Legal_Form_Id,
					offerCode: offerInfo.License_Offer_Code,
					offerImg: offerInfo.License_Offer_Image_URL,
					offerIcon: offerInfo.License_Offer_Icon_URL,
				};
			this.setState({ offerInitValues: initValues });
			// update avatr img if there was one in initialValues
			if (offerInfo.offerImg !== "") {
				this.setState({ imgUrl: offerInfo.offerImg });
			}
			if (offerInfo.offerIcon !== "") {
				this.setState({ iconUrl: offerInfo.offerIcon });
			}
		}
	};

	handleServiceImgUpload = async (offerId) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/OFFERS/Upd_License_Offer_Image",
			bodyData = {
				License_Offer_Id: offerId,
			};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		bodyFormData.append("License_Offer_Image", this.state.file);

		const response = await apiConfig.post(url, bodyFormData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		return response;
	};

	handleOfferIconUpload = async (offerId) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/LICENSES/OFFERS/Upd_License_Offer_Icon",
			bodyData = {
				License_Offer_Id: offerId,
			};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		bodyFormData.append("License_Offer_Icon", this.state.iconFile);

		const response = await apiConfig.post(url, bodyFormData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		return response;
	};

	onServiceInfoSubmit = async (values, form) => {
		const at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/LICENSES/OFFERS/Add_License_Offer"
				: "/API/ADMINISTRATION/LICENSES/OFFERS/Upd_License_Offer";
		let body = {
			License_Offer_Code: values.offerCode,
			License_Offer_Name_AR: values.offerNameAR,
			License_Offer_Name_EN: values.offerNameEN,
			License_Offer_Description_AR: values.offerDetailsAR,
			License_Offer_Description_EN: values.offerDetailsEN,
			License_Offer_Price_Description_AR: values.offerpriceDescAR,
			License_Offer_Price_Description_EN: values.offerpriceDescEN,
			License_Offer_Location_Description_AR: values.offerLocationDescAR,
			License_Offer_Location_Description_EN: values.offerLocationDescEN,
			License_Offer_Benefits_Description_AR: values.offerBenefitsDescAR,
			License_Offer_Benefits_Description_EN: values.offerBenefitsDescEN,
			License_Offer_Visas_Count: values.staysNo,
			License_Offer_Price: values.offerVal,
			License_Offer_Investors_Count: values.investorAvgNo,
			License_Offer_Activities_Count: values.activitiesAvgNo,
			License_Offer_Investors_Max_Count: values.investorMaxNo,
			License_Offer_Activities_Max_Count: values.activitiesMaxNo,
			Active_Status_Id: values.offerStatus ? 1 : 0,
			License_Legal_Form_Id: values.offerCategoryID,
		};

		if (this.props.editMood) {
			body = { License_Offer_Id: this.props.serviceid, ...body };

			// const uploadResult = this.handleServiceImgUpload();
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, body, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (this.state.file) {
			await this.handleServiceImgUpload(
				response.data.Data.License_Offer_Id
			);
		}
		if (this.state.iconFile) {
			await this.handleOfferIconUpload(
				response.data.Data.License_Offer_Id
			);
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);

		if (response.data.Status) {
			values.offerImg = this.state.imgUrl;
			values.offerIcon = this.state.iconUrl;

			this.props.onStepDone(response.data.Data.License_Offer_Id);

			if (!this.props.editMood) {
				this.setState({
					createdServiceId: response.data.Data.License_Offer_Id,
				});
			} else {
				form.restart();
			}
		}
	};

	render() {
		const validate = (values) => {
			const errors = {};

			// if (!values.serviceCode) {
			// 	errors.serviceCode = `${this.props.t("error.field_required")}`;
			// }

			// if (!values.serviceNameAR) {
			// 	errors.serviceNameAR = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceNameEN) {
			// 	errors.serviceNameEN = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.servicePlaceNameAR) {
			// 	errors.servicePlaceNameAR = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.servicePlaceNameEN) {
			// 	errors.servicePlaceNameEN = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceHoursAR) {
			// 	errors.serviceHoursAR = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceHoursEN) {
			// 	errors.serviceHoursEN = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceDescAR) {
			// 	errors.serviceDescAR = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceDescEN) {
			// 	errors.serviceDescEN = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceRulesAR) {
			// 	errors.serviceRulesAR = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceRulesEN) {
			// 	errors.serviceRulesEN = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceTime) {
			// 	errors.serviceTime = `${this.props.t("error.field_required")}`;
			// }

			// if (!values.serviceTimeType) {
			// 	errors.serviceTimeType = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.serviceCategoryID) {
			// 	errors.serviceCategoryID = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			// if (!values.chartSignature) {
			// 	errors.chartSignature = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			return errors;
		};

		if (
			this.props.serviceid &&
			Object.keys(this.state.offerInitValues).length === 0
		) {
			return <p>loading......</p>;
		}

		return (
			<Form
				subscription={{ submitting: true }}
				onSubmit={this.onServiceInfoSubmit}
				initialValues={this.state.offerInitValues}
				validate={validate}
				render={({ handleSubmit, form, submitting }) => (
					<form className="row" onSubmit={handleSubmit} noValidate>
						<Col xs={12} md={8}>
							<Row>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("offerNameAR")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="offerNameAR"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("offerNameEN")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="offerNameEN"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
										"offerDetailsAR"
									)} *`}</label>
									<Field
										name="offerDetailsAR"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="250px"
												defaultValue={
													this.state.offerInitValues
														.offerDetailsAR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>
								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
										"offerDetailsEN"
									)} *`}</label>
									<Field
										name="offerDetailsEN"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="250px"
												defaultValue={
													this.state.offerInitValues
														.offerDetailsEN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2">{`${this.props.t(
										"offerpriceDescAR"
									)} *`}</label>
									<Field
										name="offerpriceDescAR"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerpriceDescAR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2 mb-2">{`${this.props.t(
										"offerLocationDescAR"
									)} *`}</label>
									<Field
										name="offerLocationDescAR"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerLocationDescAR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2 mb-2">{`${this.props.t(
										"offerpriceDescEN"
									)} *`}</label>
									<Field
										name="offerpriceDescEN"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerpriceDescEN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2 mb-2">{`${this.props.t(
										"offerLocationDescEN"
									)} *`}</label>
									<Field
										name="offerLocationDescEN"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerLocationDescEN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("staysNo")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2 mt-3`}
										required={true}
										name="staysNo"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("offerVal")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2 mt-3`}
										required={true}
										name="offerVal"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("investorAvgNo")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="investorAvgNo"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("activitiesAvgNo")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="activitiesAvgNo"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("investorMaxNo")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="investorMaxNo"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("activitiesMaxNo")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="activitiesMaxNo"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								{!this.props.viewMood && (
									<Col xs={12} className="mt-3">
										<ButtonPrimary
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												this.props.viewMood
											}
											type="submit"
										>
											{this.props.t("save")}
										</ButtonPrimary>
									</Col>
								)}
							</Row>
						</Col>

						<Col xs={12} md={4}>
							<Row>
								<Col xs={12} md={6}>
									<Row>
										<Col
											xs={12}
											md={12}
											className="d-flex ServiceAvatarContainer"
										>
											<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
												"offerImage"
											)} *`}</label>
											<Avatar
												variant="square"
												classes={{
													root: this.props.classes
														.avatarRoot,
													img: this.props.classes
														.avatarImg,
												}}
												src={
													this.state.imgUrl
														? this.state.imgUrl
														: "/broken-image.jpg"
												}
											/>
										</Col>
										{!this.props.viewMood && (
											<Col xs={12} md={12}>
												<ButtonSecondary
													color="primary"
													variant="contained"
													endIcon={
														<CloudUploadIcon />
													}
												>
													{this.props.t("offerImage")}
													<input
														type="file"
														name="offerImage"
														className="buttonFile"
														onChange={
															this
																.handleUploadClick
														}
													/>
												</ButtonSecondary>
											</Col>
										)}
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Col
											xs={12}
											md={12}
											className="d-flex ServiceAvatarContainer"
										>
											<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
												"offerIcon"
											)} *`}</label>
											<Avatar
												variant="square"
												classes={{
													root: this.props.classes
														.avatarRoot,
													img: this.props.classes
														.avatarImg,
												}}
												src={
													this.state.iconUrl
														? this.state.iconUrl
														: "/broken-image.jpg"
												}
											/>
										</Col>
										{!this.props.viewMood && (
											<Col xs={12} md={12}>
												<ButtonSecondary
													color="primary"
													variant="contained"
													endIcon={
														<CloudUploadIcon />
													}
												>
													{this.props.t("offerIcon")}
													<input
														type="file"
														name="offerIcon"
														className="buttonFile"
														onChange={
															this
																.handleIconUploadClick
														}
													/>
												</ButtonSecondary>
											</Col>
										)}
									</Row>
								</Col>

								<Col xs={12} md={12}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2">{`${this.props.t(
										"offerBenefitsDescAR"
									)} *`}</label>
									<Field
										name="offerBenefitsDescAR"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerBenefitsDescAR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} md={12}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled mt-2">{`${this.props.t(
										"offerBenefitsDescEN"
									)} *`}</label>
									<Field
										name="offerBenefitsDescEN"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="200px"
												defaultValue={
													this.state.offerInitValues
														.offerBenefitsDescEN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</Col>

								<Col xs={12} className="mt-4">
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("offerCode")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="offerCode"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col md={12}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t("LicenseCat")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
										required={true}
										name="offerCategoryID"
										data={this.state.offerCategories}
										showError={() => {
											let fieldStatus =
												form.getFieldState(
													"serviceCategoryID"
												);

											if (fieldStatus) {
												return (
													fieldStatus.submitFailed &&
													fieldStatus.invalid
												);
											}
										}}
									></Select>
								</Col>

								<Col
									md={12}
									className={`${
										this.props.viewMood ? "read-only" : ""
									}`}
								>
									<Switches
										disabled={this.props.viewMood}
										color="primary"
										name="offerStatus"
										data={{
											label: `${this.props.t(
												"serviceStatus"
											)}`,
											value: `${this.props.t(
												"serviceStatus"
											)}`,
										}}
									/>
								</Col>
							</Row>
						</Col>
					</form>
				)}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	triggerNotification,
	reload,
})(translationHook(withStyles(styles)(OfferInfo)));
