import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Button,
} from "devextreme-react/data-grid";
import { Form, Field } from "react-final-form";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import moment from "moment";
import { ButtonWhite } from "../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import { Select } from "mui-rff";

import { getListDepartmentServiceTemplatesFiles } from "../../apis/services/departments/getListDepartmentServiceTemplatesFiles";
import { downloadDepartmentTemplateAttachment } from "../../apis/services/departments/downloadDepartmentTemplateAttachment";
import { deleteDepartmentTemplateAttachment } from "../../apis/services/departments/deleteDepartmentTemplateAttachment";
import { saveDepartmentTemplateAttachment } from "../../apis/services/departments/saveDepartmentTemplateAttachment";
import { testDepartmentTemplateAttachment } from "../../apis/services/departments/testDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachment } from "../../apis/services/departments/updateDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachmentList } from "../../apis/services/departments/updateDepartmentTemplateAttachmentList";

import { getLegalInvestorsCountList } from "../../apis/licenses/licenseLegalFiles/getLegalInvestorsCountList";
import { saveLicenseLegalFile } from "../../apis/licenses/licenseLegalFiles/saveLicenseLegalFile";
import { testLicenseLegalFile } from "../../apis/licenses/licenseLegalFiles/testLicenseLegalFile";
import { deleteLicenseLegalFile } from "../../apis/licenses/licenseLegalFiles/deleteLicenseLegalFile";

import { downloadAttachment } from "../../apis/system/downloadAttachment";

import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
} from "../../actions";
import { translationHook } from "../translationHook";

import "./Templates.module.scss";
import style from "./ReturnStep.module.scss";
import imgLoading from "../../images/ShMunAnimatedGif.gif";
import imgLoadingStrip from "../../imagesTemp/STRIPAnimatGif-ForceWhite.gif";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";

class LicenseLegalFiles extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		fileIdsList: [],
		legalsFormsList: [],
		formTypeId: 0,
		selectedCount: "0",
		investorsCountList: [],
		filesList: [],
	};

	handleDelete = async ({
		License_Legal_Form_File_Id,
		License_Legal_Form_Id,
		Investors_Count,
		License_Attachment_Type_Id,
		File_Code,
		File_Name,
	}) => {
		let at = this.props.currentUser.Data.access_token;
		this.props.setLoading(true, "dark");

		const response = await deleteLicenseLegalFile(
			at,
			License_Legal_Form_File_Id,
			License_Legal_Form_Id,
			Investors_Count,
			License_Attachment_Type_Id,
			File_Code
		).catch((error) => {
			return error.response;
		});

		this.props.setLoading(false);

		if (response.data.Status) {
			this.setState({ filesList: response.data.Data });
		}
	};

	handleSelectCount = async (e) => {
		let at = this.props.currentUser.Data.access_token;

		this.setState({ selectedCount: e.target.value, filesList: [] });

		let filesList = await this.getLegalFilesList(
			at,
			this.state.formTypeId,
			e.target.value
		);

		if (filesList.data.Status) {
			this.setState({ filesList: filesList.data.Data });
		}
	};

	handleLegalFormSelect = async (e) => {
		this.setState({ formTypeId: e.target.value, filesList: [] });

		let at = this.props.currentUser.Data.access_token;

		let response = await getLegalInvestorsCountList(at, e.target.value);

		if (response.data.Status) {
			this.setState({ investorsCountList: response.data.Data });
		}
	};

	handleUploadClick = async (
		event,
		License_Legal_Form_Id,
		Investors_Count,
		License_Attachment_Type_Id
	) => {
		let file = event.target.files[0];

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			let response = await saveLicenseLegalFile(
				at,
				License_Legal_Form_Id,
				Investors_Count,
				License_Attachment_Type_Id,
				file
			);

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.setState({
					filesList: response.data.Data,
				});
			}
		}
	};

	handleTestFile = async ({
		License_Legal_Form_File_Id,
		License_Legal_Form_Id,
		Investors_Count,
		License_Attachment_Type_Id,
		File_Code,
		File_Name,
	}) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		let downloadResult = await testLicenseLegalFile(
			at,
			License_Legal_Form_File_Id,
			License_Legal_Form_Id,
			Investors_Count,
			License_Attachment_Type_Id,
			File_Code
		).catch((error) => {
			return error.response;
		});

		this.props.setLoading(false);

		if (downloadResult.status === 200) {
			let contentType = downloadResult.headers["content-type"],
				typesArray = [
					"application/pdf",
					"image/bmp",
					"image/gif",
					"image/vnd.microsoft.icon",
					"image/jpeg",
					"image/png",
					"image/svg+xml",
					"image/tiff",
					"image/webp",
				],
				url = window.URL.createObjectURL(
					new Blob([downloadResult.data], {
						type: contentType,
					})
				),
				link = document.createElement("a");

			if (downloadResult?.data?.Data?.File_Bytes) {
				url = window.URL.createObjectURL(
					new Blob([downloadResult.data.Data.File_Bytes], {
						type: contentType,
					})
				);

				File_Name = downloadResult.data.Data.File_Name;
			}

			link.href = url;

			typesArray.includes(contentType)
				? link.setAttribute("target", "_blank")
				: link.setAttribute("download", File_Name);

			document.body.appendChild(link);
			link.click();
		}
	};

	handleUploadListClick = async (event, Template_Id) => {
		let file = event.target.files[0],
			response;

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			response = await updateDepartmentTemplateAttachmentList(
				at,
				Template_Id,
				this.state.fileIdsList,
				file
			);

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.props.reload();
			}
		}
	};

	handleUpdateFileOption = async (bodyData) => {
		let at = this.props.currentUser.Data.access_token;
		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/LEGAL/FILES/Update_Legal_File_Options",
				bodyData,
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		return response;
	};

	customizeQRCodeSignCellCheckbox = ({ data }) => {
		if (!data.File_Code || data.File_Code === "") return null;

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.File_Code}
						name={`userSign_${data.License_Legal_Form_File_Id}`}
						onChange={async () => {
							let File_Code = data.File_Code ? 0 : 1;

							let bodyData = {
								...data,
								File_Code,
							};

							let response = await this.handleUpdateFileOption(
								bodyData
							);

							if (response.data.Status) {
								this.setState({
									filesList: response.data.Data,
								});
							}
						}}
					/>
				}
			/>
		);
	};

	customizeApprovalSignCellCheckbox = ({ data }) => {
		if (!data.File_Code || data.File_Code === "") return null;

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Enable_Approval_Sign}
						name={`userSign_${data.License_Legal_Form_File_Id}`}
						onChange={async () => {
							let Enable_Approval_Sign = data.Enable_Approval_Sign
								? 0
								: 1;

							let bodyData = {
								...data,
								Enable_Approval_Sign,
							};

							let response = await this.handleUpdateFileOption(
								bodyData
							);

							if (response.data.Status) {
								this.setState({
									filesList: response.data.Data,
								});
							}
						}}
					/>
				}
			/>
		);
	};

	customizeReviewSignCellCheckbox = ({ data }) => {
		if (!data.File_Code || data.File_Code === "") return null;

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Enable_Review_Sign}
						name={`userSign_${data.License_Legal_Form_File_Id}`}
						onChange={async () => {
							let Enable_Review_Sign = data.Enable_Review_Sign
								? 0
								: 1;

							let bodyData = {
								...data,
								Enable_Review_Sign,
							};

							let response = await this.handleUpdateFileOption(
								bodyData
							);

							if (response.data.Status) {
								this.setState({
									filesList: response.data.Data,
								});
							}
						}}
					/>
				}
			/>
		);
	};

	customizeUserSignCellCheckbox = ({ data }) => {
		if (!data.File_Code || data.File_Code === "") return null;

		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Enable_User_Sign}
						name={`userSign_${data.License_Legal_Form_File_Id}`}
						onChange={async () => {
							let Enable_User_Sign = data.Enable_User_Sign
								? 0
								: 1;

							let bodyData = {
								...data,
								Enable_User_Sign,
							};

							let response = await this.handleUpdateFileOption(
								bodyData
							);

							if (response.data.Status) {
								this.setState({
									filesList: response.data.Data,
								});
							}
						}}
					/>
				}
			/>
		);
	};

	customizeDownloadCell = ({ data }) => {
		if (data.File_Code) {
			return (
				<List className="grid-list-item">
					<ListItem key={data.License_Legal_Form_File_Id}>
						<div
							className={`d-flex w-100 flex-column align-items-center`}
						>
							<ListItemText
								className="text-center"
								primary={data.File_Name}
								secondary={data.File_Size}
							/>

							<ListItemSecondaryAction
								className="d-flex"
								style={{
									position: "unset",
									top: "auto",
									transform: "none",
								}}
							>
								<>
									<IconButton
										edge="end"
										aria-label="download"
										onClick={async () => {
											let at =
												this.props.currentUser.Data
													.access_token;

											let downloadResult =
												await downloadAttachment({
													at,
													File_Code: data.File_Code,
												});

											if (downloadResult.status === 200) {
												let contentType =
														downloadResult.headers[
															"content-type"
														],
													typesArray = [
														"application/pdf",
														"image/bmp",
														"image/gif",
														"image/vnd.microsoft.icon",
														"image/jpeg",
														"image/png",
														"image/svg+xml",
														"image/tiff",
														"image/webp",
													],
													url =
														window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult.data,
																],
																{
																	type: contentType,
																}
															)
														),
													link =
														document.createElement(
															"a"
														);

												if (
													downloadResult?.data?.Data
														?.File_Bytes
												) {
													url =
														window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult
																		.data
																		.Data
																		.File_Bytes,
																],
																{
																	type: contentType,
																}
															)
														);

													data.File_Name =
														downloadResult.data.Data.File_Name;
												}

												link.href = url;

												typesArray.includes(contentType)
													? link.setAttribute(
															"target",
															"_blank"
													  )
													: link.setAttribute(
															"download",
															data.File_Name
													  );

												document.body.appendChild(link);
												link.click();
											}
										}}
									>
										<GetAppIcon />
									</IconButton>
								</>
							</ListItemSecondaryAction>
						</div>
					</ListItem>
				</List>
			);
		}
	};

	customizeUploadCell = ({ data }) => {
		return (
			<div className="d-flex justify-content-center">
				<IconButton className="grid-upload-button m-0">
					<input
						type="file"
						onChange={(e) => {
							this.handleUploadClick(
								e,
								data.License_Legal_Form_Id,
								data.Investors_Count,
								data.License_Attachment_Type_Id
							);
						}}
					/>
					<PublishIcon />
				</IconButton>
			</div>
		);
	};

	customizeTestCell = ({ data }) => {
		if (data.File_Code && data.File_Code !== "") {
			return (
				<div className="d-flex justify-content-center">
					<ButtonInfo
						variant="circleIcon"
						startIcon={<VisibilityIcon />}
						onClick={() => {
							this.handleTestFile(data);
						}}
					></ButtonInfo>
				</div>
			);
		}
	};

	handleSelectionChanged = (data) => {
		this.setState({ fileIdsList: data.selectedRowKeys });
	};

	onPlanSubmit = async (values, form) => {
		return;
	};

	validate = (values) => {
		const errors = {};

		return errors;
	};

	getLegalFormTypesList = async () => {
		let typesArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LEGALS_FORMS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Legal_Form_Name_AR
						: item.License_Legal_Form_Name_EN;

				typesArr.push({
					value: item.License_Legal_Form_Id,
					label,
				});
			});

			this.setState({
				legalsFormsList: [...typesArr],
			});
		}
	};

	getLegalFilesList = async (at, License_Legal_Form_Id, Investors_Count) => {
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/LEGAL/FILES/Get_Legal_Files_List",
			{
				License_Legal_Form_Id,
				Investors_Count,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		return response;
	};

	componentDidMount() {
		this.getLegalFormTypesList();
	}

	componentDidUpdate() {
		console.log(typeof this.state.selectedCount);
	}

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "License_Legal_Form_File_Id",
			load: () => {
				return this.state.filesList;
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t("manageLicenseLegalFiles")}</h3>
				</div>

				<Form
					// subscription={{ submitting: true }}
					onSubmit={this.onPlanSubmit}
					validate={this.validate}
					initialValues={{
						investorsCount: this.state.selectedCount,
					}}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						return (
							<>
								<form
									id="legalFormsForm"
									className="row"
									onSubmit={handleSubmit}
									noValidate
								>
									<div className="col-12 col-md-6">
										<Select
											label={this.props.t("legalForm")}
											className="mb-2"
											name="Invoice_Type_Id"
											required={true}
											data={this.state.legalsFormsList}
											value={this.state.formTypeId}
											onChange={(e) => {
												this.handleLegalFormSelect(e);
											}}
										></Select>
									</div>

									{this.state.investorsCountList.length >
										0 && (
										<div className="col-12">
											<FormControl className="mt-3">
												<label
													className={style.radioLabel}
												>
													{this.props.t(
														"investorsCount"
													)}
												</label>
												<RadioGroup
													row
													name="investorsCount"
													value={
														this.state.selectedCount
													}
													onChange={
														this.handleSelectCount
													}
												>
													{this.state.investorsCountList.map(
														(item) => {
															return (
																<FormControlLabel
																	value={JSON.stringify(
																		item.Investors_Count
																	)}
																	control={
																		<Radio />
																	}
																	label={
																		this
																			.props
																			.i18n
																			.language ===
																		"ar"
																			? item.Investors_Count_Name_AR
																			: item.Investors_Count_Name_EN
																	}
																/>
															);
														}
													)}
												</RadioGroup>
											</FormControl>
										</div>
									)}
								</form>
							</>
						);
					}}
				/>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={this.state.filesList}
					key="License_Legal_Form_File_Id"
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={false}
					onExporting={this.onExporting}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					onSelectionChanged={this.handleSelectionChanged}
				>
					{/* <ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/> */}

					<Column
						dataField={"File_No"}
						caption={this.props.t("fileNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "License_Attachment_Type_Name_EN"
								: "License_Attachment_Type_Name_AR"
						}
						caption={this.props.t("fileType")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="File_Size"
						caption={this.props.t("fileSize")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Enable_User_Sign"
						caption={this.props.t("userSign")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeUserSignCellCheckbox}
					></Column>

					<Column
						dataField="Enable_Review_Sign"
						caption={this.props.t("reviewSign")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeReviewSignCellCheckbox}
					></Column>

					<Column
						dataField="Enable_Approval_Sign"
						caption={this.props.t("approvalSign")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeApprovalSignCellCheckbox}
					></Column>

					<Column
						dataField="Enable_QR_Code"
						caption={this.props.t("QRCodeSign")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeQRCodeSignCellCheckbox}
					></Column>

					<Column
						caption={this.props.t("download")}
						cellRender={this.customizeDownloadCell}
						width="200"
						alignment={"center"}
					></Column>

					<Column
						caption={this.props.t("upload")}
						cellRender={this.customizeUploadCell}
						alignment={"center"}
						width="100px"
					></Column>

					<Column
						caption={this.props.t("test")}
						cellRender={this.customizeTestCell}
						alignment={"center"}
						width="100px"
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("delete")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServices");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(LicenseLegalFiles));
