import apiConfig from "../../apiConfig";

export const getLicensesOfferInfo = async (at, offerId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Get_License_Offer_Info",
		{ License_Offer_Id: offerId },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
