import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import style from "./Login.module.scss";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LanguageBtn from "./../UI/LanguageBtn/LanguageBtn";
import Footer from "./../UI/Footer/Footer";
import Sharjah from "./../../images/login/logos/Sharjah-Mun-Logo.svg";
import Strip from "./../../imagesTemp/login/logos/STRIP.png";
import Video from "./../../images/login/video.mp4";
import VideoStrip from "./../../imagesTemp/login/Sharjah-Research-Technology-and-Innovation-Park.mp4";
import VideoCover from "react-video-cover";
import LoginForm from "./LoginForm";
import ResetPassAfterFirstLog from "../Login/ResetPassword";
const VerificationLogin = (props) => {
	const i18n = useTranslation(),
		activeTheme = document.body.classList;

	const theme = createMuiTheme({
		typography: {
			fontFamily: ["Cairo", "sans-serif"].join(","),
		},
		palette: {
			primary: {
				main: "#000033",
			},
		},
	});

	let mystyle;
	if (i18n.i18n.language === "ar") {
		mystyle = {
			container: {
				direction: "rtl",
				textAlign: "right",
			},
			whiteContainer: {
				paddingLeft: 0,
			},
			videoWrapParent: {
				borderTopLeftRadius: "20px",
				borderBottomLeftRadius: "20px",
			},
			videoWrap: {
				borderTopLeftRadius: "20px",
				borderBottomLeftRadius: "20px",
				height: "140%",
				transform: "translateY(-15%)",
			},
		};
	} else {
		mystyle = {
			container: {
				textAlign: "left",
				direction: "ltr",
			},
			whiteContainer: {
				paddingRight: 0,
			},
			videoWrapParent: {
				borderTopRightRadius: "20px",
				borderBottomRightRadius: "20px",
			},
			videoWrap: {
				borderTopRightRadius: "20px",
				borderBottomRightRadius: "20px",
				height: "140%",
				transform: "translateY(-15%)",
			},
		};
	}

	const videoOptions = {
		src: process.env.REACT_APP_IMG_SRC == 2 ? VideoStrip : Video,
		autoPlay: true,
		loop: true,
		muted: true,
	};

	return (
		<ThemeProvider theme={theme}>
			<Container className="my-5" style={mystyle.container}>
				<Row className={style.contentContainer}>
					<Col
						className={style.whiteContainer}
						lg={10}
						md={12}
						style={mystyle.whiteContainer}
					>
						<Row className="mx-0">
							<Col lg={5} sm={12} className="py-3">
								<Row className="mt-4 mx-md-2 mb-lg-0 mb-4">
									<Col xs={8}>
										{activeTheme.contains("sharjah") ? (
											<img
												className={style.logo}
												src={
													process.env
														.REACT_APP_IMG_SRC == 2
														? Strip
														: Sharjah
												}
											/>
										) : null}
									</Col>
									<Col
										xs={4}
										className="d-flex justify-content-end"
									>
										<LanguageBtn login={true} />
									</Col>
								</Row>
								{props.currentUser.Data.Reset_Password === 1 ? (
									<ResetPassAfterFirstLog />
								) : (
									<LoginForm />
								)}
							</Col>
							<Col className="mt-md-0 mt-5 p-0" lg={7} sm={12}>
								<div
									style={mystyle.videoWrapParent}
									className={style.videoWrap}
								>
									<VideoCover
										videoOptions={videoOptions}
										style={mystyle.videoWrap}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Footer />
			</Container>
		</ThemeProvider>
	);
};

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(VerificationLogin);
