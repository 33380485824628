import apiConfig from '../apiConfig'

export const getLicensesList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LICENSES/LIST/Get_Licenses_List',
		{
			PageSize: 100000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};

export const getLicensesDetails = async (at , id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LICENSES/LIST/GET_LICENSE_INFO',
		{
            License_Id: id
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};