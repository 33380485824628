import { Button, withStyles } from "@material-ui/core";
import {
	red,
	teal,
	blue,
	yellow,
	green,
	grey,
	orange,
	amber,
	deepPurple,
} from "@material-ui/core/colors";
import { Autocomplete } from "mui-rff";

export const ButtonPrimary = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		color: grey[50],
		backgroundColor: "#00b1eb",
		borderColor: "#00b1eb",
		borderRadius: "30px",
		padding: ".75rem 1.5rem",
		minWidth: "120px",
		"&:hover": {
			backgroundColor: "#00b1eb",
			// backgroundColor: green[900],
			color: grey[50],
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
		"&.btn-primary_alt": {
			borderRadius: "4px",
			padding: "6px 16px",
		},
	},
}))(Button);

export const ButtonSecondary = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		backgroundColor: grey[600],
		borderColor: grey[600],
		color: grey[50],
		"&:hover": {
			backgroundColor: grey[700],
			color: "#fff",
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
		"& .MuiButton-endIcon": {
			marginLeft: "8px",
			marginRight: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: grey[800],
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-endIcon": {
			marginLeft: "8px",
			marginRight: "-4px",
		},
	},
}))(Button);

export const ButtonDanger = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		color: theme.palette.getContrastText(red[700]),
		backgroundColor: red[700],
		borderColor: red[700],
		"&:hover": {
			backgroundColor: red[900],
			color: "#fff",
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: red[700],
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-startIcon": {
			marginLeft: "4px",
			marginRight: "4px",
		},
	},
}))(Button);

export const ButtonInfo = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		color: theme.palette.getContrastText(teal[700]),
		backgroundColor: '#00b1eb',
		// backgroundColor: teal[400],
		borderColor: '#00b1eb',
		// borderColor: teal[400],
		"&:hover": {
			backgroundColor: '#00b1eb',
			// backgroundColor: teal[600],
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: teal[700],
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-startIcon": {
			marginLeft: "4px",
			marginRight: "4px",
		},
	},
}))(Button);

export const ButtonWarning = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		color: "#ffffff",
		backgroundColor: amber[700],
		borderColor: amber[700],
		"&:hover": {
			backgroundColor: amber[800],
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: amber[700],
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-startIcon": {
			marginLeft: "4px",
			marginRight: "4px",
		},
	},
}))(Button);

export const ButtonPurple = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		backgroundColor: deepPurple[400],
		borderColor: deepPurple[400],
		color: "#fff",
		"&:hover": {
			backgroundColor: deepPurple[600],
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: deepPurple[500],
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-startIcon": {
			marginLeft: "4px",
			marginRight: "4px",
		},
	},
}))(Button);

export const ButtonWhite = withStyles((theme) => ({
	root: {
		fontFamily: "Cairo, sans-serif",
		backgroundColor: "#fff",
		borderColor: "#fff",
		boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
		color: "#151713",
		"&:hover": {
			backgroundColor: "#fdfdfd",
		},
		margin: "0 2px",
		"& .MuiButton-startIcon": {
			marginRight: "8px",
			marginLeft: "-4px",
		},
	},
	outlined: {
		"& .MuiButton-label": {
			color: "#fff",
		},
	},
	circleIcon: {
		width: "40px",
		height: "40px",
		minWidth: "auto",
		borderRadius: "50%",
		"& .MuiButton-startIcon": {
			marginLeft: "4px",
			marginRight: "4px",
		},
	},
}))(Button);
