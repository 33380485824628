import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import UploadService from "./FileUploadService";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import { ButtonSecondary } from "../../UI/Buttons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { setLoading, triggerNotification } from "../../../actions";
import style from "./UploadFiles.module.scss";
import DocumentInfoLists from "../../requests/processing/charts/DocumentInfoLists";
import ConfirmationDialog from "../ConfirmationDialog";

const UploadFiles = (props) => {
	const useStyles = makeStyles((theme) => ({
		root: {
			width: "100%",
			maxWidth: props.deleteCB ? "auto" : 360,
			backgroundColor: theme.palette.background.paper,
			"& .MuiListItemText-root": {
				textAlign: "start",
				"& .MuiListItemText-primary": {
					// maxWidth: "80%",
					// whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				},
			},
			"& .MuiListItem-secondaryAction": {
				paddingRight: props.i18n.language === "ar" ? "16px" : "48px",
				paddingLeft: props.i18n.language === "ar" ? "48px" : "16px",
			},
			"& .MuiListItemSecondaryAction-root": {
				right: props.i18n.language === "ar" ? "auto" : "16px",
				left: props.i18n.language === "ar" ? "16px" : "auto",
			},
			"& .MuiListItemAvatar-root": {
				minWidth: "initial",
				marginLeft: props.i18n.language === "ar" ? "10px" : "0",
				marginRight: props.i18n.language === "ar" ? "0" : "10px",
			},
		},

		uploadInput: {
			marginBottom: "6px",
			"& .MuiButtonBase-root": {
				position: "relative",
				minWidth: "100px",
				display: "inline-flex",
				justifyContent: "space-between",
				"& .MuiButton-endIcon": {
					marginLeft: props.i18n.language === "ar" ? "-4px" : "8px",
					marginRight: props.i18n.language === "ar" ? "8px" : "-4px",
				},
			},
			"& input": {
				position: "absolute",
				width: "100%",
				height: "100%",
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 1,
				opacity: 0,
				fontSize: 0,
				cursor: "pointer",
			},
		},
		progressBar: {
			backgroundColor: "#00b1eb",
		},
		labelAction: {
			color: "#d32f2f",
			textDecoration: "none",
			"&:hover": {
				color: "#b71c1c",
				textDecoration: "none",
			},
		},
	}));

	const classes = useStyles();

	const [selectedFiles, setSelectedFiles] = useState(undefined);
	const [progressInfos, setProgressInfos] = useState({ val: [] });
	const [fileInfos, setFileInfos] = useState([]);
	const progressInfosRef = useRef(null);
	const [uploadResult, setUploadResult] = useState([]);

	const [confirmationDialog, setConfirmationDialog] = useState({
		isOpen: false,
		title: "",
		okButtonTitle: "",
		closeButtonTitle: "",
		actionCallback: () => {},
	});

	const lang = props.i18n.language === "ar" ? "AR" : "EN";

	useEffect(() => {
		if (fileInfos.length > 0 && !props.view) {
			uploadFiles();
		}
	}, [fileInfos]);

	// check on component mount if an array of files is passed and set to the the uploadResult
	// poosible refactor later to remove the view prop and attachment logic at the bottom
	useEffect(() => {
		if (props.filesData) {
			setUploadResult(props.filesData);

			const lang = props.i18n.language === "ar" ? "AR" : "EN";
		}
	}, [props.i18n, props.filesData]);

	const base64ToArrayBuffer = (base64) => {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	};

	const selectFiles = (event) => {
		let filesData = [];

		setSelectedFiles(event.target.files);
		setProgressInfos({ val: [] });

		for (const file in event.target.files) {
			if (Object.hasOwnProperty.call(event.target.files, file)) {
				const element = event.target.files[file];
				filesData.push(element);
			}
		}

		setFileInfos(filesData);
	};

	const uploadFiles = () => {
		const files = Array.from(selectedFiles);

		let _progressInfos = files.map((file) => ({
			percentage: 0,
			fileName: file.name,
		}));

		progressInfosRef.current = {
			val: _progressInfos,
		};

		const uploadPromises = files.map((file, i) => upload(i, file));

		// props.setLoading(true, 'dark', 1301)

		Promise.all(uploadPromises).then(() => {
			// props.setLoading(false)
			setProgressInfos({ val: [] });
		});

		// setUploadResult([])
	};

	const upload = (idx, file) => {
		let _progressInfos = [...progressInfosRef.current.val],
			endPoint = props.endPoint
				? props.endPoint
				: "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_ATTACHMENT",
			fileApiKey = props.fileApiKey ? props.fileApiKey : "Attached_File";

		return UploadService.upload(
			file,
			(event) => {
				_progressInfos[idx].percentage = Math.round(
					(100 * event.loaded) / event.total
				);
				setProgressInfos({ val: _progressInfos });
			},
			props.currentUser.Data.access_token,
			props.params ? props.params : {},
			endPoint,
			fileApiKey
		)
			.then((res) => {
				if (res.data.Status) {
					if (Array.isArray(res.data.Data)) {
						setUploadResult(res.data.Data);
						props.handleSetFilesData &&
							props.handleSetFilesData(res.data.Data);
					} else {
						if (res.data.Data.Mission_Attach_File_Name) {
							const diffRes = {
								File_Name:
									res.data.Data.Mission_Attach_File_Name,
								Can_Delete: 1,
								Mission_Attach_Id:
									res.data.Data.Mission_Attach_Id,
								Mission_Attach_File:
									res.data.Data.Mission_Attach_File,
							};
							setUploadResult((prevResult) => {
								props.handleSetFilesData &&
									props.handleSetFilesData([
										...prevResult,
										diffRes,
									]);
								return [...prevResult, diffRes];
							});
						} else if (res.data.Data.Violation_Attachment_Id) {
							props.getUpdatedList();
							// setUploadResult(() => {
							//   return [
							//     res.data.Data
							//   ]
							// })
						} else if (res.data.Data.Invoice_Changes_Att_Data) {
							setUploadResult(() => {
								let data =
									res.data.Data.Invoice_Changes_Att_Data.map(
										(item) => {
											item.Can_Delete = 1;
											return item;
										}
									);

								props.handleSetFilesData &&
									props.handleSetFilesData(data);
								return data;
							});
						} else {
							setUploadResult((prevResult) => {
								props.handleSetFilesData &&
									props.handleSetFilesData([
										...prevResult,
										res.data.Data,
									]);
								return [...prevResult, res.data.Data];
							});
						}
					}
				}
			})
			.catch((error) => {
				_progressInfos[idx].percentage = 0;
				setProgressInfos({ val: _progressInfos });
				props.triggerNotification(true, error.response.data);
			});
	};

	const downloadAttachment = async ({
		File_Code,
		File_Name,
		Inspection_Template_File_Code,
		Violation_Attachment_File,
	}) => {
		let params = {
			at: props.currentUser.Data.access_token,
			File_Code: File_Code
				? File_Code
				: Violation_Attachment_File
				? Violation_Attachment_File
				: Inspection_Template_File_Code
				? Inspection_Template_File_Code
				: null,
		};

		params = props.downloadParams
			? { ...params, ...props.downloadParams }
			: params;

		if (props.downloadCB) {
			const downloadResult = await props.downloadCB(params);

			if (downloadResult.status === 200) {
				let contentType = downloadResult.headers["content-type"],
					typesArray = [
						"application/pdf",
						"image/bmp",
						"image/gif",
						"image/vnd.microsoft.icon",
						"image/jpeg",
						"image/png",
						"image/svg+xml",
						"image/tiff",
						"image/webp",
					],
					url =
						typeof downloadResult.data === String
							? window.URL.createObjectURL(
									new Blob(
										[
											base64ToArrayBuffer(
												downloadResult.data
											),
										],
										{
											type: contentType,
										}
									)
							  )
							: window.URL.createObjectURL(
									new Blob([downloadResult.data], {
										type: contentType,
									})
							  ),
					link = document.createElement("a");

				if (downloadResult?.data?.Data?.File_Bytes) {
					url = window.URL.createObjectURL(
						new Blob(
							[
								base64ToArrayBuffer(
									downloadResult.data.Data.File_Bytes
								),
							],
							{
								type: contentType,
							}
						)
					);

					File_Name = downloadResult.data.Data.File_Name;
				}

				link.href = url;

				typesArray.includes(contentType)
					? link.setAttribute("target", "_blank")
					: link.setAttribute("download", File_Name);

				document.body.appendChild(link);
				link.click();
			}
		}
	};

	const deleteTemplateAttachment = async (file) => {
		let at = props.currentUser.Data.access_token;

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DELETE_DEPARTMENT_SERVICE_TEMPLATE_FILE",
				{
					Department_Service_Version_Id:
						file.Department_Service_Version_Id,
					File_Code: file.File_Code
						? file.File_Code
						: file.Inspection_Template_File_Code,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});

		return response;
	};

	const handleCloseConfirmationDialog = () => {
		setConfirmationDialog((prev) => ({ ...prev, isOpen: !prev.isOpen }));
	};

	return (
		<div className="text-start">
			{/* Title */}
			{!props.view && (
				<div className={classes.uploadInput}>
					<ButtonSecondary
						color="primary"
						variant="contained"
						endIcon={<CloudUploadIcon />}
					>
						{props.t("select")}
						<input
							type="file"
							accept={props.acceptFiles ? props.acceptFiles : ""}
							multiple={props.single ? false : true}
							onChange={selectFiles}
						/>
					</ButtonSecondary>
				</div>
			)}

			{progressInfos &&
				progressInfos.val.length > 0 &&
				!props.view &&
				progressInfos.val.map((progressInfo, index) => (
					<div className="mb-2" key={index}>
						<span>{progressInfo.fileName}</span>
						<div className="progress">
							<div
								className={`progress-bar ${classes.progressBar}`}
								role="progressbar"
								aria-valuenow={progressInfo.percentage}
								aria-valuemin="0"
								aria-valuemax="100"
								style={{ width: progressInfo.percentage + "%" }}
							>
								{progressInfo.percentage}%
							</div>
						</div>
					</div>
				))}

			{uploadResult.length > 0 && (
				<div className={classes.root}>
					<List component="nav" disablePadding={true}>
						{uploadResult?.map((file, index) => {
							if (file == null) {
								return;
							}

							file.listsData = props.documentInfoListsData;
							return (
								<>
									<ListItem key={index}>
										{/* Icon  */}
										<ListItemAvatar>
											<CropOriginalIcon />
										</ListItemAvatar>

										{/* File Info */}
										<ListItemText
											primary={
												file.File_Name
													? file.File_Name
													: file.Inspection_Template_File_Name
													? file.Inspection_Template_File_Name
													: file.Violation_Attachment_File_Name
											}
											secondary={
												file.File_Size
													? file.File_Size
													: file.Inspection_Template_File_Size
											}
										/>

										{/* ///////////////////////// */}
										{props.hasInfo && (
											<DocumentInfoLists file={file} />
										)}
										{/* ///////////////////////// */}

										<ListItemSecondaryAction>
											{!file.Violation_Attachment_File_Name && (
												<IconButton
													edge="end"
													aria-label="download"
													onClick={() => {
														if (
															file.Mission_Attach_File
														) {
															window.open(
																file.Mission_Attach_File,
																"_blank",
																"noopener"
															);
														} else {
															downloadAttachment(
																file
															);
														}
													}}
												>
													<GetAppIcon />
												</IconButton>
											)}

											{(() => {
												if (
													file.Can_Delete &&
													file.Can_Delete === 1 &&
													!props.deleteParams
												) {
													return (
														<IconButton
															edge="end"
															aria-label="download"
															onClick={async () => {
																props.setLoading(
																	true,
																	"dark",
																	3000
																);
																const response =
																	await props
																		.deleteCB(
																			{
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																				...file,
																			}
																		)
																		.then(
																			(
																				result
																			) => {
																				if (
																					result
																						.data
																						.Status
																				) {
																					const updatedFilesList =
																						uploadResult.filter(
																							(
																								f
																							) => {
																								return (
																									file.File_Code !==
																									f.File_Code
																								);
																							}
																						);

																					setUploadResult(
																						updatedFilesList
																					);
																					props.handleSetFilesData &&
																						props.handleSetFilesData(
																							updatedFilesList
																						);
																				}
																				props.setLoading(
																					false
																				);
																			}
																		);
															}}
														>
															<DeleteIcon />
														</IconButton>
													);
												}
											})()}

											{file.Department_Service_Version_Id && (
												<IconButton
													edge="end"
													aria-label="delete"
													onClick={async () => {
														props.setLoading(
															true,
															"dark",
															3000
														);
														const response =
															await deleteTemplateAttachment(
																file
															).then((result) => {
																if (
																	result.data
																		.Status
																) {
																	props.toggle(
																		true
																	);
																	setUploadResult(
																		[]
																	);
																}
																props.setLoading(
																	false
																);
															});
													}}
												>
													<DeleteIcon />
												</IconButton>
											)}

											{file.Violation_Attachment_Id &&
												!props.view &&
												props.deleteViolation && (
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={async () => {
															props.setLoading(
																true,
																"dark",
																3000
															);
															const response =
																await props
																	.deleteViolation(
																		props
																			.currentUser
																			.Data
																			.access_token,
																		file.Violation_Attachment_Id
																	)
																	.then(
																		(
																			result
																		) => {
																			if (
																				result
																					.data
																					.Status
																			) {
																				props.getUpdatedList();
																			}
																			props.setLoading(
																				false
																			);
																		}
																	);
														}}
													>
														<DeleteIcon />
													</IconButton>
												)}

											{file.Violation_Attachment_Id && (
												<IconButton
													edge="end"
													aria-label="download"
													onClick={() => {
														downloadAttachment(
															file
														);
													}}
												>
													<GetAppIcon />
												</IconButton>
											)}

											{
												// load default delete button if no passed delete info
												(() => {
													if (
														!file.Can_Delete &&
														file.Can_Delete !== 0 &&
														file.Can_Delete !== 1 &&
														props.deleteParams
													) {
														return (
															<IconButton
																edge="end"
																aria-label="download"
																onClick={async () => {
																	let params =
																		{
																			...props.deleteParams,
																			...{
																				File_Code:
																					file.File_Code,
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																			},
																		};

																	props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await props
																			.deleteCB(
																				params
																			)
																			.then(
																				(
																					result
																				) => {
																					if (
																						result
																							.data
																							.Status
																					) {
																						const updatedFilesList =
																							uploadResult.filter(
																								(
																									f
																								) => {
																									return (
																										file.File_Code !==
																										f.File_Code
																									);
																								}
																							);

																						setUploadResult(
																							updatedFilesList
																						);
																						props.handleSetFilesData &&
																							props.handleSetFilesData(
																								updatedFilesList
																							);
																					}
																					props.setLoading(
																						false
																					);
																				}
																			);
																}}
															>
																<DeleteIcon />
															</IconButton>
														);
													}
												})()
											}

											{
												// delete later after revert what previouslly requested form yihya
												(() => {
													if (
														file.Can_Delete === 1 &&
														props.deleteParams
													) {
														return (
															<IconButton
																edge="end"
																aria-label="download"
																onClick={async () => {
																	let params =
																		{
																			...props.deleteParams,
																			...{
																				File_Code:
																					file.File_Code,
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																			},
																		};

																	props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await props
																			.deleteCB(
																				params
																			)
																			.then(
																				(
																					result
																				) => {
																					if (
																						result
																							.data
																							.Status
																					) {
																						const updatedFilesList =
																							uploadResult.filter(
																								(
																									f
																								) => {
																									return (
																										file.File_Code !==
																										f.File_Code
																									);
																								}
																							);

																						setUploadResult(
																							updatedFilesList
																						);
																						props.handleSetFilesData &&
																							props.handleSetFilesData(
																								updatedFilesList
																							);
																					}
																					props.setLoading(
																						false
																					);
																				}
																			);
																}}
															>
																<DeleteIcon />
															</IconButton>
														);
													}
												})()
											}

											{(() => {
												if (
													file.Can_Approve &&
													file.Can_Approve === 1
												) {
													return (
														<ButtonSecondary
															variant="outlined"
															onClick={async () => {
																const condition =
																	file[
																		`Document_Size_Id`
																	] ===
																		null &&
																	file[
																		`Document_Stamp_Type_Id`
																	] ===
																		null &&
																	file[
																		`Document_Type_Id`
																	] === null;

																const action =
																	async () => {
																		props.setLoading(
																			true,
																			"dark",
																			3000
																		);
																		const response =
																			await props.approveCB(
																				file
																			);
																		props.setLoading(
																			false
																		);
																		condition &&
																			handleCloseConfirmationDialog();
																	};

																if (condition) {
																	setConfirmationDialog(
																		{
																			isOpen: true,
																			title: props.t(
																				"youMayKnowYouDidnotChooseAnyAdditionalData"
																			),

																			closeButtonTitle:
																				props.t(
																					"close"
																				),
																			okButtonTitle:
																				props.t(
																					"accept"
																				),
																			actionCallback:
																				action,
																		}
																	);
																} else {
																	action();
																}
															}}
														>
															{props.t(
																"approveTemplate"
															)}
														</ButtonSecondary>
													);
												}
											})()}

											{(() => {
												if (
													file.Can_Unapprove &&
													file.Can_Unapprove === 1
												) {
													return (
														<ButtonSecondary
															variant="outlined"
															onClick={async () => {
																props.setLoading(
																	true,
																	"dark",
																	3000
																);
																const response =
																	await props.unApproveCB(
																		file
																	);
																props.setLoading(
																	false
																);
															}}
														>
															{props.t(
																"unApproveTemplate"
															)}
														</ButtonSecondary>
													);
												}
											})()}
										</ListItemSecondaryAction>

										<ListItemSecondaryAction>
											{!file.Violation_Attachment_File_Name && (
												<IconButton
													edge="end"
													aria-label="download"
													onClick={() => {
														if (
															file.Mission_Attach_File
														) {
															window.open(
																file.Mission_Attach_File,
																"_blank",
																"noopener"
															);
														} else {
															downloadAttachment(
																file
															);
														}
													}}
												>
													<GetAppIcon />
												</IconButton>
											)}

											{(() => {
												if (
													file.Can_Delete &&
													file.Can_Delete === 1 &&
													!props.deleteParams
												) {
													return (
														<IconButton
															edge="end"
															aria-label="download"
															onClick={async () => {
																props.setLoading(
																	true,
																	"dark",
																	3000
																);
																const response =
																	await props
																		.deleteCB(
																			{
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																				...file,
																			}
																		)
																		.then(
																			(
																				result
																			) => {
																				if (
																					result
																						.data
																						.Status
																				) {
																					const updatedFilesList =
																						uploadResult.filter(
																							(
																								f
																							) => {
																								return (
																									file.File_Code !==
																									f.File_Code
																								);
																							}
																						);

																					setUploadResult(
																						updatedFilesList
																					);
																					props.handleSetFilesData &&
																						props.handleSetFilesData(
																							updatedFilesList
																						);
																				}
																				props.setLoading(
																					false
																				);
																			}
																		);
															}}
														>
															<DeleteIcon />
														</IconButton>
													);
												}
											})()}

											{file.Department_Service_Version_Id && (
												<IconButton
													edge="end"
													aria-label="delete"
													onClick={async () => {
														props.setLoading(
															true,
															"dark",
															3000
														);
														const response =
															await deleteTemplateAttachment(
																file
															).then((result) => {
																if (
																	result.data
																		.Status
																) {
																	props.toggle(
																		true
																	);
																	setUploadResult(
																		[]
																	);
																}
																props.setLoading(
																	false
																);
															});
													}}
												>
													<DeleteIcon />
												</IconButton>
											)}

											{file.Violation_Attachment_Id &&
												!props.view &&
												props.deleteViolation && (
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={async () => {
															props.setLoading(
																true,
																"dark",
																3000
															);
															const response =
																await props
																	.deleteViolation(
																		props
																			.currentUser
																			.Data
																			.access_token,
																		file.Violation_Attachment_Id
																	)
																	.then(
																		(
																			result
																		) => {
																			if (
																				result
																					.data
																					.Status
																			) {
																				props.getUpdatedList();
																			}
																			props.setLoading(
																				false
																			);
																		}
																	);
														}}
													>
														<DeleteIcon />
													</IconButton>
												)}

											{file.Violation_Attachment_Id && (
												<IconButton
													edge="end"
													aria-label="download"
													onClick={() => {
														downloadAttachment(
															file
														);
													}}
												>
													<GetAppIcon />
												</IconButton>
											)}

											{
												// load default delete button if no passed delete info
												(() => {
													if (
														!file.Can_Delete &&
														file.Can_Delete !== 0 &&
														file.Can_Delete !== 1 &&
														props.deleteParams
													) {
														return (
															<IconButton
																edge="end"
																aria-label="download"
																onClick={async () => {
																	let params =
																		{
																			...props.deleteParams,
																			...{
																				File_Code:
																					file.File_Code,
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																			},
																		};

																	props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await props
																			.deleteCB(
																				params
																			)
																			.then(
																				(
																					result
																				) => {
																					if (
																						result
																							.data
																							.Status
																					) {
																						const updatedFilesList =
																							uploadResult.filter(
																								(
																									f
																								) => {
																									return (
																										file.File_Code !==
																										f.File_Code
																									);
																								}
																							);

																						setUploadResult(
																							updatedFilesList
																						);
																						props.handleSetFilesData &&
																							props.handleSetFilesData(
																								updatedFilesList
																							);
																					}
																					props.setLoading(
																						false
																					);
																				}
																			);
																}}
															>
																<DeleteIcon />
															</IconButton>
														);
													}
												})()
											}

											{
												// delete later after revert what previouslly requested form yihya
												(() => {
													if (
														file.Can_Delete === 1 &&
														props.deleteParams
													) {
														return (
															<IconButton
																edge="end"
																aria-label="download"
																onClick={async () => {
																	let params =
																		{
																			...props.deleteParams,
																			...{
																				File_Code:
																					file.File_Code,
																				at: props
																					.currentUser
																					.Data
																					.access_token,
																			},
																		};

																	props.setLoading(
																		true,
																		"dark",
																		3000
																	);
																	const response =
																		await props
																			.deleteCB(
																				params
																			)
																			.then(
																				(
																					result
																				) => {
																					if (
																						result
																							.data
																							.Status
																					) {
																						const updatedFilesList =
																							uploadResult.filter(
																								(
																									f
																								) => {
																									return (
																										file.File_Code !==
																										f.File_Code
																									);
																								}
																							);

																						setUploadResult(
																							updatedFilesList
																						);
																						props.handleSetFilesData &&
																							props.handleSetFilesData(
																								updatedFilesList
																							);
																					}
																					props.setLoading(
																						false
																					);
																				}
																			);
																}}
															>
																<DeleteIcon />
															</IconButton>
														);
													}
												})()
											}

											{(() => {
												if (
													file.Can_Approve &&
													file.Can_Approve === 1
												) {
													return (
														<ButtonSecondary
															variant="outlined"
															onClick={async () => {
																const condition =
																	file[
																		`Document_Size_Id`
																	] ===
																		null &&
																	file[
																		`Document_Stamp_Type_Id`
																	] ===
																		null &&
																	file[
																		`Document_Type_Id`
																	] === null;

																const action =
																	async () => {
																		props.setLoading(
																			true,
																			"dark",
																			3000
																		);
																		const response =
																			await props.approveCB(
																				file
																			);
																		props.setLoading(
																			false
																		);
																		condition &&
																			!props.view &&
																			handleCloseConfirmationDialog();
																	};

																if (
																	condition &&
																	!props.view
																) {
																	setConfirmationDialog(
																		{
																			isOpen: true,
																			title: props.t(
																				"youMayKnowYouDidnotChooseAnyAdditionalData"
																			),

																			closeButtonTitle:
																				props.t(
																					"close"
																				),
																			okButtonTitle:
																				props.t(
																					"accept"
																				),
																			actionCallback:
																				action,
																		}
																	);
																} else {
																	action();
																}
															}}
														>
															{props.t(
																"approveTemplate"
															)}
														</ButtonSecondary>
													);
												}
											})()}

											{(() => {
												if (
													file.Can_Unapprove &&
													file.Can_Unapprove === 1
												) {
													return (
														<ButtonSecondary
															variant="outlined"
															onClick={async () => {
																props.setLoading(
																	true,
																	"dark",
																	3000
																);
																const response =
																	await props.unApproveCB(
																		file
																	);
																props.setLoading(
																	false
																);
															}}
														>
															{props.t(
																"unApproveTemplate"
															)}
														</ButtonSecondary>
													);
												}
											})()}
										</ListItemSecondaryAction>
									</ListItem>

									<ConfirmationDialog
										isOpen={confirmationDialog.isOpen}
										handleClose={
											handleCloseConfirmationDialog
										}
										closeBtnTitle={
											confirmationDialog.closeButtonTitle
										}
										actionCallback={
											confirmationDialog.actionCallback
										}
										title={confirmationDialog.title}
										actionBtnTitle={
											confirmationDialog.okButtonTitle
										}
									/>
									<Divider />
								</>
							);
						})}
					</List>
				</div>
			)}

			{props.view && props.attachments && (
				<div>
					{props.attachments &&
						!props.chunk &&
						props.attachments.map((attachment, index) => (
							<>
								<div key={index} className="mt-2">
									{attachment.Attachment_Title_AR && (
										<h6>
											{props.i18n.language === "ar"
												? attachment.Attachment_Title_AR
												: attachment.Attachment_Title_EN}
										</h6>
									)}
									<div
										style={{ minWidth: "fit-content" }}
										className={`${classes.root} ${
											props.inline ? style.inlineList : ""
										}`}
									>
										<List
											component="nav"
											disablePadding={true}
										>
											{attachment.Files_Data &&
												attachment.Files_Data.map(
													(file, index) => {
														return (
															<>
																<ListItem
																	key={index}
																>
																	<ListItemAvatar>
																		<CropOriginalIcon />
																	</ListItemAvatar>
																	<ListItemText
																		// ref={textRef}
																		primary={
																			file.File_Name
																		}
																		secondary={
																			file.File_Size
																		}
																	/>

																	{/* ///////////////////////// */}
																	{props.hasInfo && (
																		<DocumentInfoLists
																			file={
																				file
																			}
																		/>
																	)}
																	{/* ///////////////////////// */}

																	<ListItemSecondaryAction>
																		<IconButton
																			edge="end"
																			aria-label="download"
																			onClick={() => {
																				downloadAttachment(
																					file
																				);
																			}}
																		>
																			<GetAppIcon />
																		</IconButton>

																		{(() => {
																			if (
																				file.Can_Delete &&
																				file.Can_Delete ===
																					1 &&
																				!props.deleteParams
																			) {
																				return (
																					<IconButton
																						edge="end"
																						aria-label="download"
																						onClick={() => {
																							props.deleteCB(
																								file
																							);
																						}}
																					>
																						<DeleteIcon />
																					</IconButton>
																				);
																			}
																		})()}

																		{(() => {
																			if (
																				file.Can_Approve &&
																				file.Can_Approve ===
																					1
																			) {
																				return (
																					<ButtonSecondary
																						variant="outlined"
																						onClick={() => {
																							const condition =
																								file[
																									`Document_Size_Id`
																								] ===
																									null &&
																								file[
																									`Document_Stamp_Type_Id`
																								] ===
																									null &&
																								file[
																									`Document_Type_Id`
																								] ===
																									null;

																							if (
																								condition
																							) {
																								setConfirmationDialog(
																									{
																										isOpen: true,
																										title: props.t(
																											"youMayKnowYouDidnotChooseAnyAdditionalData"
																										),

																										closeButtonTitle:
																											props.t(
																												"close"
																											),
																										okButtonTitle:
																											props.t(
																												"accept"
																											),
																										actionCallback:
																											() => {
																												props.approveCB(
																													file
																												);
																												handleCloseConfirmationDialog();
																											},
																									}
																								);
																							} else {
																								props.approveCB(
																									file
																								);
																							}
																						}}
																					>
																						{props.t(
																							"approveTemplate"
																						)}
																					</ButtonSecondary>
																				);
																			}
																		})()}

																		{(() => {
																			if (
																				file.Can_Unapprove &&
																				file.Can_Unapprove ===
																					1
																			) {
																				return (
																					<ButtonSecondary
																						variant="outlined"
																						onClick={() => {
																							props.unApproveCB(
																								file
																							);
																						}}
																					>
																						{props.t(
																							"unApproveTemplate"
																						)}
																					</ButtonSecondary>
																				);
																			}
																		})()}
																	</ListItemSecondaryAction>
																</ListItem>
																{!props.inline ? (
																	<Divider />
																) : null}
															</>
														);
													}
												)}
										</List>
									</div>
								</div>
							</>
						))}

					{props.attachments &&
						props.chunk &&
						_.chunk(props.attachments, 3).map((chunk, i) => (
							<>
								<div className="row" key={i}>
									{chunk.map((attachment, index) => (
										<div key={index} className="col-4 mt-2">
											<div
												className={[
													"d-flex flex-column",
													style.attacHeader,
												].join(" ")}
											>
												{!props.hideAttachmentListTitle &&
													attachment.Attachment_Title_AR && (
														<h6
															className={[
																attachment.Service_Detail_Changed
																	? style.attachTitleWithLink
																	: style.attachTitleWithoutLink,
															].join(" ")}
														>
															{props.i18n
																.language ===
															"ar"
																? attachment.Attachment_Title_AR
																: attachment.Attachment_Title_EN}
														</h6>
													)}

												{Boolean(
													attachment.Service_Detail_Changed
												) &&
													props.labelAction && (
														<a
															role="button"
															href="#"
															className={
																classes.labelAction
															}
															onClick={async (
																e
															) => {
																e.preventDefault();

																await props
																	.labelAction(
																		props.requestId,
																		attachment.Department_Service_Detail_Id
																	)
																	.then(
																		(
																			data
																		) => {
																			if (
																				data
																			) {
																				props.labelActionCB(
																					data
																				);
																			}
																		}
																	);
															}}
														>
															{props.t(
																"viewChangesList"
															)}
														</a>
													)}
											</div>
											<div className={classes.root}>
												<List
													component="nav"
													disablePadding={true}
												>
													{!attachment.Files_Data ||
														(attachment.Files_Data
															.length < 1 && (
															<div
																className="alert alert-danger text-center"
																role="alert"
															>
																{props.t(
																	"noDataFound"
																)}
															</div>
														))}

													{attachment.Files_Data &&
														attachment.Files_Data.map(
															(file, index) => (
																<>
																	<ListItem
																		key={
																			index
																		}
																	>
																		<ListItemAvatar>
																			<CropOriginalIcon />
																		</ListItemAvatar>
																		<ListItemText
																			primary={
																				file.File_Name
																			}
																			secondary={
																				file.File_Size
																			}
																		/>

																		<ListItemSecondaryAction>
																			<IconButton
																				edge="end"
																				aria-label="download"
																				onClick={() => {
																					downloadAttachment(
																						file
																					);
																				}}
																			>
																				<GetAppIcon />
																			</IconButton>

																			{(() => {
																				if (
																					file.Can_Delete &&
																					file.Can_Delete ===
																						1 &&
																					!props.deleteParams
																				) {
																					return (
																						<IconButton
																							edge="end"
																							aria-label="download"
																							onClick={() => {
																								props.deleteCB(
																									file
																								);
																							}}
																						>
																							<DeleteIcon />
																						</IconButton>
																					);
																				}
																			})()}

																			{(() => {
																				if (
																					file.Can_Approve &&
																					file.Can_Approve ===
																						1
																				) {
																					return (
																						<ButtonSecondary
																							variant="outlined"
																							onClick={() => {
																								const condition =
																									file[
																										`Document_Size_Id`
																									] ===
																										null &&
																									file[
																										`Document_Stamp_Type_Id`
																									] ===
																										null &&
																									file[
																										`Document_Type_Id`
																									] ===
																										null;

																								if (
																									condition
																								) {
																									setConfirmationDialog(
																										{
																											isOpen: true,
																											title: props.t(
																												"youMayKnowYouDidnotChooseAnyAdditionalData"
																											),
																											closeButtonTitle:
																												props.t(
																													"close"
																												),
																											okButtonTitle:
																												props.t(
																													"accept"
																												),
																											actionCallback:
																												() => {
																													props.approveCB(
																														file
																													);
																													handleCloseConfirmationDialog();
																												},
																										}
																									);
																								} else {
																									props.approveCB(
																										file
																									);
																								}
																							}}
																						>
																							{props.t(
																								"approveTemplate"
																							)}
																						</ButtonSecondary>
																					);
																				}
																			})()}

																			{(() => {
																				if (
																					file.Can_Unapprove &&
																					file.Can_Unapprove ===
																						1
																				) {
																					return (
																						<ButtonSecondary
																							variant="outlined"
																							onClick={() => {
																								props.unApproveCB(
																									file
																								);
																							}}
																						>
																							{props.t(
																								"unApproveTemplate"
																							)}
																						</ButtonSecondary>
																					);
																				}
																			})()}
																		</ListItemSecondaryAction>
																	</ListItem>
																	<Divider />
																</>
															)
														)}
												</List>
											</div>
										</div>
									))}
								</div>
							</>
						))}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	setLoading,
	triggerNotification,
})(translationHook(UploadFiles));
