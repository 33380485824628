import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { triggerNotification } from '../../actions'
import { translationHook } from '../translationHook'


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  message: {
    fontFamily: 'Cairo, sans-serif'
  }
}));

const Notification = (props) => {
  const classes = useStyles()
  // .MuiAlert-filledSuccess

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const dataClose = {
      Status: props.notification.success,
      Data: props.notification.messages ? props.notification.messages : null
    }
    props.triggerNotification(false , dataClose)
  };
  return (
    <div className={classes.root}>
      <Snackbar open={props.notification.Open} onClose={handleClose} autoHideDuration={props.notification.error ? 8000 : 2000}>
        <Alert onClose={handleClose} severity={props.notification.success ? 'success' : 'error'}>
          <div className={classes.message}>
            {props.notification.success &&
            <span>{props.i18n.language === 'ar' ? 'اكتملت العملية بنجاح' : 'process completed successfully'}</span>
            }

            {props.notification.error && !Array.isArray(props.notification.messages) &&
            <span>{props.notification.messages}</span>
            }

            {props.notification.error && Array.isArray(props.notification.messages) &&
            <div className="row">
              {
                props.notification.messages.map((message) => {
                  return (
                    <div className=
                    {props.notification.messages.length > 1 ? "col-6": "col-12"}>
                      <h6>{props.i18n.language === 'ar' ? message.Parameter_AR : message.Parameter_EN}</h6>
                      {
                        Array.isArray(message.Errors_AR) ? 
                          <>
                            <p>{props.i18n.language === 'ar' ? message.Errors_AR[0] : message.Errors_EN[0]}</p>
                            <p>{ message.Data ? message.Data :  message.Data }</p>
                          </>
                        :
                          <>
                            <span className="mx-2">{ message.Data ? message.Data :  message.Data }</span>
                            <span>{props.i18n.language === 'ar' ? message.Error_AR : message.Error_EN}</span>
                          </>
                      }
                    </div>
                  )
                })
              }
            </div>
            }
          </div>
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {notification: state.notification}
}

export default connect(mapStateToProps, { triggerNotification })(translationHook(Notification))