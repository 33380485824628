import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
	ButtonDanger,
	ButtonSecondary,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { translationHook } from "../translationHook";
import { getServiceDetailList } from "../../apis/services/getServiceDetailList";
import { deleteServiceDetail } from "../../apis/services/deleteServiceDetail";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
} from "../../actions";
import ServiceDetailForm from "./ServiceDetailForm";
import moment from "moment";

class ServiceDetail extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		reload: false,
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			serviceDetailid = data.Service_Detail_Id,
			serviceDetailName =
				this.props.i18n.language === "ar"
					? data.Service_Detail_Title_AR
					: data.Service_Detail_Title_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${serviceDetailName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteServiceDetail,
				params: [at, serviceDetailid],
				text: this.props.t("deleteServiceDetail"),
			},
		});
	};

	renderEditForm = (...params) => {
		return (
			<ServiceDetailForm
				serviceid={this.props.serviceid}
				reloadDatagrid={() =>
					this.setState({ reload: !this.state.reload })
				}
				editMood={this.props.editMood}
				viewMood={this.props.viewMood}
				serviceDetailId={params[0]}
				initValues={params[1]}
			/>
		);
	};

	handleEdit = (data) => {
		let initValues = {
				serviceDetailLabelEN: data.Service_Detail_Label_Text_EN,
				serviceDetailLabelAR: data.Service_Detail_Label_Text_AR,
				allowSymbols: data.Service_Detail_Allow_Symbols,
				serviceDetailFormId: data.Service_Detail_Form_Id,
				serviceDetailLangId: data.Service_Detail_Lang_Id ?? 0,
				serviceDetailFormatId: data.Service_Detail_Format_Id ?? 0,
				detailNameAR: data.Service_Detail_Title_AR,
				detailNameEN: data.Service_Detail_Title_EN,
				detailType: data.Service_Detail_Type_Id,
				usedServices: data.Service_Detail_API_Id,
				required: data.Service_Detail_Required ? true : false,
				serviceLookups: data.Service_Lookup_Id,
				predefinedField: data.Service_Detail_Predefined_Field_Id,
				Service_Detail_Relation_DropDown_Input_Id: data.Service_Detail_Relation_DropDown_Input_Id,
				fileType: data.Service_File_Content_Type_Id
					? data.Service_File_Content_Type_Id
					: null,
				fileExtension: data.Service_File_Extension
					? data.Service_File_Extension
					: null,
				allowOldDate: data.Service_Allow_Old_Date,
				checkboxDefault: data.Service_ChkBox_Status,
				templateCode: data.Service_Detail_Template_Code,
				Service_File_Signature_Attachment: data.Service_File_Signature_Attachment ? true : false,
				Service_File_Signature: data.Service_File_Signature ? true : false,
				Service_File_Max_Count: data.Service_File_Max_Count,
				Service_File_Max_Size: data.Service_File_Max_Size,
			},
			serviceDetailId = data.Service_Detail_Id,
			serviceDetailName =
				this.props.i18n.language === "ar"
					? data.Service_Detail_Title_AR
					: data.Service_Detail_Title_EN,
			dialogTitle = `${
				this.props.viewMood
					? this.props.t("viewServiceDetailTitle")
					: this.props.t("editServiceDetailTitle")
			} ${serviceDetailName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderEditForm,
			params: [serviceDetailId, initValues],
		});
	};

	setOrderUp = async (serviceDetailId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_ORDER_UP",
			{
				Service_Detail_Id: serviceDetailId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (serviceDetailId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_ORDER_DOWN",
			{
				Service_Detail_Id: serviceDetailId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() => this.setOrderUp(data.Service_Detail_Id)}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() => this.setOrderDown(data.Service_Detail_Id)}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			ServiceDetailTitleKey = "Service_Detail_Title_EN",
			ServiceDetailTypeKey = "Service_Detail_Type_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			ServiceDetailTitleKey = "Service_Detail_Title_AR";
			ServiceDetailTypeKey = "Service_Detail_Type_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Service_Detail_Id",
			load: async () => {
				return getServiceDetailList(at, this.props.serviceid);
			},
		});

		return (
			<Row>
				{!this.props.viewMood && (
					<Col xs={12} className="mb-5">
						<ServiceDetailForm
							serviceid={this.props.serviceid}
							reloadDatagrid={() =>
								this.setState({ reload: !this.state.reload })
							}
						/>
					</Col>
				)}

				<Col xs={12}>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/>

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							cssClass="outlinedColumn"
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
							alignment="center"
							width={"70px"}
							cellRender={this.renderOrderButtons}
						></Column>

						<Column
							dataField="Service_Detail_No"
							sortOrder="asc"
							caption={this.props.t("detailno")}
							alignment={"center"}
						></Column>

						<Column
							dataField={ServiceDetailTitleKey}
							caption={this.props.t("serviceDetailName")}
							alignment={columnAlign}
						></Column>

						<Column
							dataField={ServiceDetailTypeKey}
							caption={this.props.t("serviceDetailType")}
							alignment={columnAlign}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Service_Detail_Template_Code"
							caption={this.props.t("templateCode")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							{!this.props.viewMood && [
								<Button
									render={(record) => {
										return (
											<ButtonWarning
												variant="circleIcon"
												startIcon={<EditIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('edit')} */}
											</ButtonWarning>
										);
									}}
								/>,

								<Button
									render={(record) => {
										return (
											<ButtonDanger
												variant="circleIcon"
												startIcon={<DeleteIcon />}
												onClick={() =>
													this.handleDelete(
														record.data
													)
												}
											>
												{/* {this.props.t('delete')} */}
											</ButtonDanger>
										);
									}}
								/>,
							]}

							{this.props.viewMood && (
								<Button
									render={(record) => {
										return (
											<ButtonInfo
												variant="circleIcon"
												startIcon={<VisibilityIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('view')} */}
											</ButtonInfo>
										);
									}}
								/>
							)}
						</Column>
					</DataGrid>
				</Col>
			</Row>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("serviceDetails");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
})(translationHook(ServiceDetail));
