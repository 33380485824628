import apiConfig from "../../apiConfig";

export const deleteRequestTaskInterviewAttachment = async ({
	at,
	File_Code,
	Request_Task_Id,
	Request_Id,
}) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Delete_Request_Task_Interview_Attachment",
			{
				File_Code,
				Request_Task_Id,
				Request_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response;
};
