import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
	ButtonDanger,
	ButtonSecondary,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";

import { translationHook } from "../translationHook";

import RegistrationItemForm from "./RegistrationItemForm";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
} from "../../actions";

class RegistrationFinancialInvestorItems extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		reload: false,
	};

	handleDelete = async (data) => {
		let at = this.props.currentUser.Data.access_token;
		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/OFFERS/Del_License_Offer_Registration_Financial_Additional_Investor",
				{
					License_Offer_Registration_Id:
						data.License_Offer_Registration_Id,
					License_Offer_Registration_Financial_Id:
						data.License_Offer_Registration_Financial_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			this.props.reloadCB();
		}
	};

	onItemAdd = async (params) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");
		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/LICENSES/OFFERS/Add_License_Offer_Registration_Financial_Additional_Investor",
				params,
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});
		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);

		return response;
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "License_Offer_Registration_Financial_Id",
			load: async () => {
				return this.props.items;
			},
		});

		return (
			<Row
				style={{
					textAlign: "start",
					marginTop: "20px",
					marginBottom: "20px",
				}}
			>
				{!this.props.viewMood && (
					<>
						<Col xs={12}>
							<h5 className="mt-5 mb-1">
								{this.props.t(
									"registerationFinancialInvestorItems"
								)}
							</h5>
						</Col>
						<Col xs={12} className="mb-5">
							<RegistrationItemForm
								params={{
									License_Offer_Registration_Id:
										this.props.info
											.License_Offer_Registration_Id,
								}}
								onItemSubmitCB={this.onItemAdd}
								reloadCB={this.props.reloadCB}
								id="financialInvestorsForm"
							/>
						</Col>
					</>
				)}

				<Col xs={12}>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
					>
						{/* <ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/> */}

						{/* <Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/> */}

						<Column
							dataField={
								this.props.i18n.language === "ar"
									? "Invoice_Type_Name_AR"
									: "Invoice_Type_Name_EN"
							}
							caption={this.props.t("financialItem")}
							alignment={columnAlign}
						></Column>

						<Column
							dataField={
								this.props.i18n.language === "ar"
									? "License_Offer_Registration_Financial_Category_Name_AR"
									: "License_Offer_Registration_Financial_Category_Name_EN"
							}
							caption={this.props.t("licenseCat")}
							alignment={columnAlign}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Value"
							caption={this.props.t("value")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Discount_Rate"
							caption={this.props.t("discountPercentage")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Discount_Value"
							caption={this.props.t("discountValue")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Tax_Rate"
							caption={this.props.t("taxPercentage")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Tax_Value"
							caption={this.props.t("taxValue")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Invoice_Type_Net_Value"
							caption={this.props.t("netValue")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							{!this.props.viewMood && [
								<Button
									render={(record) => {
										return (
											<ButtonDanger
												variant="circleIcon"
												startIcon={<DeleteIcon />}
												onClick={() =>
													this.handleDelete(
														record.data
													)
												}
											>
												{/* {this.props.t('delete')} */}
											</ButtonDanger>
										);
									}}
								/>,
							]}
						</Column>
					</DataGrid>
				</Col>
			</Row>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("serviceDetails");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(RegistrationFinancialInvestorItems));
