import apiConfig from "../../apiConfig";

export const getLicensesOffersRegistrationInfo = async (
	at,
	registerationOfferId
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Get_License_Offer_Registration_Info",
		{ License_Offer_Registration_Id: registerationOfferId },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
