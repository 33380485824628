import React, { useState, useEffect } from "react";

import ApproveLicenseName from "./ApproveLicenseName";
import Notes from "../Notes";
import {
	escalateRequestStep,
	saveRequestLicenseNameSelection,
	sendRequestLicenseNameSelection,
} from "../../../../apis/requests/processTask";

export const ApproveLicenseNameStep = (props) => {
	const [licenseNameSelectionValues, setLicenseNameSelectionValues] =
		useState({});

	useEffect(() => {
		console.log(licenseNameSelectionValues);
	}, [licenseNameSelectionValues]);

	return (
		<>
			<ApproveLicenseName
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				setValuesCB={setLicenseNameSelectionValues}
			/>

			<Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				onTaskSubmit={(at, data) => {
					return saveRequestLicenseNameSelection(at, {
						...data,
						...licenseNameSelectionValues,
					});
				}}
				onSendNameToClient={(at, data) => {
					return sendRequestLicenseNameSelection(at, {
						...data,
						...licenseNameSelectionValues,
					});
				}}
				required={false}
			/>
		</>
	);
};
