import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";

class RegistrationItemForm extends Component {
	state = {
		showForm: false,
		itemTypesList: [],
		licenseTypesList: [],
		typeId: null,
		licenseTypeId: null,
		netValue: 0,
		value: 0,
		discountRate: 0,
		discountValue: 0,
		taxRate: 0,
		taxValue: 0,
	};

	handleItemTypeSelect = (e) => {
		this.setState({ typeId: e.target.value });
	};

	handleLicenseItemTypeSelect = (e) => {
		this.setState({ licenseTypeId: e.target.value });
	};

	handleValueChange = (e) => {
		let netValue = e.target.value;

		if (this.state.discountValue > 0) {
			netValue -= this.state.discountValue;
		}

		if (this.state.taxValue > 0) {
			netValue += this.state.taxValue;
		}

		this.setState({ netValue, value: e.target.value });
	};

	handleDiscountRateChange = (e) => {
		let discountRate = e.target.value,
			discountValue = (this.state.value * discountRate) / 100,
			netValue = this.state.value - discountValue;

		if (this.state.taxValue > 0) {
			netValue += this.state.taxValue;
		}

		this.setState({
			netValue,
			discountValue,
			discountRate: e.target.value,
		});
	};

	handleDiscountValueChange = (e) => {
		let discountValue = e.target.value,
			discountRate = (discountValue / this.state.value) * 100,
			netValue = this.state.value - discountValue;

		if (this.state.taxValue > 0) {
			netValue += this.state.taxValue;
		}

		this.setState({
			netValue,
			discountValue: e.target.value,
			discountRate,
		});
	};

	handleTaxRateChange = (e) => {
		let taxRate = e.target.value,
			taxValue =
				((this.state.value - this.state.discountValue) * taxRate) / 100,
			netValue = this.state.value - this.state.discountValue + taxValue;

		this.setState({
			netValue,
			taxValue,
			taxRate: e.target.value,
		});
	};

	handleTaxValueChange = (e) => {
		let taxValue = e.target.value,
			taxRate =
				(taxValue / (this.state.value - this.state.discountValue)) *
				100,
			netValue = this.state.value - this.state.discountValue + taxValue;

		this.setState({
			netValue,
			taxValue: e.target.value,
			taxRate,
		});
	};

	getItemTypesList = async () => {
		let typesArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_INVOICES_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({
				itemTypesList: [...typesArr],
			});
		}
	};

	getLicenseTypesList = async () => {
		let typesArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_LICENSES_OFFERS_REGISTRATION_FINANCIALS_CATEGORIES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Offer_Registration_Financial_Category_Name_AR
						: item.License_Offer_Registration_Financial_Category_Name_EN;

				typesArr.push({
					value: item.License_Offer_Registration_Financial_Category_Id,
					label,
				});
			});

			this.setState({
				licenseTypesList: [...typesArr],
			});
		}
	};

	validate = (values) => {
		const errors = {};

		// if (!values.License_Offer_Registration_Name_AR) {
		// 	errors.License_Offer_Registration_Name_AR = `${this.props.t(
		// 		"error.field_required"
		// 	)}`;
		// }

		// if (!values.License_Offer_Registration_Name_EN) {
		// 	errors.License_Offer_Registration_Name_EN = `${this.props.t(
		// 		"error.field_required"
		// 	)}`;
		// }

		// if (!values.License_Offer_Registration_Years) {
		// 	errors.License_Offer_Registration_Years = `${this.props.t(
		// 		"error.field_required"
		// 	)}`;
		// }

		return errors;
	};

	onPlanSubmit = async (values, form) => {
		let bodyData = {
			...this.props.params,
			...values,
			Invoice_Type_Net_Value: this.state.netValue,
		};

		const response = await this.props
			.onItemSubmitCB(bodyData)
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.reset();
			this.setState({
				showForm: false,
				typeId: null,
				licenseTypeId: null,
				netValue: 0,
				value: 0,
				discountRate: 0,
				discountValue: 0,
				taxRate: 0,
				taxValue: 0,
			});
			this.props.reloadCB();
		}
	};

	componentDidMount() {
		this.getItemTypesList();
		this.getLicenseTypesList();
	}

	render() {
		return (
			<>
				<Form
					// subscription={{ submitting: true }}
					onSubmit={this.onPlanSubmit}
					validate={this.validate}
					initialValues={{
						Invoice_Type_Id: this.state.typeId,
						License_Offer_Registration_Financial_Category_Id:
							this.state.licenseTypeId,
						Invoice_Type_Value: this.state.value,
						Invoice_Type_Discount_Rate: this.state.discountRate,
						Invoice_Type_Discount_Value: this.state.discountValue,
						Invoice_Type_Tax_Rate: this.state.taxRate,
						Invoice_Type_Tax_Value: this.state.taxValue,
						Invoice_Type_Net_Value: this.state.netValue,
					}}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						return (
							<>
								{!this.props.editMood &&
									!this.props.viewMood && (
										<div className="d-flex justify-content-end">
											<ButtonPrimary
												variant={
													!this.state.showForm
														? "contained"
														: "outlined"
												}
												onClick={() => {
													form.restart();
													this.setState({
														showForm:
															!this.state
																.showForm,
													});
												}}
												type="button"
											>
												{!this.state.showForm &&
													this.props.t("addNewItem")}
												{this.state.showForm &&
													this.props.t("cancel")}
											</ButtonPrimary>
										</div>
									)}
								<form
									id={this.props.id}
									className={`row ${
										!this.state.showForm &&
										!this.props.editMood &&
										!this.props.viewMood
											? "d-none"
											: ""
									}`}
									onSubmit={handleSubmit}
									noValidate
								>
									<Col xs={12} md={6}>
										<Select
											label={this.props.t("itemCat")}
											className="mb-2"
											name="Invoice_Type_Id"
											required={true}
											data={this.state.itemTypesList}
											value={this.state.typeId}
											onChange={(e) => {
												this.handleItemTypeSelect(e);
												form.change(
													"Invoice_Type_Id",
													e.target.value
												);
											}}
											showError={() => {
												let fieldStatus =
													form.getFieldState(
														"Invoice_Type_Id"
													);

												if (fieldStatus) {
													return fieldStatus.invalid;
												}
											}}
										></Select>
									</Col>

									<Col xs={12} md={6}>
										<Select
											label={this.props.t("licenseCat")}
											className="mb-2"
											name="License_Offer_Registration_Financial_Category_Id"
											required={true}
											data={this.state.licenseTypesList}
											value={this.state.licenseTypeId}
											onChange={(e) => {
												this.handleLicenseItemTypeSelect(
													e
												);
												form.change(
													"License_Offer_Registration_Financial_Category_Id",
													e.target.value
												);
											}}
											showError={() => {
												let fieldStatus =
													form.getFieldState(
														"License_Offer_Registration_Financial_Category_Id"
													);

												if (fieldStatus) {
													return fieldStatus.invalid;
												}
											}}
										></Select>
									</Col>

									<Col xs={12} md={2}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t("invoiceValue")}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Value"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.value}
											onChange={(e) => {
												this.handleValueChange(e);
												form.change(
													"Invoice_Type_Value",
													e.target.value
												);
											}}
										/>
									</Col>

									<Col xs={12} md={2}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"discountPercentage"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Discount_Rate"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.discountRate}
											onChange={(e) => {
												form.change(
													"Invoice_Type_Discount_Rate",
													e.target.value
												);
												this.handleDiscountRateChange(
													e
												);
											}}
										/>
									</Col>

									<Col xs={12} md={2}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"discountValue"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Discount_Value"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.discountValue}
											onChange={(e) => {
												form.change(
													"Invoice_Type_Discount_Value",
													e.target.value
												);
												this.handleDiscountValueChange(
													e
												);
											}}
										/>
									</Col>

									<Col xs={12} md={2}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t(
												"taxPercentage"
											)}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Tax_Rate"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.taxRate}
											onChange={(e) => {
												this.handleTaxRateChange(e);
												form.change(
													"Invoice_Type_Tax_Rate",
													e.target.value
												);
											}}
										/>
									</Col>

									<Col xs={12} md={2}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t("taxValue")}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Tax_Value"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.taxValue}
											onChange={(e) => {
												this.handleTaxValueChange(e);
												form.change(
													"Invoice_Type_Tax_Value",
													e.target.value
												);
											}}
										/>
									</Col>

									<Col xs={12} md={12}>
										<TextField
											disabled={true}
											label={this.props.t("netValue")}
											className={`${
												this.props.viewMood
													? "read-only"
													: ""
											} mb-2`}
											required={true}
											name="Invoice_Type_Net_Value"
											autoComplete="off"
											showError={({
												meta: { submitFailed, invalid },
											}) => {
												return submitFailed && invalid;
											}}
											type="number"
											value={this.state.netValue}
										/>
									</Col>

									<Col className="mt-4" xs={12}>
										<Row className="align-items-baseline">
											<Col className="mt-2 submit-btn-wrap">
												{!this.props.viewMood && (
													<ButtonPrimary
														color="primary"
														variant="contained"
														disabled={
															submitting ||
															this.props.viewMood
														}
														type="submit"
													>
														{this.props.t("save")}
													</ButtonPrimary>
												)}
											</Col>
										</Row>
									</Col>
								</form>
							</>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(RegistrationItemForm));
