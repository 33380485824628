import apiConfig from "../../apiConfig";

export const deleteLicenseForbiddenName = async (at, licenseForbiddenNameId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Del_License_Forbidden_Name",
		{
			License_Forbidden_Name_Id: licenseForbiddenNameId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
