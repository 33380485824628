import apiConfig from "../../apiConfig";

export const getLicendeTradeNameList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Get_Licenses_Trademark_Names_List",
		{
			PageSize: 100000,
			PageIndex: 1
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};
