import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
} from "../../actions";
import { getLicensesOffersList } from "../../apis/licenses/licensesOffers/getLicensesOffersList";
import { deleteOffer } from "../../apis/licenses/licensesOffers/deleteOffer";
import { setOfferStatus } from "../../apis/licenses/licensesOffers/setOfferStatus";
import { translationHook } from "../translationHook";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";

class LicenseOffersList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			offerId = data.License_Offer_Id,
			offerName =
				this.props.i18n.language === "ar"
					? data.License_Offer_Name_AR
					: data.License_Offer_Name_EN,
			dialogTitle = `${this.props.t("confirmDeleteTitle")} ${offerName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteOffer,
				params: [at, offerId],
				text: this.props.t("deleteOffer"),
			},
		});
	};

	setOrderUp = async (offerId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Order_Up",
			{
				License_Offer_Id: offerId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (offerId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Order_Down",
			{
				License_Offer_Id: offerId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() => this.setOrderUp(data.License_Offer_Id)}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() => this.setOrderDown(data.License_Offer_Id)}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	customizeCellCheckbox = ({ data }) => {
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id}
						name={`offerStatus_${data.License_Offer_Id}`}
						onChange={async () => {
							let at = this.props.currentUser.Data.access_token,
								offerId = data.License_Offer_Id,
								status = data.Active_Status_Id ? 0 : 1;

							this.props.setLoading(true, "dark");

							const response = await setOfferStatus(
								at,
								offerId,
								status
							);

							this.props.triggerNotification(true, response);

							this.props.setLoading(false);
						}}
					/>
				}
			/>
		);
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "License_Offer_Id",
			load: () => {
				return getLicensesOffersList(at);
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t("manageLicenseOffers")}</h3>
					<ButtonPrimary component={Link} to="add-offer" size="large">
						{this.props.t("addOffer")}
					</ButtonPrimary>
				</div>

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						cssClass="outlinedColumn"
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "right" : "left"
						}
						alignment="center"
						cellRender={this.renderOrderButtons}
						width={"70px"}
					></Column>

					<Column
						dataField="License_Offer_No"
						sortOrder="asc"
						caption={this.props.t("offerNo")}
						alignment={"center"}
						width={"120px"}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "ar"
								? "License_Offer_Name_AR"
								: "License_Offer_Name_EN"
						}
						caption={this.props.t("offerName")}
						alignment={columnAlign}
					></Column>

					<Column
						cssClass="outlinedColumn"
						dataField="License_Offer_Price"
						caption={this.props.t("offerValue")}
						alignment={"center"}
					></Column>

					<Column
						dataField="License_Offer_Visas_Count"
						caption={this.props.t("staysNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Offer_Investors_Count"
						caption={this.props.t("investorsNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Offer_Activities_Count"
						caption={this.props.t("activitiesNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Offer_Investors_Max_Count"
						caption={this.props.t("maxInvestorsNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="License_Offer_Activities_Max_Count"
						caption={this.props.t("maxActivitiesNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Active_Status_Id"
						caption={this.props.t("serviceStatus")}
						allowFiltering={false}
						alignment={"center"}
						width={"100px"}
						cellRender={this.customizeCellCheckbox}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										component={Link}
										state={{ serviceInfo: record.data }}
										to={`edit-offer/${record.data.License_Offer_Id}`}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										component={Link}
										state={{ serviceInfo: record.data }}
										to={`edit-offer/${record.data.License_Offer_Id}/#view`}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServices");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(LicenseOffersList));
