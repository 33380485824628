import apiConfig from "../apiConfig";

export const deleteServiceDetailForm = async (...params) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/SERVICES/DEL_SERVICE_DETAIL_FORM",
		{
			Service_Detail_Form_Id: params[1],
		},
		{
			headers: {
				Authorization: `Bearer ${params[0]}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
