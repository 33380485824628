import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import style from "./Processing.module.scss";
import ApplicantInfo from "../info/ApplicantInfo";
import RequestInfo from "../info/RequestInfo";
import RequestAdditionalInfo from "../info/RequestAdditionalInfo";
import { StepsNav, StepsTab } from "../info/Steps";
import ProcessingActions from "./ProcessingActions";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import { translationHook } from "../../translationHook";
import { getRequestManagmentInfo } from "../../../apis/requests/info/getRequestManagmentInfo";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import { getRequestDetails } from "../../../apis/requests/getRequestDetails";
import { triggerNotification, triggerDialogForm } from "../../../actions";
import imgLight from "../../../images/ShMunAnimatedGif-inside.gif";
import imgLightStrip from "../../../imagesTemp/STRIPAnimatGif-ForceWhite.gif";
import { ButtonPrimary } from "../../UI/Buttons";
import AccountForm from "../../system/accounts/AccountForm";

class Processing extends Component {
	state = {
		key: 0,
		next: 1,
		prev: -1,
		requestInfo: {
			info: null,
			data: [],
			attachments: [],
		},
		requestSteps: [],
		requestAdditionalInfo: {
			notes: null,
			attachments: [],
			invoices: [],
			documentsFiles: [],
			templatesFiles: [],
			actions: [],
		},
		requestBtns: {},
		reuestSubmissionInfo: {},
		requestClientData: null,
		loading: false,
		showAdditionalData: false,
		// taskId: undefined,
	};

	processingLoader = (state) => {
		this.setState({ loading: state });
	};

	cachRequestAdditionalInfo = (requestAdditionalInfo) => {
		this.setState({ requestAdditionalInfo });
	};

	cachRequestSteps = (requestStep) => {
		if (!requestStep) {
			return;
		}

		let cachedSteps = this.state.requestSteps;

		_.forEach(this.state.requestSteps, (step, index) => {
			if (step.Step_Id === requestStep.Step_Id) {
				cachedSteps[index] = requestStep;

				this.setState({ requestSteps: cachedSteps });

				return false;
			}
		});
	};

	loadRequestInfos = async () => {
		this.setState({ key: 0, loading: true });

		// if (taskId) {
		// 	this.setState({ taskId });
		// }

		let isViewMode = this.props.location?.state?.viewMood
			? this.props.location?.state?.viewMood
			: this.props.dataListObject?.viewMood;
		if (isViewMode) {
			(async () => {
				await getRequestDetails(
					this.props.currentUser.Data.access_token,
					this.props.location?.state?.requestId
						? this.props.location?.state?.requestId
						: this.props.dataListObject?.requestId,
					this.props.location?.state?.requestTaskId
						? this.props.location?.state?.requestTaskId
						: this.props.dataListObject?.requestTaskId
				)
					.then((data) => {
						if (!data.data.Status) {
							throw data.data;
						}

						this.setState({
							requestInfo: {
								info: data.data.Data.Request_Info,
								data: data.data.Data.Request_Data,
								attachments: data.data.Data.Request_Attachments,
							},
							requestClientData: data.data.Data.Client_Data,
							requestBtns:
								data.data.Data.Request_Task_Buttons_Info,
							requestSteps: data.data.Data.Request_Steps,
							requestAdditionalInfo: {
								notes:
									data.data.Data?.Request_Additional_Data
										?.Request_Additional_Notes ?? null,
								attachments:
									data.data.Data?.Request_Additional_Data
										?.Request_Additional_Attachments ?? [],
								invoices:
									data.data.Data?.Request_Additional_Data
										?.Request_Invoices ?? [],
								documentsFiles:
									data.data.Data?.Request_Additional_Data
										?.Request_Results_Documents_Files ?? [],
								templatesFiles:
									data.data.Data?.Request_Additional_Data
										?.Request_Results_Templates_Files ?? [],
								actions:
									data.data.Data?.Request_Additional_Data
										?.Request_Actions ?? [],
							},
							reuestSubmissionInfo: {
								requestSubmissionNotes:
									data.data.Data?.Request_Submission_Notes,
								requestSubmissionFiles:
									data.data.Data?.Request_Submission_Files,
							},
							showAdditionalData:
								!!data.data.Data.Request_Additional_Data,
						});
					})
					.catch((err) => {
						this.props.triggerNotification(true, err);
					});

				this.processingLoader(false);
			})();
		} else {
			(async () => {
				await getRequestManagmentInfo(
					this.props.currentUser.Data.access_token,

					this.props.location?.state?.requestId
						? this.props.location?.state?.requestId
						: this.props.dataListObject?.requestId,
					this.props.location?.state?.requestTaskId
						? this.props.location?.state?.requestTaskId
						: this.props.dataListObject?.requestTaskId
				)
					.then((data) => {
						if (!data.data.Status) {
							throw data.data;
						}

						this.setState({
							requestBtns:
								data.data.Data.Request_Task_Buttons_Info,
							requestSteps: data.data.Data.Request_Steps,
							requestClientData: data.data.Data.Client_Data,
							requestInfo: {
								info: data.data.Data.Request_Info,
								data: data.data.Data.Request_Data,
								attachments: data.data.Data.Request_Attachments,
							},
							reuestSubmissionInfo: {
								requestSubmissionNotes:
									data.data.Data?.Request_Submission_Notes,
								requestSubmissionFiles:
									data.data.Data?.Request_Submission_Files,
							},
						});
					})
					// .then(async () => {
					// 	const clientData = await getRequestManagmentInfo(
					// 		this.props.currentUser.Data.access_token,
					// 		this.props.location?.state?.requestId,
					// 		2
					// 	);

					// 	return clientData;
					// })
					// .then((clientResponse) => {
					// 	if (!clientResponse.data.Status) {
					// 		throw clientResponse.data;
					// 	}

					// 	this.setState({
					// 		requestClientData:
					// 			clientResponse.data.Data.Client_Data,
					// 	});
					// })
					// .then(async () => {
					// 	const requestInfoData = await getRequestManagmentInfo(
					// 		this.props.currentUser.Data.access_token,
					// 		this.props.location?.state?.requestId,
					// 		3
					// 	);

					// 	return requestInfoData;
					// })
					// .then((requestInfoResponse) => {
					// 	if (!requestInfoResponse.data.Status) {
					// 		throw requestInfoResponse.data;
					// 	}

					// 	this.setState({
					// 		requestInfo: {
					// 			info: requestInfoResponse.data.Data
					// 				.Request_Info,
					// 			data: requestInfoResponse.data.Data
					// 				.Request_Data,
					// 			attachments:
					// 				requestInfoResponse.data.Data
					// 					.Request_Attachments,
					// 		},
					// 	});
					// })
					.catch((err) => {
						this.props.triggerNotification(true, err);
					});

				this.processingLoader(false);
			})();
		}
	};

	handleNext = () => {
		this.setState({
			key: this.state.next,
			next: this.state.next + 1,
			prev: this.state.prev + 1,
		});
	};

	handlePrev = () => {
		this.setState({
			key: this.state.prev,
			next: this.state.next - 1,
			prev: this.state.prev - 1,
		});
	};

	renderChevs = () => {
		let rtl = this.props.i18n.language === "ar" ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label="prev"
					color="primary"
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}
				>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label="next"
					color="primary"
					onClick={this.handleNext}
					disabled={
						this.state.key < 3 && !this.state.second ? false : true
					}
				>
					{iconNext}
				</IconButton>
			</>
		);
	};

	componentDidMount() {
		this.loadRequestInfos();
	}

	componentDidUpdate(prevProps) {
		let requestTaskId = this.props.location?.state?.requestTaskId
			? this.props.location?.state?.requestTaskId
			: this.props.dataListObject?.requestTaskId;

		let prev = prevProps.location?.state?.requestTaskId
			? prevProps.location?.state?.requestTaskId
			: prevProps.dataListObject?.requestTaskId;
		if (requestTaskId !== prev) {
			this.loadRequestInfos();
		}
	}

	renderShowDetailsForm = (...params) => {
		let viewMood = true;
		return (
			<AccountForm
				accountId={params[0]}
				initValues={params[1]}
				viewMood={viewMood}
				showAccountProcessButton={false}
			/>
		);
	};

	renderCurrentDialog = (params) => {
		return (
			<div
				className={style.dashboardListContainer}
				style={{
					padding: params.viewMood
						? "7rem 2rem 0 2rem"
						: "7rem 2rem 0 2rem",
				}}
			>
				<Processing
					i18n={this.props.i18n}
					t={this.props.t}
					currentUser={this.props.currentUser}
					currentDialogData={this.props.currentDialogData}
					triggerDialogForm={this.props.triggerDialogForm}
					triggerNotification={this.props.triggerNotification}
					dataListObject={params}
				/>
			</div>
		);
	};

	renderShowDetailsButton = (title, id, name) => {
		return (
			<ButtonPrimary
				variant={"contained"}
				onClick={() => {
					let initValues = this.state.requestClientData;
					let accountId = id;
					let accountTitle = name;

					this.props.triggerDialogForm(true, {
						title: `${this.props.t("view")}  ${accountTitle}`,
						closeBtnTitle: this.props.t("back"),
						closeForm: false,
						action: () => {
							this.props.triggerDialogForm(true, {
								title: this.props.currentDialogData.title,
								closeBtnTitle:
									window.location.href.indexOf(
										"servicies-requests"
									) > 0
										? this.props.t("closeAndReturnToMenu")
										: this.props.t("cancelToDashboard"),
								renderForm: this.renderCurrentDialog,
								params: this.props.currentDialogData.params,
							});
						},
						renderForm: this.renderShowDetailsForm,
						params: [accountId, initValues],
					});
				}}
			>
				{this.props.t(title)}
			</ButtonPrimary>
		);
	};

	render() {
		let mystyle;
		let tabConStyle;
		let actWrap;

		if (this.props.i18n.language === "ar") {
			mystyle = "ServiceTabsAr";
			tabConStyle = "tabConStyleAr";
			actWrap = "actionsWrapAr";
		} else {
			mystyle = "ServiceTabsEn";
			tabConStyle = "tabConStyleEn";
			actWrap = "actionsWrapEn";
		}

		return (
			<>
				<div
					className={`${style.actionsWrap} ${actWrap} d-flex align-items-center`}
				>
					<ProcessingActions
						btns={this.state.requestBtns}
						requestStatusId={
							this.props.location?.state?.requestStatusId
								? this.props.location?.state?.requestStatusId
								: this.props.dataListObject?.requestStatusId
						}
						viewMood={
							this.props.location?.state?.viewMood
								? this.props.location?.state?.viewMood
								: this.props.dataListObject?.viewMood
						}
						isRequestsList={
							this.props.location?.state?.isRequestsList
								? this.props.location?.state?.isRequestsList
								: "hide"
						}
						requestId={
							this.props.location?.state?.requestId
								? this.props.location?.state?.requestId
								: this.props.dataListObject?.requestId
						}
						requestTaskId={
							this.props.location?.state?.requestTaskId
								? this.props.location?.state?.requestTaskId
								: this.props.dataListObject?.requestTaskId
						}
						refetchData={this.loadRequestInfos}
					/>
				</div>

				<Tab.Container
					id="left-tabs-example"
					defaultActiveKey={0}
					activeKey={this.state.key}
					onSelect={(k) =>
						this.setState({
							key: parseInt(k),
							next: parseInt(k) + 1,
							prev: parseInt(k) - 1,
						})
					}
				>
					<Row>
						<Col
							sm={2}
							style={{
								overflowY:
									window.location.pathname === "/"
										? "unset"
										: "scroll",
							}}
							className={`${tabConStyle} d-flex justify-content-start align-items-start position-fixed`}
						>
							<Nav variant="pills" className={style.stepsNav}>
								{/* <Nav.Item className={mystyle}>
									<Nav.Link
										className={`${style.navLink} ${
											this.state.key === 0
												? style.active
												: ""
										}`}
										eventKey="0"
										disabled={this.state.first}
									>
										{this.props.t("applicantInfo")}
									</Nav.Link>
								</Nav.Item> */}

								<Nav.Item className={mystyle}>
									<Nav.Link
										className={`${style.navLink} ${style.processingNavLinkDone}`}
										eventKey="0"
										style={{
											textAlign:
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left",
										}}
										disabled={this.state.second}
									>
										{this.props.t("requestInfo")}
									</Nav.Link>
								</Nav.Item>

								{/* {this.props.location?.state?.viewMood &&
									(this.state.requestAdditionalInfo.notes ||
									this.state.requestAdditionalInfo?.attachments?.length > 0 || 
									this.state.requestAdditionalInfo?.invoices?.length > 0 ||
									this.state.requestAdditionalInfo?.documentsFiles?.length > 0 || 
									this.state.requestAdditionalInfo?.templatesFiles?.length > 0) && ( */}
								<Nav.Item
									className={mystyle}
									hidden={!this.state.showAdditionalData}
								>
									<Nav.Link
										className={`${style.navLink} ${style.processingNavLinkDone}`}
										style={{
											textAlign:
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left",
										}}
										eventKey="1"
										disabled={this.state.third}
									>
										{this.props.t(
											"addAdditionalAttatchment"
										)}
									</Nav.Link>
								</Nav.Item>
								{/* )
								} */}

								<StepsNav steps={this.state.requestSteps} />
							</Nav>
						</Col>

						<Col sm={10} className="pt-5 mt-2">
							{this.state.loading && (
								<div className={style.processingLoader}>
									<img
										src={
											process.env.REACT_APP_IMG_SRC == 2
												? imgLightStrip
												: imgLight
										}
										alt="spinner"
									/>
								</div>
							)}

							<Tab.Content style={{ textAlign: "start" }}>
								{/* <Tab.Pane eventKey={0}>
									{this.state.requestClientData && (
										<ApplicantInfo
											info={this.state.requestClientData}
										/>
									)}
								</Tab.Pane> */}
								<Tab.Pane eventKey={0}>
									<RequestInfo
										info={this.state.requestInfo.info}
										data={this.state.requestInfo.data}
										attachments={
											this.state.requestInfo.attachments
										}
										reuestSubmissionInfo={
											this.state.reuestSubmissionInfo
										}
										requestInfoButtonCallback={(
											title,
											id,
											name
										) =>
											this.renderShowDetailsButton(
												title,
												id,
												name
											)
										}
									>
										<ApplicantInfo
											info={this.state.requestClientData}
										/>
										{/* {this.renderShowDetailsButton(
											"showApplicantInfoDataDetails",
											this.state.requestClientData
												?.Client_Profile_Id,
											this.props.i18n.language === "ar"
												? `حساب ${this.state.requestClientData?.Client_Name_AR}`
												: `${this.state.requestClientData?.Client_Name_EN} Acconut`
										)} */}
									</RequestInfo>
								</Tab.Pane>
								{/* {this.props.location?.state?.viewMood &&
									(this.state.requestAdditionalInfo.notes ||
									this.state.requestAdditionalInfo?.attachments?.length > 0 || 
									this.state.requestAdditionalInfo?.invoices?.length > 0 ||
									this.state.requestAdditionalInfo?.documentsFiles?.length > 0 || 
									this.state.requestAdditionalInfo?.templatesFiles?.length > 0) && ( */}
								<Tab.Pane eventKey={1} unmountOnExit={true}>
									<RequestAdditionalInfo
										// notes={this.state.requestAdditionalInfo.notes}
										// attachments={this.state.requestAdditionalInfo.attachments}
										// actions={this.state.requestAdditionalInfo.actions}
										// documents={this.state.requestAdditionalInfo.documentsFiles}
										// templates={this.state.requestAdditionalInfo.templatesFiles}
										info={this.state.requestAdditionalInfo}
										cachRequestAdditionalInfoCB={
											this.cachRequestAdditionalInfo
										}
										requestId={
											this.props.location?.state
												?.requestId
												? this.props.location?.state
														?.requestId
												: this.props.dataListObject
														?.requestId
										}
									/>
								</Tab.Pane>
								{/* )
								} */}
								{this.props.location?.state?.requestId ? (
									<StepsTab
										steps={this.state.requestSteps}
										requestId={
											this.props.location?.state
												?.requestId
												? this.props.location?.state
														?.requestId
												: this.props.dataListObject
														?.requestId
										}
										cachRequestStepsCB={
											this.cachRequestSteps
										}
										activeTab={this.state.key}
										triggerLoader={this.processingLoader}
									/>
								) : (
									//
									<div
										style={{
											textAlign: "start",
										}}
										className="m-4"
									>
										<StepsTab
											steps={this.state.requestSteps}
											requestId={
												this.props.location?.state
													?.requestId
													? this.props.location?.state
															?.requestId
													: this.props.dataListObject
															?.requestId
											}
											cachRequestStepsCB={
												this.cachRequestSteps
											}
											activeTab={this.state.key}
											triggerLoader={
												this.processingLoader
											}
										/>
									</div>
								)}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		currentDialogData: state.currentDialogData.Data,
	};
};

export default connect(mapStateToProps, {
	triggerNotification,
	triggerDialogForm,
})(translationHook(Processing));
