import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../actions";

class ServiceApplicationStepForm extends Component {
	state = {
		showForm: false,
	};

	validate = (values) => {
		const errors = {};

		if (!values.detailNameAR) {
			errors.detailNameAR = `${this.props.t("error.field_required")}`;
		}

		if (!values.detailNameEN) {
			errors.detailNameEN = `${this.props.t("error.field_required")}`;
		}

		return errors;
	};

	onServiceDetailSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/SERVICES/ADD_SERVICE_DETAIL_FORM"
				: "/API/ADMINISTRATION/SERVICES/UPD_SERVICE_DETAIL_FORM";

		let bodyData = {
			Service_Detail_Form_Title_AR: values.detailNameAR,
			Service_Detail_Form_Title_EN: values.detailNameEN,
		};

		if (!this.props.editMood) {
			bodyData.Service_Id = this.props.serviceid;
		} else {
			bodyData.Service_Detail_Form_Id = this.props.serviceDetailId;
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			if (this.props.editMood) {
				this.props.triggerDialogForm(false);
			}
			this.props.reloadDatagrid();
			form.restart();
		}
	};

	render() {
		let { initValues } = this.props;

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-end">
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addNewStep")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}
				<Form
					onSubmit={this.onServiceDetailSubmit}
					initialValues={
						initValues
							? initValues
							: {
									detailNameAR: "",
									detailNameEN: "",
							  }
					}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						return (
							<form
								id="serviceDetailForm"
								className={`row ${
									!this.state.showForm &&
									!this.props.editMood &&
									!this.props.viewMood
										? "d-none"
										: ""
								}`}
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("stepNameAR")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="detailNameAR"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("stepNameEN")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="detailNameEN"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col className="mt-4" xs={12}>
									<Row className="align-items-baseline">
										<Col className="mt-2 submit-btn-wrap">
											{!this.props.viewMood && (
												<ButtonPrimary
													color="primary"
													variant="contained"
													disabled={
														submitting ||
														this.props.viewMood
													}
													type="submit"
												>
													{this.props.t("saveStep")}
												</ButtonPrimary>
											)}
										</Col>
									</Row>
								</Col>
							</form>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ServiceApplicationStepForm));
