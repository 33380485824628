import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
	ButtonDanger,
	ButtonSecondary,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { translationHook } from "../translationHook";

import { getLicensesOffersRegistrationList } from "../../apis/licenses/licensesOffers/getLicensesOffersRegistrationList";
import { setRegistrationOfferStatus } from "../../apis/licenses/licensesOffers/setRegistrationOfferStatus";
import { deleteRegistrationOffer } from "../../apis/licenses/licensesOffers/deleteRegistrationOffer";
import { getLicensesOffersRegistrationInfo } from "../../apis/licenses/licensesOffers/getLicensesOffersRegistrationInfo";
import RegistrationInfoForm from "./RegistrationInfoForm";
import RegistrationInfoEditForm from "./RegistrationInfoEditForm";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
} from "../../actions";

class RegistrationInfo extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		reload: false,
	};

	customizeCellCheckbox = ({ data }) => {
		return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id}
						name={`offerStatus_${data.License_Offer_Registration_Id}`}
						onChange={async () => {
							let at = this.props.currentUser.Data.access_token,
								registerationOfferId =
									data.License_Offer_Registration_Id,
								status = data.Active_Status_Id ? 0 : 1;
							this.props.setLoading(true, "dark");
							const response = await setRegistrationOfferStatus(
								at,
								registerationOfferId,
								status
							);
							this.props.triggerNotification(true, response);
							this.props.setLoading(false);
						}}
					/>
				}
			/>
		);
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			registerationOfferId = data.License_Offer_Registration_Id,
			registrationOfferName =
				this.props.i18n.language === "ar"
					? data.License_Offer_Name_AR
					: data.License_Offer_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${registrationOfferName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteRegistrationOffer,
				params: [at, registerationOfferId],
				text: this.props.t("deleteRegistrationOffer"),
			},
		});
	};

	renderEditForm = (...params) => {
		return (
			<div className="container pt-5">
				<RegistrationInfoEditForm
					editMood={this.props.editMood}
					viewMood={this.props.viewMood}
					planData={params[0]}
				/>
			</div>
		);
	};

	handleEdit = async (data) => {
		let at = this.props.currentUser.Data.access_token;

		const response = await getLicensesOffersRegistrationInfo(
			at,
			data.License_Offer_Registration_Id
		);

		if (response.data.Status) {
			let offerPLanNameName =
					this.props.i18n.language === "ar"
						? response.data.Data.Offer_Registration_Info
								.License_Offer_Registration_Name_AR
						: response.data.Data.Offer_Registration_Info
								.License_Offer_Registration_Name_EN,
				dialogTitle = `${
					this.props.viewMood
						? this.props.t("viewServiceDetailTitle")
						: this.props.t("editServiceDetailTitle")
				} ${offerPLanNameName}`;

			this.props.triggerDialogForm(true, {
				title: dialogTitle,
				renderForm: this.renderEditForm,
				params: [response.data.Data],
			});
		}
	};

	setOrderUp = async (registrationOfferId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Registration_Order_Up",
			{
				License_Offer_Registration_Id: registrationOfferId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (registrationOfferId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Registration_Order_Down",
			{
				License_Offer_Registration_Id: registrationOfferId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() =>
						this.setOrderUp(data.License_Offer_Registration_Id)
					}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						this.setOrderDown(data.License_Offer_Registration_Id)
					}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "License_Offer_Registration_Id",
			load: async () => {
				return getLicensesOffersRegistrationList(
					at,
					this.props.serviceid
				);
			},
		});

		return (
			<Row>
				{!this.props.viewMood && (
					<Col xs={12} className="mb-5">
						<RegistrationInfoForm
							reloadDatagrid={() =>
								this.setState({ reload: !this.state.reload })
							}
							offerId={this.props.serviceid}
						/>
					</Col>
				)}

				<Col xs={12}>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/>

						{/* <Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/> */}

						<Column
							cssClass="outlinedColumn"
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
							alignment="center"
							width={"70px"}
							cellRender={this.renderOrderButtons}
						></Column>

						<Column
							dataField={
								this.props.i18n.language === "ar"
									? "License_Offer_Name_AR"
									: "License_Offer_Name_EN"
							}
							caption={this.props.t("licenseOfferName")}
							alignment={columnAlign}
						></Column>

						<Column
							dataField="License_Offer_Registration_Years"
							caption={this.props.t("registerationYears")}
							alignment={columnAlign}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Registration_Net_Value"
							caption={this.props.t("registerationValueSpecial")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Lease_Net_Value"
							caption={this.props.t("leaseValueSpecial")}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Additional_Investor_Net_Value"
							caption={this.props.t(
								"additionalInvestorValueSpecial"
							)}
							alignment={"center"}
						></Column>

						<Column
							cssClass="outlinedColumn"
							dataField="Additional_Activity_Net_Value"
							caption={this.props.t(
								"additionalActivityValueSpecial"
							)}
							alignment={"center"}
						></Column>

						<Column
							dataField="Active_Status_Id"
							caption={this.props.t("serviceStatus")}
							allowFiltering={false}
							alignment={"center"}
							width={"100px"}
							cellRender={this.customizeCellCheckbox}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							{!this.props.viewMood && [
								<Button
									render={(record) => {
										return (
											<ButtonWarning
												variant="circleIcon"
												startIcon={<EditIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('edit')} */}
											</ButtonWarning>
										);
									}}
								/>,

								<Button
									render={(record) => {
										return (
											<ButtonDanger
												variant="circleIcon"
												startIcon={<DeleteIcon />}
												onClick={() =>
													this.handleDelete(
														record.data
													)
												}
											>
												{/* {this.props.t('delete')} */}
											</ButtonDanger>
										);
									}}
								/>,
							]}

							{this.props.viewMood && (
								<Button
									render={(record) => {
										return (
											<ButtonInfo
												variant="circleIcon"
												startIcon={<VisibilityIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('view')} */}
											</ButtonInfo>
										);
									}}
								/>
							)}
						</Column>
					</DataGrid>
				</Col>
			</Row>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("serviceDetails");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(RegistrationInfo));
