import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";
import { Toolbar, Item } from "devextreme-react/html-editor";
import Wysiwyg from "../../UI/Wysiwyg";

const toolbaroptions = {
	items: [
		"undo",
		"redo",
		"separator",
		"bold",
		"italic",
		"underline",
		"strike",
		"separator",
		"link",
		{
			formatName: "header",
			formatValues: [false, 3, 4, 5],
		},
		{
			formatName: "size",
			formatValues: ["11px", "14px", "16px", "22px"],
		},
		"orderedList",
		"bulletList",
		"alignLeft",
		"alignCenter",
		"alignRight",
	],
};

class FaqForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showForm: false,
			render: false,
		};
	}

	onSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/PORTAL/QUESTIONS/ADD_QUESTION"
				: "/API/ADMINISTRATION/PORTAL/QUESTIONS/UPD_QUESTION",
			bodyData = {
				Question_Id: values.Question_Id,
				Question_AR: values.Question_AR,
				Question_EN: values.Question_EN,
				Question_Answer_AR: values.Question_Answer_AR,
				Question_Answer_EN: values.Question_Answer_EN,
			};

		if (this.props.editMood) {
			bodyData.Question_Id = this.props.qousId;
		}

		this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
			this.setState({
				render: true,
			});
		}
		this.setState({
			render: false,
		});

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};

			if (!values.Question_AR) {
				errors.Question_AR = `${this.props.t("error.field_required")}`;
			}
			if (!values.Question_EN) {
				errors.Question_EN = `${this.props.t("error.field_required")}`;
			}

			if (!values.Question_Answer_EN) {
				errors.Question_Answer_EN = `${this.props.t(
					"error.field_required"
				)}`;
			}
			if (!values.Question_Answer_AR) {
				errors.Question_Answer_AR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className="d-flex justify-content-between align-items-center pb-5">
						<h3>{this.props.t("manageFaqList")}</h3>
						<ButtonPrimary
							variant={
								!this.state.showForm ? "contained" : "outlined"
							}
							onClick={() =>
								this.setState({
									showForm: !this.state.showForm,
								})
							}
						>
							{!this.state.showForm && this.props.t("addQoues")}
							{this.state.showForm && this.props.t("cancel")}
						</ButtonPrimary>
					</div>
				)}

				<Form
					onSubmit={this.onSubmit}
					subscription={{ submitting: true, initialValues: true }}
					initialValues={
						initValues
							? initValues
							: {
									Question_AR: "",
									Question_EN: "",
									Question_Answer_EN: "",
									Question_Answer_AR: "",
							  }
					}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="sliderform"
							className={`row ${
								!this.state.showForm &&
								!this.props.editMood &&
								!this.props.viewMood
									? "d-none"
									: ""
							}`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("qouesAR")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="Question_AR"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t("qouesEN")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									} mb-2`}
									name="Question_EN"
									required={true}
									autoComplete="off"
									showError={({
										meta: { submitFailed, invalid },
									}) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>
							<Col xs={12} md={6}>
								<div className="text-start MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
									<label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
										{this.props.t("qouesAnsAR")}
									</label>
									<Field
										name="Question_Answer_AR"
										subscription={{
											error: true,
											initial: true,
										}}
										disabled={this.props.viewMood}
									>
										{(fieldProps) => (
											<Wysiwyg
												shouldRender={this.state.render}
												rtlEnabled={true}
												height="300px"
												defaultValue={
													initValues?.Question_Answer_AR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</div>
							</Col>
							<Col xs={12} md={6}>
								<div className="text-left MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
										{this.props.t("qouesAnsEN")}
									</label>
									<Field
										name="Question_Answer_EN"
										subscription={{
											error: true,
											initial: true,
										}}
										disabled={this.props.viewMood}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												shouldRender={this.state.render}
												height="300px"
												defaultValue={
													initValues?.Question_Answer_EN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												toolbar={toolbaroptions}
											></Wysiwyg>
										)}
									</Field>
								</div>
							</Col>

							<Col
								className="my-4 justify-content-start d-flex"
								xs={12}
							>
								{!this.props.viewMood && (
									<ButtonPrimary
										color="primary"
										variant="contained"
										disabled={
											submitting || this.props.viewMood
										}
										type="submit"
									>
										{this.props.t("save")}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(FaqForm));
