import apiConfig from "../apiConfig";

export const getServiceDetailsFormsList = async (at, serviceid) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_FORMS_LIST",
		{
			Service_Id: serviceid,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
