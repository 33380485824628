import apiConfig from "../../apiConfig";

export const setLicenseForbiddenNameStatus = async (at, licenseForbiddenNameId, statusId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Set_License_Forbidden_Name_Status",
		{
			License_Forbidden_Name_Id: licenseForbiddenNameId,
			Active_Status_Id: statusId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
