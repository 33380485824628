import apiConfig from "../../apiConfig";

export const getLicensesOffersRegistrationList = async (at, offerId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Get_Licenses_Offers_Registration_List",
		{ License_Offer_Id: offerId },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
