import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import { Link } from '@reach/router'
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ButtonSecondary } from '../../UI/Buttons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import style from '../Service.module.scss'
import ServiceInfo from './ServiceInfo'
import ServiceDealingType from '../ServiceDealingType'
import ServiceDetail from './ServiceDetail'
import ServiceStep from '../ServiceStep'
import { translationHook } from '../../translationHook'
import { getServiceDropdwnInfo } from '../../../apis/services/dropdowns/getServicedropdownInfo'


class ServiceDropdown extends Component {

    state = {
        key: 0,
        next: 1,
        prev: -1,
        dropdownId: null,
        first: false,
        second: true,
        edit: false,
        view: false,
        serviceInfoInit: {
            dropDowmeStatus: true
        }
    }


    handleNext = () => {
        this.setState({
            key: this.state.next,
            next: this.state.next + 1,
            prev: this.state.prev + 1
        })
    }

    handlePrev = () => {
        this.setState({
            key: this.state.prev,
            next: this.state.next - 1,
            prev: this.state.prev - 1
        })
    }

    handleFirstStepDone = (sID, values) => { 
        this.setState({
            dropdownId: sID , second: false, edit: true, serviceInfoInit: values
        })
        this.handleNext()
    } 

    renderChevs = () => {
        let rtl = this.props.i18n.language === 'ar' ? true : false,
        iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
        iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />

        return (
            <>
            <IconButton aria-label="prev" color="primary" onClick={this.handlePrev} disabled={this.state.key > 0 ? false : true}>
                {iconPrev}
            </IconButton>
            <IconButton aria-label="next" color="primary" onClick={this.handleNext} disabled={this.state.key < 1 && !this.state.second ? false : true}>
                {iconNext}
            </IconButton>
            </>
        )
    }

    componentDidMount() {
        // if edit service and there is dropdownId prop in the url 
        if ( this.props.dropdownId ) {
            (async () => {
                let at = this.props.currentUser.Data.access_token,
                dropdownId = this.props.dropdownId,
                values = {}

                
                const response = await getServiceDropdwnInfo(at, dropdownId)

                if (response) {
                    values.dropDownid = response.Service_Lookup_Id
                    values.dropDownNameAR = response.Service_Lookup_Name_AR
                    values.dropDownNameEN = response.Service_Lookup_Name_EN
                    values.dropDowmeStatus = response.Active_Status_Id == 1 ? true : false
                    values.Service_Relation_Lookup_Id = response.Service_Relation_Lookup_Id
                    this.setState({
                        dropdownId: this.props.dropdownId,
                        second: false,
                        edit: this.props.location.hash === '#view' ? false : true,
                        view: this.props.location.hash === '#view' ? true : false,
                        serviceInfoInit: values
                    })
                }

            })()
        }
    }


    render() {

        let mystyle
        if (this.props.i18n.language === 'ar') {
            mystyle = "ServiceTabsAr"
        } else {
            mystyle = "ServiceTabsEn"
        }
        

        return(
            <Tab.Container id="left-tabs-example" defaultActiveKey={0} activeKey={this.state.key} onSelect={(k) => this.setState({key: parseInt(k), next: parseInt(k) + 1, prev: parseInt(k) - 1})} unmountOnExit="true">
                <Row>
                    <Col sm={12} className='d-flex justify-content-between align-items-center'>
                    <div>
                    {this.renderChevs()}
                    </div>
                    <Nav variant="pills" className={style.stepsNav}>
                        
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 0 ? style.active : ''}`} eventKey="0" disabled={this.state.first}>{this.props.t('dropdownInfo')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 1 ? style.active : ''}`} eventKey="1" disabled={this.state.second}>{this.props.t('dropdownValues')}</Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                        <ButtonSecondary
                            variant="outlined"
                            endIcon={<ExitToAppIcon />}
                        component={Link}
                        to="/services/drop-downs"
                        size="large"
                        >
                            {this.props.t('exitTodropdownlist')}
                        </ButtonSecondary>
                    </Col>
                    <Col sm={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey={0} disabled={this.state.first}>
                            <ServiceInfo dropdownId={this.state.dropdownId} active={!this.state.first} initValues={this.state.serviceInfoInit} viewMood={this.state.view} onStepDone={this.handleFirstStepDone} editMood={this.state.edit} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={1} disabled={this.state.second} unmountOnExit="true">
                        <ServiceDetail dropdownId={this.state.dropdownId} active={this.state.second} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps)(translationHook(ServiceDropdown));