import apiConfig from "../../apiConfig";

export const setRegistrationOfferStatus = async (
	at,
	registrationOfferId,
	statusId
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Registration_Status",
		{
			License_Offer_Registration_Id: registrationOfferId,
			Active_Status_Id: statusId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
