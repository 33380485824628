import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import moment from "moment";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { DataGrid, Column } from "devextreme-react/data-grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadAttachment } from "./../../../apis/system/downloadAttachment";

class LicenseForm extends Component {
	state = {
		initialValues: {},
	};

	componentDidMount() {}

	onSubmit = async (values) => {};

	handleAttachmentDownload = ({ data }) => {
		return (
			<GetAppIcon
				style={{ width: "100%", cursor: "pointer" }}
				onClick={async () => {
					let params = {
						at: this.props.currentUser.Data.access_token,
						File_Code: data.License_Attachment_File_Code,
					};
					const downloadResult = await downloadAttachment(params);
					const url = window.URL.createObjectURL(
						new Blob([downloadResult.data], {
							type: "application/pdf",
						})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("target", "_blank");
					link.click();
				}}
			/>
		);
	};

	render() {
		let {
			Activities_Data,
			Attachments_Data,
			Investors_Data,
			License_Data,
		} = this.props.initValues;

		return (
			<>
				<Form
					onSubmit={this.onSubmit}
					initialValues={License_Data}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
						handleReset,
					}) => (
						<form
							onSubmit={handleSubmit}
							onReset={handleReset}
							style={{ overflowX: "hidden" }}
							className={`py-4 px-3 departmentFormClass ${
								!this.props.viewMood ? "d-none" : ""
							}`}
							noValidate
						>
							<Row>
								<Col md={12}>
									<div className="d-flex justify-content-between align-items-center mb-4">
										<h3>
											{this.props.t("licenseDataScreen")}
										</h3>
									</div>

									<Row>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Id"
												)}
												className="read-only mb-3"
												name="License_Id"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_No"
												)}
												className="read-only mb-3"
												name="License_No"
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextField
												disabled
												label={this.props.t(
													"License_Name_AR"
												)}
												className="read-only mb-3"
												name="License_Name_AR"
											/>
										</Col>
										<Col md={6}>
											<TextField
												disabled
												label={this.props.t(
													"License_Name_EN"
												)}
												className="read-only mb-3"
												name="License_Name_EN"
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextField
												disabled
												label={this.props.t(
													"License_Legal_Form"
												)}
												className="read-only mb-3"
												name={
													this.props.i18n.language ==
													"ar"
														? "License_Legal_Form_Name_AR"
														: "License_Legal_Form_Name_EN"
												}
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Offer_Name"
												)}
												className="read-only mb-3"
												name={
													this.props.i18n.language ==
													"ar"
														? "License_Offer_Name_AR"
														: "License_Offer_Name_EN"
												}
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Offer_Registration_Year"
												)}
												className="read-only mb-3"
												name="License_Offer_Registration_Years"
											/>
										</Col>
									</Row>

									<Row>
										<Col md={6}>
											<TextField
												disabled
												label={this.props.t(
													"License_Status"
												)}
												className="read-only mb-3"
												name={
													this.props.i18n.language ==
													"ar"
														? "License_Status_Name_AR"
														: "License_Status_Name_EN"
												}
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Segment_Name"
												)}
												className="read-only mb-3"
												name={
													this.props.i18n.language ==
													"ar"
														? "License_Segment_Name_AR"
														: "License_Segment_Name_EN"
												}
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Facility_Type_Name"
												)}
												className="read-only mb-3"
												name={
													this.props.i18n.language ==
													"ar"
														? "Facility_Type_Name_AR"
														: "Facility_Type_Name_EN"
												}
											/>
										</Col>
									</Row>

									<Row>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Investors_Count"
												)}
												className="read-only mb-3"
												name="Investors_Count"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_Capital"
												)}
												className="read-only mb-3"
												name="Company_Capital"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_Shares_Count"
												)}
												className="read-only mb-3"
												name="Company_Shares_Count"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_Share_Value"
												)}
												className="read-only mb-3"
												name="Company_Share_Value"
											/>
										</Col>
									</Row>

									<Row>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Office_No"
												)}
												className="read-only mb-3"
												name="License_Office_No"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Incorporation_Date"
												)}
												className="read-only mb-3"
												name="License_Incorporation_Date"
												value={moment(
													License_Data.License_Incorporation_Date
												).format("YYYY-MM-DD")}
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Issue_Date"
												)}
												className="read-only mb-3"
												name="License_Issue_Date"
												value={moment(
													License_Data.License_Issue_Date
												).format("YYYY-MM-DD")}
											/>
										</Col>

										{/* <Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Start_Date"
												)}
												className="read-only mb-3"
												name="License_Start_Date"
												value={moment(
													License_Data.License_Start_Date
												).format("YYYY-MM-DD")}
											/>
										</Col> */}
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"License_Expire_Date"
												)}
												className="read-only mb-3"
												name="License_Expire_Date"
												value={moment(
													License_Data.License_Expire_Date
												).format("YYYY-MM-DD")}
											/>
										</Col>
									</Row>

									<Row>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_Website"
												)}
												className="read-only mb-3"
												name="Company_Website"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_eMail"
												)}
												className="read-only mb-3"
												name="Company_eMail"
											/>
										</Col>
										<Col md={3}>
											<TextField
												disabled
												label={this.props.t(
													"Company_Phone"
												)}
												className="read-only mb-3"
												name="Company_Phone"
											/>
										</Col>
									</Row>
								</Col>

								<Col md={6}>
									<div className="d-flex justify-content-between align-items-center mb-4">
										<h3>
											{this.props.t(
												"licenseِِActivitiesList"
											)}
										</h3>
									</div>

									<DataGrid
										className={[
											this.props.i18n.language === "ar"
												? "dx-grid-ar"
												: "dx-grid-en",
											"globalBox",
										].join(" ")}
										id="dataGrid"
										width={"100%"}
										keyExpr="License_Activity_Data_Id"
										dataSource={Activities_Data}
										showBorders={false}
										showColumnLines={false}
										showRowLines={true}
										allowColumnResizing={true}
										columnResizingMode={"widget"}
										allowColumnReordering={true}
										rowAlternationEnabled={false}
										focusedRowEnabled={true}
										onExporting={this.onExporting}
										columnWidth={"auto"}
										wordWrapEnabled={true}
										rtlEnabled={
											this.props.i18n.language === "ar"
												? true
												: false
										}
										ref={(ref) => (this.dataGrid = ref)}
										bounceEnabled={false}
										remoteOperations={false}
									>
										<Column
											dataField="License_Activity_ISIC"
											caption="ISIC"
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											width="150px"
										></Column>

										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "License_Activity_Name_AR"
													: "License_Activity_Name_EN"
											}
											caption={this.props.t(
												"activityName"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
										></Column>
									</DataGrid>
								</Col>

								<Col md={6}>
									<div className="d-flex justify-content-between align-items-center mb-4">
										<h3>
											{this.props.t(
												"licenseِِAttachmentsList"
											)}
										</h3>
									</div>

									<DataGrid
										className={[
											this.props.i18n.language === "ar"
												? "dx-grid-ar"
												: "dx-grid-en",
											"globalBox",
										].join(" ")}
										id="dataGrid"
										width={"100%"}
										keyExpr="License_Attachment_Data_Id"
										dataSource={Attachments_Data}
										showBorders={false}
										showColumnLines={false}
										showRowLines={true}
										allowColumnResizing={true}
										columnResizingMode={"widget"}
										allowColumnReordering={true}
										rowAlternationEnabled={false}
										focusedRowEnabled={true}
										onExporting={this.onExporting}
										columnWidth={"auto"}
										wordWrapEnabled={true}
										rtlEnabled={
											this.props.i18n.language === "ar"
												? true
												: false
										}
										ref={(ref) => (this.dataGrid = ref)}
										bounceEnabled={false}
										remoteOperations={false}
									>
										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "License_Attachment_Type_Name_AR"
													: "License_Attachment_Type_Name_EN"
											}
											caption={this.props.t(
												"attachmentFileType"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											width="300px"
										></Column>

										<Column
											dataField="License_Attachment_File_Code"
											caption={this.props.t("download")}
											cellRender={
												this.handleAttachmentDownload
											}
											alignment="center"
											width="100px"
										></Column>
									</DataGrid>
								</Col>

								<Col md={12}>
									<div className="d-flex justify-content-between align-items-center mb-4">
										<h3>{this.props.t("investorsList")}</h3>
									</div>

									<DataGrid
										className={[
											this.props.i18n.language === "ar"
												? "dx-grid-ar"
												: "dx-grid-en",
											"globalBox",
										].join(" ")}
										id="dataGrid"
										width={"100%"}
										keyExpr="License_Investor_Data_Id"
										dataSource={Investors_Data}
										showBorders={false}
										showColumnLines={false}
										showRowLines={true}
										allowColumnResizing={true}
										columnResizingMode={"widget"}
										allowColumnReordering={true}
										rowAlternationEnabled={false}
										focusedRowEnabled={true}
										onExporting={this.onExporting}
										columnWidth={"auto"}
										wordWrapEnabled={true}
										rtlEnabled={
											this.props.i18n.language === "ar"
												? true
												: false
										}
										ref={(ref) => (this.dataGrid = ref)}
										bounceEnabled={false}
										remoteOperations={false}
									>
										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "Investor_Name_AR"
													: "Investor_Name_AR"
											}
											caption={this.props.t(
												"investorName"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											width="200px"
										></Column>

										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "License_Investor_Type_Name_AR"
													: "License_Investor_Type_Name_EN"
											}
											caption={this.props.t(
												"investorType"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											width="200px"
										></Column>

										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "Nationality_Name_AR"
													: "Nationality_Name_EN"
											}
											caption={this.props.t(
												"nationality"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
										></Column>

										<Column
											dataField="Investor_Partner_Ratio"
											caption={this.props.t(
												"partnerRatio"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
										></Column>

										<Column
											dataField="Investor_Shares_Count"
											caption={this.props.t(
												"sharesCount"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
										></Column>

										<Column
											dataField="Investor_Shares_Value"
											caption={this.props.t(
												"sharesValue"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
										></Column>

										<Column
											dataField={
												this.props.i18n.language ===
												"ar"
													? "License_Signature_Type_Name_AR"
													: "License_Signature_Type_Name_EN"
											}
											caption={this.props.t(
												"signatureType"
											)}
											alignment={
												this.props.i18n.language ===
												"ar"
													? "right"
													: "left"
											}
											width="200px"
										></Column>
									</DataGrid>
								</Col>
							</Row>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
})(translationHook(LicenseForm));
