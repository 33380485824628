import React, { useState, useEffect } from "react";

import EscalateStep from "./EscalateStep";
import Notes from "../Notes";
import { escalateRequestStep } from "../../../../apis/requests/processTask";

export const EscalateRequestStep = (props) => {
	const [escalateValues, setEscalateValues] = useState({
		Escalate_Step_Receiver_Type_Id: "2",
		Escalate_Step_Receiver_User_Id: null,
	});

	return (
		<>
			<EscalateStep
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				setValuesCB={setEscalateValues}
			/>

			<Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={(at, data) => {
					return escalateRequestStep(at, {
						...data,
						...escalateValues,
					});
				}}
				required={true}
			/>
		</>
	);
};
