import apiConfig from "../../apiConfig";

export const deleteRegistrationOffer = async (
	at,
	registrationOfferIdofferId
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Del_License_Offer_Registration",
		{
			License_Offer_Registration_Id: registrationOfferIdofferId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
