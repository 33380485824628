import apiConfig from "../../apiConfig";

export const setLicenseTradeNameStatus = async (at, licenseTrademarkNameId, statusId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/NAMES/Set_License_Trademark_Name_Status",
		{
			License_Trademark_Name_Id: licenseTrademarkNameId,
			Active_Status_Id: statusId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
