import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
	ButtonDanger,
	ButtonSecondary,
	ButtonInfo,
	ButtonWarning,
} from "./../UI/Buttons";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { translationHook } from "../translationHook";
import { getServiceDetailsFormsList } from "../../apis/services/getServiceDetailsFormsList";
import { deleteServiceDetailForm } from "../../apis/services/deleteServiceDetailForm";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
} from "../../actions";
import ServiceDetailForm from "./ServiceDetailForm";
import ServiceApplicationStepForm from "./ServiceApplicationStepForm";
import moment from "moment";

class ServiceDetailsFormsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		reload: false,
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			serviceDetailFormid = data.Service_Detail_Form_Id,
			serviceDetailFormName =
				this.props.i18n.language === "ar"
					? data.Service_Detail_Form_Title_AR
					: data.Service_Detail_Form_Title_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${serviceDetailFormName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteServiceDetailForm,
				params: [at, serviceDetailFormid],
				text: this.props.t("deleteStep"),
			},
		});
	};

	renderEditForm = (...params) => {
		return (
			<ServiceApplicationStepForm
				serviceid={this.props.serviceid}
				reloadDatagrid={() =>
					this.setState({ reload: !this.state.reload })
				}
				editMood={this.props.editMood}
				viewMood={this.props.viewMood}
				serviceDetailId={params[0]}
				initValues={params[1]}
			/>
		);
	};

	handleEdit = (data) => {
		let initValues = {
				detailNameAR: data.Service_Detail_Form_Title_AR,
				detailNameEN: data.Service_Detail_Form_Title_EN,
			},
			serviceDetailId = data.Service_Detail_Form_Id,
			serviceDetailName =
				this.props.i18n.language === "ar"
					? data.Service_Detail_Form_Title_AR
					: data.Service_Detail_Form_Title_EN,
			dialogTitle = `${
				this.props.viewMood
					? this.props.t("viewServiceDetailTitle")
					: this.props.t("editServiceDetailTitle")
			} ${serviceDetailName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderEditForm,
			params: [serviceDetailId, initValues],
		});
	};

	setOrderUp = async (serviceDetailFormId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_ORDER_UP",
			{
				Service_Detail_Form_Id: serviceDetailFormId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	setOrderDown = async (serviceDetailFormId) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/SERVICES/SET_SERVICE_DETAIL_FORM_ORDER_DOWN",
			{
				Service_Detail_Form_Id: serviceDetailFormId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		this.props.setLoading(false);
		this.props.reload();
	};

	renderOrderButtons = ({ data }) => {
		return (
			<div className="d-flex flex-column align-items-center order-btns">
				<IconButton
					onClick={() => this.setOrderUp(data.Service_Detail_Form_Id)}
				>
					<KeyboardArrowUpIcon />
				</IconButton>
				<IconButton
					onClick={() =>
						this.setOrderDown(data.Service_Detail_Form_Id)
					}
				>
					<KeyboardArrowDownIcon />
				</IconButton>
			</div>
		);
	};

	render() {
		let columnAlign = "left",
			ServiceDetailTitleKey = "Service_Detail_Form_Title_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			ServiceDetailTitleKey = "Service_Detail_Form_Title_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Service_Detail_Form_Id",
			load: async () => {
				return getServiceDetailsFormsList(at, this.props.serviceid);
			},
		});

		return (
			<Row>
				{!this.props.viewMood && (
					<Col xs={12} className="mb-5">
						<ServiceApplicationStepForm
							serviceid={this.props.serviceid}
							reloadDatagrid={() =>
								this.setState({ reload: !this.state.reload })
							}
						/>
					</Col>
				)}

				<Col xs={12}>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={
							this.props.i18n.language === "ar" ? true : false
						}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping
							contextMenuEnabled={true}
							expandMode="rowClick"
						/>
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/>

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							cssClass="outlinedColumn"
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "right"
									: "left"
							}
							alignment="center"
							width={"70px"}
							cellRender={this.renderOrderButtons}
						></Column>

						<Column
							dataField="Service_Detail_Form_No"
							sortOrder="asc"
							caption={this.props.t("stepNo")}
							alignment={"center"}
						></Column>

						<Column
							dataField={ServiceDetailTitleKey}
							caption={this.props.t("stepName")}
							alignment={columnAlign}
						></Column>

						<Column
							cssClass="outlinedColumn nowrapColumn"
							type="buttons"
							caption={this.props.t("details")}
							fixed={true}
							fixedPosition={
								this.props.i18n.language === "ar"
									? "left"
									: "right"
							}
						>
							{!this.props.viewMood && [
								<Button
									render={(record) => {
										return (
											<ButtonWarning
												variant="circleIcon"
												startIcon={<EditIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('edit')} */}
											</ButtonWarning>
										);
									}}
								/>,

								<Button
									render={(record) => {
										return (
											<ButtonDanger
												variant="circleIcon"
												startIcon={<DeleteIcon />}
												onClick={() =>
													this.handleDelete(
														record.data
													)
												}
											>
												{/* {this.props.t('delete')} */}
											</ButtonDanger>
										);
									}}
								/>,
							]}

							{this.props.viewMood && (
								<Button
									render={(record) => {
										return (
											<ButtonInfo
												variant="circleIcon"
												startIcon={<VisibilityIcon />}
												onClick={() =>
													this.handleEdit(record.data)
												}
											>
												{/* {this.props.t('view')} */}
											</ButtonInfo>
										);
									}}
								/>
							)}
						</Column>
					</DataGrid>
				</Col>
			</Row>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("serviceDetails");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
})(translationHook(ServiceDetailsFormsList));
