import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { Select, TextField, Checkboxes } from "mui-rff";
import FilesUpload from "../../../UI/FileUpload/UploadFile";
import { translationHook } from "../../../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import { downloadRequestAttachment } from "../../../../apis/requests/info/downloadRequestAttachment";
import { deleteRequestTaskInterviewAttachment } from "../../../../apis/requests/info/deleteRequestTaskInterviewAttachment";
import { deleteRequestWorldcheckAttachment } from "../../../../apis/requests/info/deleteRequestWorldcheckAttachment";

class RiskFactorInfo extends Component {
	state = {
		ratingResults: [],
	};

	getRiskRatingResult = async () => {
		let ratingArr = [],
			at = this.props.currentUser.Data.access_token;
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Request_Task_Risk_Rating_Results",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.License_Risk_Rating_Result_Name_AR
						: item.License_Risk_Rating_Result_Name_EN;

				ratingArr.push({
					value: item.License_Risk_Rating_Result_Id,
					label,
				});
			});

			this.setState({ ratingResults: ratingArr });
		}
	};

	saveGlobalRiskRatingResult = (riskRateId) => {
		let at = this.props.currentUser.Data.access_token;

		const response = apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_Global_Risk_Rating_Result",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Risk_Rate_Id: riskRateId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		return response;
	};

	saveInterviewRiskRatingResult = (interviewRiskRateId) => {
		let at = this.props.currentUser.Data.access_token;

		const response = apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_Interview_Risk_Result",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Interview_Risk_Rate_Id: interviewRiskRateId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		return response;
	};

	saveRequestWorldcheckDone = (worldcheckDone) => {
		let at = this.props.currentUser.Data.access_token;

		const response = apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_WorldCheck_Done",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				WorldCheck_Done: worldcheckDone ? 1 : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		return response;
	};

	saveRequestWorldcheckRatingResult = (worldcheckRateId) => {
		let at = this.props.currentUser.Data.access_token;

		const response = apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_WorldCheck_Risk_Result",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				WorldCheck_Risk_Rate_Id: worldcheckRateId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		return response;
	};

	saveRequestTaskInterviewDone = (interviewDone) => {
		let at = this.props.currentUser.Data.access_token;

		const response = apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Save_Request_Task_Interview_Done",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Interview_Done: interviewDone ? 1 : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);
		return response;
	};

	handleRiskValue = async (e) => {
		this.props.setLoading(true, "dark");

		await this.saveGlobalRiskRatingResult(e.target.value)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					this.props.setValuesCB((prev) => {
						return { ...prev, Risk_Rate_Id: e.target.value };
					});
					// window.location.assign("/");
					// window.location.href = '/'
					// props.triggerDialogForm(false);
					// window.location.reload()
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	handleInterviewRiskValue = async (e) => {
		this.props.setLoading(true, "dark");

		await this.saveInterviewRiskRatingResult(e.target.value)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					this.props.setValuesCB((prev) => {
						return {
							...prev,
							Interview_Risk_Rating: {
								...prev.Interview_Risk_Rating,
								...{
									Interview_Risk_Rate_Id: e.target.value,
								},
							},
						};
					});
					// window.location.assign("/");
					// window.location.href = '/'
					// props.triggerDialogForm(false);
					// window.location.reload()
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	handleWorldcheckRiskValue = async (e) => {
		this.props.setLoading(true, "dark");

		await this.saveRequestWorldcheckRatingResult(e.target.value)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					this.props.setValuesCB((prev) => {
						return {
							...prev,
							WorldCheck_Risk_Rating: {
								...prev.WorldCheck_Risk_Rating,
								...{
									WorldCheck_Risk_Rate_Id: e.target.value,
								},
							},
						};
					});
					// window.location.assign("/");
					// window.location.href = '/'
					// props.triggerDialogForm(false);
					// window.location.reload()
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	handleCheckboxes = async (e, callback, successCB) => {
		this.props.setLoading(true, "dark");

		await callback(e.target.checked)
			.then((result) => {
				if (!result.data.Status) {
					throw result.data;
				}

				this.props.setLoading(false);

				this.props.triggerNotification(true, result.data);

				//here 1/5
				if (result.data.Status) {
					successCB(!e.target.checked);
					// window.location.assign("/");
					// window.location.href = '/'
					// props.triggerDialogForm(false);
					// window.location.reload()
				}
			})
			.catch((error) => {
				this.props.setLoading(false);
				if (error.response.data.RESULT_CODE === 500) {
					this.props.triggerNotification(true, error.response.data);
				} else {
					this.props.triggerNotification(true, error.response);
				}

				return error.response;
			});
	};

	componentDidMount() {
		this.getRiskRatingResult();
	}

	onServiceInfoSubmit = async (values, form) => {};

	render() {
		const validate = (values) => {
			const errors = {};

			return errors;
		};

		return (
			<div className="row mt-5">
				<Col xs={12}>
					<div className="row">
						<Col xs={4}>{this.props.t("systemRiskRating")}</Col>
						<Col xs={8}>
							<div
								style={{
									backgroundColor:
										this.props.riskInfo?.Risk_Rate_Color,
									color: "#fff",
									padding: "8px",
									borderRadius: "4px",
									textAlign: "center",
									maxWidth: "200px",
								}}
							>
								{this.props.i18n.language === "ar"
									? this.props.riskInfo.Risk_Rate_Name_AR
									: this.props.riskInfo.Risk_Rate_Name_EN}
							</div>
						</Col>
					</div>
				</Col>
				<Col xs={12}>
					<Form
						subscription={{ submitting: true }}
						onSubmit={this.onServiceInfoSubmit}
						validate={validate}
						render={({ handleSubmit, form, submitting }) => (
							<form
								className="row mt-5"
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("DNFBPactivities")}
										className="read-only mb-4"
										name="dnfbpActivitiesAR"
										value={
											this.props.riskInfo
												?.DNFBP_Activities_Rating
												?.DNFBP_Activities_Status_Name_AR
										}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("DNFBPactivities")}
										className="read-only mb-4"
										name="dnfbpActivitiesEN"
										value={
											this.props.riskInfo
												?.DNFBP_Activities_Rating
												?.DNFBP_Activities_Status_Name_EN
										}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("ESRactivities")}
										className="read-only mb-4"
										name="esrActivitiesAR"
										value={
											this.props.riskInfo
												?.ESR_Activities_Rating
												?.ESR_Activities_Status_Name_AR
										}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled
										label={this.props.t("ESRactivities")}
										className="read-only mb-4"
										name="esrActivitiesEN"
										value={
											this.props.riskInfo
												?.ESR_Activities_Rating
												?.ESR_Activities_Status_Name_EN
										}
									/>
								</Col>

								<Col md={6} xs={12}>
									<div className="d-flex w-100">
										<div className="w-25">
											<Checkboxes
												className="mb-2"
												name="interviewDone"
												data={{
													label: this.props.t(
														"interviewDone"
													),
												}}
												checked={
													this.props.riskInfo
														?.Interview_Risk_Rating
														?.Interview_Done === 0
														? false
														: true
												}
												onChange={(e) => {
													this.handleCheckboxes(
														e,
														this
															.saveRequestTaskInterviewDone,
														(interviewDone) => {
															this.props.setValuesCB(
																(prev) => {
																	return {
																		...prev,
																		Interview_Risk_Rating:
																			{
																				...prev.Interview_Risk_Rating,
																				...{
																					Interview_Done:
																						interviewDone
																							? 1
																							: 0,
																				},
																			},
																	};
																}
															);
														}
													);
												}}
											/>
										</div>
										<div className="flex-grow-1">
											<h5 className="my-1 text-start">
												{this.props.t(
													"interviewAttachments"
												)}
											</h5>
											<FilesUpload
												at={
													this.props.currentUser.Data
														.access_token
												}
												params={{
													Request_Id:
														this.props.requestId,
													Request_Task_Id:
														this.props
															.requestTaskId,
													// Temp_Action_Id: this.props.tempId,
												}}
												downloadCB={
													downloadRequestAttachment
												}
												deleteCB={
													deleteRequestTaskInterviewAttachment
												}
												deleteParams={{
													Request_Task_Id:
														this.props
															.requestTaskId,
													Request_Id:
														this.props.requestId,
												}}
												endPoint="/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_INTERVIEW_ATTACHMENT"
											/>
										</div>
									</div>
								</Col>

								<Col xs={12} md={6}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t(
											"interviewRiskResult"
										)}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
										name="interviewRiskRateId"
										data={this.state.ratingResults}
										value={
											this.props.riskInfo
												?.Interview_Risk_Rating
												?.Interview_Risk_Rate_Id
										}
										onChange={this.handleInterviewRiskValue}
									></Select>
								</Col>

								<Col md={6} xs={12}>
									<div className="d-flex w-100">
										<div className="w-25">
											<Checkboxes
												className="mb-2"
												name="worldcheckDone"
												data={{
													label: this.props.t(
														"worldcheckDone"
													),
												}}
												checked={
													this.props.riskInfo
														?.WorldCheck_Risk_Rating
														?.WorldCheck_Done === 0
														? false
														: true
												}
												onChange={(e) => {
													this.handleCheckboxes(
														e,
														this
															.saveRequestWorldcheckDone,
														(worldcheckDone) => {
															this.props.setValuesCB(
																(prev) => {
																	return {
																		...prev,
																		WorldCheck_Risk_Rating:
																			{
																				...prev.WorldCheck_Risk_Rating,
																				...{
																					WorldCheck_Done:
																						worldcheckDone
																							? 1
																							: 0,
																				},
																			},
																	};
																}
															);
														}
													);
												}}
											/>
										</div>
										<div className="flex-grow-1">
											<h5 className="my-1 text-start">
												{this.props.t(
													"worldcheckAttachments"
												)}
											</h5>
											<FilesUpload
												at={
													this.props.currentUser.Data
														.access_token
												}
												params={{
													Request_Id:
														this.props.requestId,
													Request_Task_Id:
														this.props
															.requestTaskId,
													// Temp_Action_Id: this.props.tempId,
												}}
												downloadCB={
													downloadRequestAttachment
												}
												deleteCB={
													deleteRequestWorldcheckAttachment
												}
												deleteParams={{
													Request_Task_Id:
														this.props
															.requestTaskId,
													Request_Id:
														this.props.requestId,
												}}
												endPoint="/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_WorldCheck_ATTACHMENT"
											/>
										</div>
									</div>
								</Col>

								<Col xs={12} md={6}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t(
											"worldcheckRiskResult"
										)}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
										name="worldcheckRiskResult"
										data={this.state.ratingResults}
										value={
											this.props.riskInfo
												?.WorldCheck_Risk_Rating
												?.WorldCheck_Risk_Rate_Id
										}
										onChange={
											this.handleWorldcheckRiskValue
										}
									></Select>
								</Col>

								<Col xs={12} md={6}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t(
											"globalCompanyRiskRating"
										)}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										}`}
										name="riskRateId"
										data={this.state.ratingResults}
										value={
											this.props.riskInfo?.Risk_Rate_Id
										}
										onChange={this.handleRiskValue}
									></Select>
								</Col>
							</form>
						)}
					/>
				</Col>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(RiskFactorInfo));
