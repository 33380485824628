import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import apiConfig from '../../../apis/apiConfig';
import Switch from '@material-ui/core/Switch';


import { getServiceDropDownsList } from '../../../apis/services/dropdowns/serviceDropDownsList';
import { deleteDropdown } from '../../../apis/services/dropdowns/delServiceDrop';
import { triggerDialog , setLoading, triggerNotification} from '../../../actions';
import { translationHook } from '../../translationHook';
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from '../../UI/Buttons';

class ServiceDropDownsList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Active_Status_Id}
			name={`templateStatus_${data.Service_Category_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					url = '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/Upd_Services_Lookups',
					bodyData = {
						Service_Lookup_Id: data.Service_Lookup_Id,
						Service_Lookup_Name_AR: data.Service_Lookup_Name_AR,
						Service_Lookup_Name_EN: data.Service_Lookup_Name_EN,
						Active_Status_Id: data.Active_Status_Id ? 0 : 1
					};
					this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)
			
					const response = await apiConfig.post(
						url,
						bodyData,
						{
							headers: {
								Authorization: `Bearer ${at}`
							},
						}
					).catch((error) => {
						return error.response
					})
					this.props.triggerNotification(true, response.data)
					this.props.setLoading(false)
			}}
			/>
        )
    }
	// delete dropdown
	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			serviceid = data.Service_Lookup_Id,
			serviceName =
				this.props.i18n.language === 'ar'
					? data.Service_Lookup_Name_AR
					: data.Service_Lookup_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${serviceName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteDropdown,
				params: [
					at,
					serviceid,
				],
				text: this.props.t('deleteService'),
			},
		});
	};

	render() {
		let columnAlign = 'left',
			ServiceLookupNameKey = 'Service_Lookup_Name_EN',
			ServiceRelationLookUpName = 'Service_Relation_Lookup_Name_AR',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
			ServiceLookupNameKey = 'Service_Lookup_Name_AR';
			ServiceRelationLookUpName = 'Service_Relation_Lookup_Name_EN';
		}

		const jsonDataSource = new CustomStore({
			key: 'Service_Lookup_Id',
			load: () => {
				return getServiceDropDownsList(at);
			},
		});

		return (
			<>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<h3>{this.props.t('manageDropDowns')}</h3>
					<ButtonPrimary component={Link} to='add-drop-down' size='large'>
						{this.props.t('addDrop')}
					</ButtonPrimary>
				</div>

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
						dataField={ServiceLookupNameKey}
						caption={this.props.t('serviceDropdownName')}
						alignment={columnAlign}>
					</Column>

					<Column
						dataField={ServiceLookupNameKey}
						caption={this.props.t('mainRelationGroup')}
						alignment={columnAlign}>
					</Column>


					<Column
						dataField='Active_Status_Id'
						caption={this.props.t('serviceStatus')}
						allowFiltering={false}
						cellRender={this.customizeCellCheckbox}
						width={'120px'}
						>
					</Column>

					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
						render={(record) => {
							return (
								
								<ButtonWarning
								variant="circleIcon"
								startIcon={<EditIcon />}
								component={Link}
								to={`edit-drop-down/${record.data.Service_Lookup_Id}`}
								>
									{/* {this.props.t('edit')} */}
								</ButtonWarning>
							)
						}}
						/>
						<Button
						render={(record) => {
							return (
								
								<ButtonInfo
								variant="circleIcon"
								startIcon={<VisibilityIcon />}
								component={Link}
								to={`edit-drop-down/${record.data.Service_Lookup_Id}/#view`}
								>
									{/* {this.props.t('view')} */}
								</ButtonInfo>
							)
						}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('ServicesDrop-Downs')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog , setLoading, 
	triggerNotification})(
	translationHook(ServiceDropDownsList)
);
