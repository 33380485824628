import apiConfig from "../../apiConfig";

export const setOfferStatus = async (at, offerId, statusId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/LICENSES/OFFERS/Set_License_Offer_Status",
		{
			License_Offer_Id: offerId,
			Active_Status_Id: statusId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
